import React, { useEffect, useState } from "react";
import "stylesheets/header.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Images } from "constants/Images";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import ContactSaleModal from "./modal/ContactSaleModal";
import search from "../assets/icons/search.png";
import { useAuthContext } from "context/auth/AuthContext";
import userImg from "../assets/images/user.svg";
// import { chevronDown } from 'react-feather';
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { API } from "API/API";
import { toast } from "react-toastify";
import { useSearchContext } from "context/searchContext/searchContext";
import { useForm } from "react-hook-form";
import cross from 'assets/icons/cross.svg';

const Header = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState();
  const [showModal, setShowModal] = useState(false);
  const { login, doLogin, assignToken, token } = useAuthContext();
  let [tokenn, setTokenn] = useState(null);
  const { userData } = useSearchContext();
  const [searchBar , setSearchBar] = useState("")
  const [AlldATA, setAlldATA] = useState(userData);
  const { call, callUserApi } = useSearchContext();
  const { setSearch, seachField } = useSearchContext();
  const { handleSubmit, control, setValue, getValue } = useForm({});
  const location = useLocation();
  const param = useParams();
  const [isScrolled, setIsScrolled] = useState(false);

  const getToken = () => {
    let myToken = localStorage.getItem("mytoken");
    if (myToken) {
      setTokenn(myToken);
      return myToken;
    } else return null;
  };

  useEffect(() => {
    if(!location?.pathname?.includes("/products")){
      setSearchBar("")
      setSearch("")
    }
  }, [location?.pathname]);

  const contactSalesRep = async (data) => {
    setLoader(true);
    try {
      let response = await API.contactSalesRep(data);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
      setShowModal(false);
    }
  };

  useEffect(() => {
    getToken();
  }, [login, tokenn]);

  const onProjectClick = () => {
    props?.onMenu();
    if (
      localStorage.getItem("mytoken") === "null" ||
      localStorage.getItem("mytoken") === null
    ) {
      navigate("login-signup");
    } else {
      navigate("/projects");
    }
  };
  const onModalClick = () => {
    props?.onMenu();
    setShowModal(true);
  };
  const onLogin = () => {
    props?.onMenu();
    navigate("/login-signup");
  };
  const onLogout = () => {
    props?.onMenu();
    assignToken(null);
    doLogin(false);
    setTokenn(null);
    localStorage.removeItem("mytoken");
    localStorage.removeItem("token");
    toast.success("You have been logged out successfully");
    navigate("/login-signup");
  };
  const onLogoClick = () => {
    props?.onMenu();
    navigate("/");
  };

  let debounceTimer;

  const handleSeachFilter = (e) => {
    setSearchBar(e.target.value)
    debounceTimer = setTimeout(() => {
      setSearch(e.target.value);
      if (!location.pathname.includes("createSubmittal") && !location.pathname.includes("createBrochure")) {
        navigate("/products");
      }
    }, 1000);
  };
  const EDITprofile = () => {
    props?.onMenu();
    navigate("/edit-profile");
  };
  const resetPas = () => {
    props?.onMenu();
    navigate("/change-password");
  };

  const crossSearch = () => {
    setSearchBar("");
    setSearch("");
  }

  // Modify header for home and products
  const modifyHeader = ['/', '/products'];
  const resetHeader = modifyHeader.includes(location.pathname);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.header__modify');
      const isHeaderSticky = header.classList.contains('header__modify--sticky');
      if (window.scrollY > 100 && !isHeaderSticky) {
        setIsScrolled(true);
      } else if (window.scrollY < 100 && isHeaderSticky) {
        setIsScrolled(false);

      }
    };
    // Add event listener when the component mounts
    window.addEventListener('scroll', handleScroll);
    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <header className={`header ${resetHeader ? 'header__modify' : ''} ${isScrolled ? 'header__modify--sticky' : ''}`}>
        <div className="container">
          <div className="cover">
            <div className="header__logo">
              <img
                src={Images.image.logo}
                alt="logo"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="header__search">
              <img src={Images.icon.search} alt="" className="searchImg" />
              <img src={cross} alt="" className="crossImg" onClick={() => crossSearch()} />
              <input
                type={"text"}
                value={searchBar}
                className="form-control"
                onChange={(e) => handleSeachFilter(e)}
              // placeholder="What are you looking for ?"
              />
            </div>
            <menu className={`header__menu ${props.showMenu ? "show" : ""}`}>
              <div className="mobileLogo">
                <img
                  src={Images.image.logo}
                  alt="logo"
                  onClick={() => onLogoClick()}
                />
              </div>
              <ul>
                {/* <li onClick={() => onModalClick()}>Contact Sales Rep</li>  */}
                <li onClick={() => onProjectClick()}>PROJECTS</li>

                {(localStorage.getItem("mytoken") === undefined ||
                  localStorage.getItem("mytoken") === "null" ||
                  localStorage.getItem("mytoken") === null) &&
                !login ? (
                  <>
                    <li className="button" onClick={() => onLogin()}>
                      Login / Signup
                    </li>
                  </>
                ) : (
                  <>
                    <li style={{ display: "flex", flexDiretion: "row" }}>
                      <img src={userImg} style={{ marginRight: "10px" }} />
                      <div class="dropdown">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{userData?.firstname}</span>
                          <AiOutlineCaretDown />
                        </div>
                        <div class="dropdown-content">
                          <ul>
                            <li
                              className="leftMargin"
                              onClick={() => EDITprofile()}
                            >
                              Edit Profile
                            </li>
                            <li
                              className="leftMargin"
                              onClick={() => resetPas()}
                            >
                              Change Password
                            </li>
                            <li onClick={() => onLogout()}>Log Out</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </menu>
            {/* <div className="header__search">
              <img src={Images.icon.search} alt="" className="searchImg" />
              <img src={cross} alt="" className="crossImg" onClick={() => crossSearch()} />
              <input
                type={"text"}
                value={searchBar}
                className="form-control"
                onChange={(e) => handleSeachFilter(e)}
                // placeholder="What are you looking for ?"
              />
            </div> */}
            <button
              type="button"
              className={`header__menuBtn ${props.showMenu ? "show" : ""}`}
              onClick={props.onMenu}
            >
              {props.showMenu ? (
                <AiOutlineClose size={24} />
              ) : (
                <AiOutlineMenu size={24} />
              )}
            </button>
          </div>
        </div>
      </header>
      <ContactSaleModal
        click={() => setShowModal(!showModal)}
        show={showModal}
        contact={contactSalesRep}
        loader={loader}
      />
    </React.Fragment>
  );
};
export default Header;
