import React from "react";
import  '../stylesheets/comman/privacypolicy.scss';



const TermsOfUse = () => {
    return (
        <section>
            <div className="container" style={{ paddingTop: '20px', paddingBottom: '70px' }}>
                <div style={{ 'paddingTop': '10px' }}>
                    <h1 style={{ 'fontWeight': '600', 'fontSize': '36px', paddingLeft: '20px' }}>Terms Of Use</h1>
                </div>
                
                <div className="textarea">
                    <br /><br />
                    <p className="textarea__text">
                        <h2>
                            Last Updated: June, 06, 2024
                        </h2>


                        Welcome to the Web site for Planspace, at www.planspace.ca (Web
                        Site or Site). We maintain this Site for general promotional, and public
                        information purposes. The following Terms of Use (Terms) apply to
                        your use of this Site. Please read them carefully! By using this Web
                        Site, you are agreeing to be bound by these Terms. You may not use
                        this Site if you do not agree to these Terms.
                        Unless the context indicates otherwise, as used in these Terms, (i)
                        “Planspace,
                        ” “us,
                        ” “we” and “our” refer to Planspace Canada and its
                        subsidiaries and affiliates and (ii) “Content” refers to any or all text,
                        documents, photographs, images, graphics, logos, emblems, designs,
                        layouts, trademarks, trade names, service marks, copyrighted
                        materials, audio and video presentations and other information
                        provided by us on or through this Web Site, including user interfaces
                        and the selection, coordination and arrangement of such information.
                    </p>
                    <p className="textarea__text">

                        BY USING THE SITE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO
                        ENTER INTO THESE TERMS.<br /><br />
                        THESE TERMS CONTAIN A MANDATORY ARBITRATION PROVISION
                        THAT, AS FURTHER SET FORTH IN THE “GOVERNING LAW; AND
                        ARBITRATION” SECTION BELOW, REQUIRES THE USE OF ARBITRATION
                        ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
                        JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS
                        ACTIONS OF ANY KIND.
                    </p>
                    <p className="textarea__text">
                        <h2>Changes to this Site or these Terms</h2>
                        We may change or discontinue all or any part of this Web Site
                        (including access to this Web Site via any third-party links) at any time
                        without prior notice or obligation to you; charge, modify or waive any
                        fees required to use this Web Site; or offer opportunities to some or
                        all Web Site users. We may also change these Terms at any time by
                        notifying you of such changes by any reasonable means, including by
                        posting revised Terms through the Site. Any such changes to the
                        Terms will not apply to any dispute between you and us arising prior
                        to the date on which we posted the revised Terms incorporating such
                        changes, or otherwise provided notice of such changes. Your
                        continued use of the Site after such changes have been posted will
                        constitute your acceptance of the changes to these Terms. The “Last
                        updated” legend above indicates when these Terms were last
                        changed.
                        <h2>Information Submitted Through the Site</h2>
                        Your submission of information through the Site is governed by our
                        Privacy Policy, located at www.planspace.ca (the “Privacy Policy”).
                        You represent and warrant that any information you provide in
                        connection with the Site is and will remain accurate and complete,
                        and that you will maintain and update such information as needed.
                        <h2>Intellectual Property</h2>
                        The Content provided on this Web Site is copyrighted by Planspace or
                        our affiliate partners and is protected from copying, reproduction,
                        modification, distribution, display, performance or transmittal for any
                        purpose under Canadian and international copyright laws.
                        The trademarks, trade names, service marks, product packaging,
                        designs and corporate logos and emblems displayed on this Site
                        belong exclusively to Planspace or our affiliate partners and are
                        protected from reproduction, imitation, dilution, or confusing or
                        misleading uses under Canadian and international trademark laws.
                        Nothing stated or implied on this Site confers on you any license or
                        right under any copyright, trademark or service mark of Planspace or
                        any affiliate partner. Unauthorized use of this intellectual property is
                        expressly prohibited and may be a violation of law.
                        <h2>Permitted and Prohibited Uses</h2>
                        Subject to your compliance with these Terms, and solely for so long as
                        you are permitted by Planspace to access and use the Site, you may
                        view and make one electronic or paper copy of the information
                        posted on any screens of this Web Site, provided that you use the
                        copy solely for your personal, informational and non-commercial
                        purposes and that you
                        (i) keep all copyright and other proprietary
                        notices intact, (ii) do not modify the Content or use it in a manner that
                        suggests an association with any of our products, services or brands
                        and (iii) do not download quantities of the Content to a database that
                        can be used to avoid future downloads from this Site. <br />
                        You may not, in connection with the Site, do any of the following:<br />
                        <ul>
                            <li>Reproduce, upload, post, transmit, display, modify, adapt,
                                translate, create derivative works of, sell, resell, rent, lease, loan,
                                timeshare, distribute, redistribute or otherwise use or exploit any
                                materials or design elements of this Site, any other portion of this
                                Site, or use of or access to the Site, except as expressly authorized
                                herein, without our prior written consent;</li>
                            <li> Use or access the Site, including any Content or Software, for any
                                commercial purpose;</li>
                            <li>
                                Reverse engineer, decompile or disassemble the Software or any
                                portion of the Site or otherwise attempt to determine the source
                                code or trade secrets of the Site or the Software;
                            </li>
                            <li>
                                Violate or attempt to violate the security of the Site;
                            </li>
                            <li>
                                Restrict or inhibit any other visitor or member from using the Site,
                                including, without limitation, by means of "hacking" or defacing
                                any portion of the Site;
                            </li>
                            <li>
                                Use the Site, including any Content or Software, for any tortious,
                                fraudulent or otherwise unlawful purpose;
                            </li>
                            <li>
                                Impersonate any person or entity, including without limitation any
                                representative of Planspace; falsely state or otherwise
                                misrepresent your affiliation with any person or entity in
                                connection with the Site; or express or imply that any statements
                                you make are endorsed by us, without our prior written consent; or
                                modify the Site, including any Content or Software, use it in a
                                manner that suggests an association with us or any of our
                                products, services or brands;
                            </li>
                            <li>
                                Transmit, post or otherwise make available through or in
                                connection with the Site (a) any content or information that is
                                unlawful, fraudulent, threatening, harassing, degrading, hateful,
                                intimidating, abusive, libelous, defamatory, obscene, indecent,
                                pornographic or otherwise objectionable, or otherwise fails to
                                respect the rights and dignity of others; (b) any material, nonpublic information about companies without the authorization to
                                do so; (c) any information or material protected by trade secret,
                                copyright, trademark, right of publicity or privacy or any other
                                intellectual property or proprietary right of any third party,
                                without the express prior written consent of the applicable owner;
                                (d) any material that would give rise to criminal or civil liability;
                                that encourages conduct that constitutes a criminal offense; or
                                that encourages or provides instructional information about illegal
                                activities or activities such as "hacking,
                                " "cracking,
                                " or "phreaking";
                                or (e) any advertisements, solicitations, junk mail, chain letters,
                                pyramid schemes, investment opportunities or other unsolicited
                                commercial communication (except as otherwise expressly
                                permitted by us);
                            </li>
                            <li> Engage in spamming or flooding;</li>
                            <li>
                                Transmit, post or otherwise make available through or in
                                connection with the Site any software or other materials that
                                contain any viruses, worms, Trojan horses, Easter eggs, defects,
                                date bombs, time bombs, spyware or other computer code, file,
                                program or item of a destructive nature that is or is potentially
                                harmful or invasive or intended to damage or hijack the operation
                                of, or to monitor the use of, any hardware, software or equipment
                                (each, a “Virus”);
                            </li>
                            <li>Remove any copyright, trademark or other proprietary rights
                                notices contained in the Site, including with respect to any
                                Content or Software;</li>
                            <li> "Frame" or "mirror" any part of the Site, or otherwise incorporate
                                any portion of the Site into any product or service, without our
                                prior written authorization;</li>
                            <li>Use any robot, spider, site search/retrieval application or other
                                manual or automatic device or process to retrieve, index, scrape,
                                "data mine" or otherwise gather Site-related content or
                                information or in any way reproduce or circumvent the
                                navigational structure or presentation of the Site or its contents.
                                Notwithstanding the foregoing, and subject to compliance with
                                any instructions posted in the robots.txt file located in the Site’s
                                root directory, Planspace grants to the operators of public search
                                engines permission to use spiders to copy materials from the site
                                for the sole purpose of and solely to the extent necessary for
                                creating publicly available searchable indices of the materials, but
                                not caches or archives of such materials. Planspace reserves the
                                right to revoke these exceptions either generally or in specific
                                cases, at any time and without notice.</li>
                            <li>Use the Site to advertise or offer to sell or buy any goods or
                                services without our express prior written consent;
                            </li>
                            <li>Create a database by systematically downloading and storing Site
                                content;</li>
                            <li>
                                Use the Site to defame abuse, harass, stalk, threaten or otherwise
                                violate the legal rights of others, including without limitation
                                others' privacy rights or rights of publicity, or harvest or collect
                                information about Site visitors or members without their express
                                consent; or
                            </li>
                            <li>
                                Otherwise disrupt or interfere with the operation of the Site or the
                                servers or networks used to make the Site available; or violate any
                                requirements, procedures, policies or regulations of such
                                networks.
                            </li>
                        </ul>

                        Your use of the Site is conditioned on your compliance with the rules
                        of conduct set forth in this section; your failure to comply with such
                        rules may result in termination or suspension of your access to the
                        Site. If you download Software from the Site, such Software is
                        licensed on a limited basis to you by us or the owner of such Software.
                        Title to the Software is not transferred to you. You own the medium
                        on which the Software is recorded, but we or our affiliate partners
                        retain all right, title and interest in and to the Software, and all
                        intellectual property rights therein. You may not re-sell or transfer the
                        Software to any third party.

                        <h2>Forums and Public Communication</h2>

                        This Site may enable users to submit their own content to a chat area,
                        message board or e-mail function (“forum”). Any forum on the Site is a
                        free service to enable you to share comments about products and
                        services available on our Site. If you participate in a forum within the
                        Site, any information you disclose in such a submission may be
                        publicly available and you should exercise caution in deciding
                        whether to disclose any personal, financial or other sensitive
                        information there. By uploading or submitting any materials to a
                        forum, you automatically grant (or warrant that the owner of such
                        materials expressly granted) us a perpetual, royalty-free, irrevocable,
                        non-exclusive right and license to use, display and distribute such
                        materials or incorporate such materials into any form now known or
                        later developed. Planspace cannot prevent other persons who access
                        this Site from using submissions and personal information they find
                        here, even if their use of that information violates the law or your
                        personal privacy and safety.<br /><br />
                        When participating in a forum, you should not assume that people are
                        who they say they are, know what they say they know or are affiliated
                        with whom they say they are affiliated. Information obtained in a
                        forum may not be reliable and it is not a good idea to trade or make
                        any investment or other decision based solely or largely on
                        information you cannot confirm. We cannot be responsible for the
                        content or accuracy of any information and shall not be responsible
                        for any trading, investment or other decisions made based on such
                        information.<br /><br />
                        Planspace is proud to provide a forum for you to air your opinions on
                        the topics and items we feature. While we appreciate your time and
                        comments, we request that you refrain from including the following in
                        your posts: (i) profanity, obscenities, hateful, threatening or harassing
                        comments or images; (ii) discussion of illegal activities or intent; (iii)
                        solicitations for commercial activities or other statements that do not
                        generally relate to the designated topic of the forum; (iv) personally
                        identifiable information such as phone numbers, addresses or URLs;
                        (v) any information that violates any right of a third party or any law;
                        and (vi) any software or other materials that contain a virus or other
                        harmful component. Planspace reserves the right to edit or remove
                        content from any forum at any time and for any reason.<br /><br />
                        A testimonial board post is not an effective way to tell us you need
                        help with an order or Site feature. If you’d like to tell us about a
                        specific problem, please e-mail us. Planspace disclaims liability for
                        any failure on its part to provide help with a specific problem
                        disclosed on this Site.<br /><br />
                        <h1>Disclaimer of Warranties</h1>
                        In preparing this Web Site, we have tried to assure that the
                        information posted is accurate, complete and current as of the dates
                        indicated on the screens and that your use of the Site will be
                        uninterrupted and error-free. However, we make no guarantees or
                        warranties of any kind, express or implied, regarding the Content or
                        operation of the Site. You access and use this Site at your own risk.
                        The Content (including all information available on or through the
                        Site) is provided “AS IS” and we are not responsible for its use or
                        misuse. Access to this Site from locations where the Content may be
                        illegal is prohibited and you are responsible for compliance with
                        applicable local laws. You are also responsible for taking all necessary
                        precautions to assure that any material you obtain from this Site is
                        free from computer viruses, worms, Trojan horses or other
                        destructive devices. <br />
                        A possibility exists that the Site, including any Content, Software or
                        Forums could include inaccuracies or errors, or information or
                        materials that violate or conflict with these Terms. Additionally, a
                        possibility exists that unauthorized alterations could be made by third
                        parties to the Site, including to any Content, Software or Forums.
                        Although we try to maintain the integrity, timeliness, and security of
                        the Site, we make no guarantees that the Site is or will remain
                        updated, complete, correct or secure, or that access to the Site will be
                        uninterrupted. In the event that a situation arises in which the Site’s
                        completeness or correctness is in question, please visit
                        www.planspace.ca and send an email through the Contact Us section,
                        with, if possible, a description of the material to be checked and the
                        location (URL) where such material can be found on the Site, as well
                        as information sufficient to enable us to contact you. We will try to
                        address your concerns as soon as reasonably practicable.<br /><br />
                        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE
                        DISCLAIM, TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANY AND
                        ALL (I) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                        PARTICULAR PURPOSE (INCLUDING SUITABILITY OF THE SITE FOR
                        YOUR NEEDS OR REQUIREMENTS OR THOSE OF ANY OTHER PERSON),
                        (II) WARRANTIES AGAINST INFRINGEMENT OF ANY THIRD PARTY
                        INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (III)
                        WARRANTIES RELATING TO THE ACCURACY, RELIABILITY,
                        CORRECTNESS OR COMPLETENESS OF THE CONTENT, (IV)
                        WARRANTIES RELATING TO THE OPERATION OF THE SITE (INCLUDING
                        DELAYS, INTERRUPTIONS, ERRORS, VIRUSES, DEFECTS OR
                        OMISSIONS) AND (V) WARRANTIES OTHERWISE RELATING TO
                        PERFORMANCE, NONPERFORMANCE OR OTHER ACTS OR OMISSIONS
                        BY US OR ANY THIRD PARTY.
                        <h2>Links from this Web Site</h2>
                        We have provided hyperlinks from this Web Site to selected third
                        party Web sites as a convenience to you. However, by providing such
                        links, we are not endorsing such third parties or their Web sites. We
                        are not responsible for and make no representations about the
                        contents or use of such hyperlinked third party sites or any further
                        links contained there to other sites. If you use a Web site that is
                        hyperlinked to this Web Site, you do so at your own risk; we are not
                        responsible for assuring that hyperlinked sites are free from computer
                        viruses, worms, Trojan horses or other destructive devices. We may
                        discontinue our hyperlinks to any third party site at any time without
                        notice or obligation to you.
                        <h2>Links from this Web Site</h2>
                        If you wish to provide a hyperlink from your Web site to this Web Site,
                        you may do so only under the following conditions: (i) links to our Site
                        can be text- or image-based; (ii) the link can “point” to the homepage
                        URL www.planspace.ca or to deeper pages within our Site; (iii) when
                        selected by a user, the link must display our Site on full-screen and
                        not within a “frame” on the linking site; (iv) the appearance, position
                        and other attributes of the link may not imply that you or any related
                        organizations or entities are endorsed or sponsored by, affiliated with,
                        or associated with us; (v) the appearance, position and other aspects
                        of the link may not be such as to damage or dilute the goodwill
                        associated with our names and trademarks; (vi) the linked site may not
                        contain content that could be construed as distasteful, offensive or
                        controversial or that is not appropriate for all age, racial, religious and
                        other groups protected by law; and (vii) we reserve the right to revoke
                        our consent to the link at any time in our sole discretion and you will
                        remove the link immediately upon our request. By linking your Web
                        site to ours, you are agreeing to be bound by these terms.
                        <h2>Violations; and indemnity</h2>
                        We reserve the right to seek all remedies available at law and in equity
                        for violations of these Terms, including termination, suspension or
                        blocking of your access to this Web Site. Our failure to enforce any
                        provision of these Terms will not constitute a waiver of such provision
                        or of our legal or equitable rights. To the fullest extent permitted
                        under applicable law, you agree to defend, indemnify and hold
                        harmless us and the Affiliated Entities from and against any and all
                        damages, claims, liabilities, judgments, losses, awards, costs, expenses
                        and fees (including attorneys’ fees), arising out of or related to (i) your
                        violation or alleged violation of these Terms; (ii) any allegation that
                        any materials you submit to us or transmit to the Site (including any
                        Submissions) infringe or otherwise violate the copyright, trademark,
                        trade secret or other intellectual property or other rights of any third
                        party; and/or (iii) your access to or use of, or activities in connection
                        with, the Site (including any Content or Software).
                        <h2>Questions and Comments</h2>
                        We welcome questions, comments and other feedback about this
                        Web Site and about our products and services. However, please be
                        aware that we will treat all such communications as non-confidential
                        and may reproduce, use, disclose and distribute them without notice
                        or obligation to you.
                        <h2>Changes to this Site or these Terms</h2>
                        We may change or discontinue this Web Site at any time without prior
                        notice or obligation to you. We may also change these Terms at any
                        time without prior notice or obligation and your continued use of the
                        Site after such changes have been posted will constitute your
                        acceptance of the changes.
                        <h2>Limitation of Liability</h2>
                        We specifically disclaim all liability (whether based in contract, tort,
                        negligence, strict liability, by statute or otherwise) for any direct,
                        indirect, incidental, consequential, punitive or special damages
                        arising out of or relating to your access to and/or use of this Web Site
                        (including those arising from your reliance on the Site Content or on
                        any products or services obtained through the use of this Site, lost
                        data, computer viruses or other destructive devices, and delays,
                        breakdowns or interruptions in the use of the Site for any reason
                        whatsoever).
                        <h2>Governing Law</h2>
                        These Terms will be governed by and construed in accordance with
                        the laws of the Province of Ontario and Canada, without reference to
                        any choice of law rules under Ontario law. You agree that any action at
                        law or in equity arising out of or relating to these Terms or relating to
                        your use of this Web Site will be filed only in the provincial or federal
                        courts located in Ontario and you agree to submit to the personal
                        jurisdiction of such courts for the purposes of litigating any such
                        action.
                        These Terms may be supplemented by additional terms posted at
                        other locations on this Site, but these Terms will supersede in the
                        event of a conflict with such additional terms. These Terms and such
                        additional terms constitute our entire agreement with you regarding
                        your use of this Web Site.
                        <h2>Violations</h2>
                        We reserve the right to seek all remedies available at law and in equity
                        for violations of these Terms, including suspension or blocking of your
                        access to this Web Site. Our failure to enforce any provision of these
                        Terms will not constitute a waiver of such provision or of our legal or
                        equitable rights. You agree to defend and indemnify us and our
                        directors and employees from and against any and all damages,
                        claims, liabilities and costs arising directly or indirectly from your
                        violation of these Terms or your use of this Site.
                        <h2>Terms and Conditions for User Content</h2>
                        Planspace owns and/or operates various websites that permit users
                        (“you,
                        ” “User” or “Users”) to submit or publish content (“User
                        Content”). Such websites may include, but are not limited to, message
                        boards, blogs, social networks and comment boards. By registering or
                        submitting any User Content, you accept and agree to the following
                        Terms and Conditions for User Content.
                        <h2>Eligibility and registration</h2>
                        As part of the registration process, you will be asked to select a
                        username and you will be responsible for all activities occurring under
                        your username. We may refuse to grant you a username that
                        impersonates someone else, is or may be protected by trademark or
                        proprietary rights law, or is vulgar, offensive or otherwise
                        inappropriate, as determined by us in our sole discretion. You are
                        solely responsible for any and all uses of your username.
                        <h2>Appropriate conduct and content; personal use only</h2>
                        By acceptance of these Terms and Conditions, you agree that any
                        information you provide about yourself is accurate and complete at
                        the time provided. In particular, you represent that you are 21 years of
                        age or older.
                        You may not use Planspace websites for any illegal or unauthorized
                        purpose. International users agree to comply with all local rules
                        regarding online conduct and acceptable content, including laws
                        regulating the export of data from Canada or your country of
                        residence. You are solely responsible for your conduct and any data,
                        text, information, photos, links and other content that you submit,
                        post or display.
                        We may, without notice to you, but we shall have no obligation to,
                        refuse, edit and/or remove User Content that we determine in our sole
                        discretion is unlawful, fraudulent, threatening, libelous, defamatory,
                        obscene or otherwise objectionable, or infringes or violates any
                        party’s intellectual property or other proprietary rights or these
                        Terms and Conditions. We also reserve the right in our sole discretion
                        to refuse, edit and/or remove any content that may be deemed
                        inappropriate without providing notice to you.
                        <h2>Children's privacy</h2>
                        We are committed to protecting the privacy of children. These
                        websites are not intended or designed to attract children under the
                        age of 21. We do not collect personally identifiable information from
                        any person and as such we will not have actual knowledge when a
                        user is under the age of 21.
                        <h2>Changes to Terms and Conditions for User Content</h2>
                        We reserve the right to modify or terminate any User Content for any
                        reason, and without notice, without liability to you or any third party.
                        We also reserve the right to modify these Terms and Conditions from
                        time to time without notice. You are responsible for regularly
                        reviewing these Terms and Conditions so that you will be apprised of
                        any changes.
                        <h2>License to Use Materials</h2>
                        By submitting, posting or displaying any User Content, you
                        automatically grant Planspace a worldwide, non-exclusive, royaltyfree, irrevocable license to publish, create derivative works, publicly
                        display, re-display or otherwise use such User Content as it sees fit on
                        Planspace’s websites and in all other forms of electronic or nonelectronic media that are used by Planspace or its agents in
                        connection with the promotion, marketing, use or expansion of any of
                        its business.<br />
                        You represent to Planspace that you own all User Content you submit
                        and/or have the unencumbered legal right to grant the abovereferenced license with respect to all User Content you provide. You
                        further represent to Planspace that such materials do not infringe any
                        third party copyrights or other third party intellectual property rights.
                        You also certify to Planspace that each person whose identity can be
                        determined from an image/video/audio file has consented to appear
                        in, and to your use of, that image/video/audio file. You agree to fully
                        indemnify Planspace for any and all losses resulting from the
                        invalidity or inaccuracy of such representations and certifications.<br />
                        You may not copy, modify, publish, transmit, distribute, perform,
                        display or sell any third party submitted proprietary information or
                        Planspace’s proprietary information, which includes the sum of all
                        postings and photos/video/audio files to or on the site.<br />
                        Please do not send to any website anything that constitutes a trade
                        secret or confidential or proprietary information. We are not in a
                        position to accept such information, nor can we agree to obligations
                        of nondisclosure or confidentiality with regard to submitted plans or
                        ideas. You agree that any information or materials that you, or
                        individuals acting on your behalf, provide will not be considered
                        confidential or proprietary.<br />
                        <h2>Standard Terms and Conditions for Planspace Websites</h2>
                        In addition to these Terms and Conditions, you agree to be bound by
                        Planspace’s standard Terms and Conditions for its websites, as the
                        same may be modified and updated from time to time, with respect to
                        your use of Planspace’s websites.
                        <h2>Questions</h2>
                        If you have any questions about these Terms or this Web Site, please
                        send an email to info@planspace.ca
                     </p>
                </div>

            </div>
        </section>
    )
}

export default TermsOfUse;