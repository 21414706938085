import React from "react";
import 'stylesheets/comman/button.scss';

const ButtonSecond = props => {
    return (
        <div className={props.grouClass}>
            <button type="button" className={`btn btn__${props.type}`} onClick={props.click}>
                
                    {props.value}    
                
            </button>
        </div>
    )
}
export default ButtonSecond;