import React, { useEffect } from 'react';
import ScrollToTop from 'components/comman/scroll';
import { Toaster } from 'components/comman/Toaster';
import { useAuthContext } from 'context/auth/AuthContext';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { API } from 'API/API';
import './App.scss';
import Routes from './routes';
import { useSearchContext } from 'context/searchContext/searchContext';



function App() {

  const { login, assignToken } = useAuthContext()
  const { mapUserData } = useSearchContext()

  const getMainUser = async (token) => {
    try {
      let response = await API.UserProfile(token)
      const data = response.data
      if (response?.data) {
        assignToken(token)
        mapUserData(data)
      }
    }
    catch (error) {
      return error
    }
  }

  const TokenCheck = () => {
    let tokenn = localStorage.getItem('mytoken')
    if (!login) {
      if (tokenn && !window?.location?.search) {

        getMainUser(tokenn)
      }
    }
  }

  useEffect(() => {
    TokenCheck()
  }, [login])

  return (
    <div className="App">
      <React.StrictMode>
        <Router >
          <ScrollToTop />
          <Routes />
        </Router>
      </React.StrictMode>
    </div>
  );
}

export default App;
