import { API } from "API/API";
import Button from "components/comman/button";
import { useAuthContext } from "context/auth/AuthContext";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "../../stylesheets/brochureDocument.scss";
import { Images } from "constants/Images";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useSearchContext } from "context/searchContext/searchContext";
import ModalC from "components/comman/ModalC";

const SelectDocumentBrochure = (props) => {
  const obj = {
    maintenance_and_part: "Maintenance and part",
    maintenance_and_installation: "Maintenance and installation",
    repair_parts_diagram: "Repair parts diagram",
    spec_sheets_drawings: "Spec sheets drawings",
    specification_sheet_series_specific: "Specification sheet series specific",
    specifications_model_specific: "Specifications model specific",
    revit: "Revit",
  };

  const [ProjectData, setProjectData] = useState({});
  let arr = [1, 2, 3, 4, 5, 6, 7];
  const [OpenArray, setOpenArray] = useState([]);
  const [DocumentList, setDocumentList] = useState([]);
  const { token } = useAuthContext();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [modalId, setModalId] = useState("");
  const [loader, setLoader] = useState(false);
  const [sampleArr, setSamplearr] = useState([]);
  const [Nodata, setNodata] = useState();
  const [DocumentLoader, setDocumentLoader] = useState(false);
  const { mapBrochureList } = useSearchContext(); 

  const [showSelectallDocument, setShowSelectallDocument] = useState(false)
  const [showDSelectallDocument, setShowDSelectallDocument] = useState(false) 

  const [showSelectSpecDocument, setShowSelectSpecDocument] = useState(false)
  const [showDSelectSpecDocument, setShowDSelectSpecDocument] = useState(false) 

  const [showSelectInstructionDocument, setShowSelectInstructionDocument] = useState(false)
  const [showDSelectInstructionDocument, setShowDSelectInstructionDocument] = useState(false) 

  const [showSelectTechDocument, setShowSelectTechDocument] = useState(false)
  const [showDSelectTechDocument, setShowDSelectTechDocument] = useState(false)


  useEffect(() => {
    GetProjectDetails();
  }, [token]);

  const GetProjectDetails = async () => {
    setLoader(true);
    try {
      let response = await API.GetProjectsById(params.id, token);
      if (response.success) {
        if (
          response.data.products[0]?.location === null &&
          response.data.products.length === 1
        ) {
          setNodata(true);
        }
        setProjectData(response.data);
        const datas = response.data;
        setShowImage(datas?.brochureShowImage)
        const y = [];
        let result = datas.products.map((data) => {
          data.product.map((item) => {
            const x = {
              productInProjectId: item.productInProjectId,
              files: [],
            };
            item.files.map((file) => {
              if (item.productBrochureFiles?.includes(file._id)) {
                x.files.push(file._id);
              }
              // x.files.push({
              //      [file._id]: item.productSubmittalFiles?.includes(file._id)
              // })
              return file;
            });
            y.push(x);
          });
        });
        setDocumentList(y);
      }
    } catch (error) {
      return error;
    } finally {
      setLoader(false);
    }
  };

  // const mapToDocumentList = (response) =>{
  //      console.log("gone")
  //      let ids=[]
  //      response?.products?.forEach((data)=>{
  //           (data?.product?.forEach((item)=>{
  //                // setDocumentList([...DocumentList,item.productFiles])
  //                ids.push(item.productFiles)
  //           }))
  //      })
  //      let result = ids.flat()
  //      console.log("out",result)
  //      setDocumentList([...result])
  // }

  const onCard = (id) => {
    if (OpenArray.length === 0) {
      setOpenArray([id]);
    } else {
      if (OpenArray.includes(id)) {
        let result = OpenArray.filter((ids) => {
          return ids !== id;
        });
        setOpenArray([...result]);
      } else {
        setOpenArray([...OpenArray, id]);
      }
    }
  };

  const addDocument = async () => {
    setDocumentLoader(true);

    try {
      let response = await API.AddFilesBrochure(token, DocumentList, params.id);
      if (response.success) {
        toast.success(response.message);
        GetProjectDetails();
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      return error;
    } finally {
      setDocumentLoader(false);
    }
  };

  const selectAll = () => {
    const y = [];
    let result = ProjectData.products.map((data) => {
      data.product.map((item) => {
        const x = {
          productInProjectId: item.productInProjectId,
          files: [],
        };
        item.files.map((file) => {
          x.files.push(file._id);
          return file;
        });
        y.push(x);
      });
    });
    setDocumentList(y);
    mapBrochureList(y , showImage);
  };

  const deselectAll = () => {
    let arr = [...DocumentList];

    arr = arr.map((item) => {
      return { ...item, files: [] };
    });

    setDocumentList([...arr]);
    mapBrochureList([...arr] , showImage);
  };

  const handleDelete = (id) => {
    setShow(true);
    setModalId(id);
  };
  const handleClose = () => {
    setShow(false);
  };
  const ProjectdeleteData = async () => {
    setDeleteLoader(true);
    let param = { productId: [modalId] };
    try {
      let response = await API.productsdelete(token, param, params.id);
      if (response.success) {
        setShow(false);
        toast.success(response.message);
        GetProjectDetails();
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      return error;
    } finally {
      setDeleteLoader(false);
    }
  };

  const selectAllIndividual = (id) => {
    let arr = [...DocumentList];

    const filesIds = [];
    ProjectData.products.forEach((data) => {
      data.product.forEach((item) => {
        item.files.forEach((file) => {
          if (item.productInProjectId === id) {
            filesIds.push(file._id);
          }
        });
      });
    });

    arr = arr.map((item) => {
      const productInProjectId = item.productInProjectId;
      if (productInProjectId === id)
        return { ...item, files: [...item.files, ...filesIds] };
      return item;
    });

    setDocumentList([...arr]);
    mapBrochureList([...arr] , showImage);
  };

  const deSelectAllIndividual = (id) => {
    let arr = [...DocumentList];
    arr = arr.map((item) => {
      const productInProjectId = item.productInProjectId;
      if (productInProjectId === id) return { ...item, files: [] };
      return item;
    });

    setDocumentList([...arr]);
    mapBrochureList([...arr] , showImage);
  };

  const selectAllSheet = (key) =>{
    let arr = [...DocumentList];
    console.log("obj",DocumentList ,ProjectData.products  )
    let newArr = []
    ProjectData.products.forEach((data) => {
      data.product.forEach((item) => {

          let flArr = []
            // newArr?.push(item?.productInProjectId)
          item.files.forEach((file) => {
              if(file?.docName?.toLowerCase()?.includes(key?.toLowerCase())){
                flArr?.push(file?._id)
              } 
          });

          let obj = {
            productInProjectId : item?.productInProjectId,
            files : flArr
          }

          newArr?.push(obj)

      });
    });

    console.log("obj", newArr)

    // let resultArr = []
    let resultArr = arr?.map((me)=>{
      let obj = newArr?.find((ne)=> me?.productInProjectId === ne?.productInProjectId)

      let a = me?.files
      let b = obj?.files

      console.log("obj" ,obj)
      console.log("obj" ,a)
      console.log("obj" ,b)

      const combinedArray = a?.concat(b);

      // Create a Set to store unique elements
      const uniqueSet = new Set(combinedArray);

      // Convert the Set back to an array
      const resultArray = Array.from(uniqueSet);

      return {
        files : resultArray , productInProjectId  : me?.productInProjectId
      }

    })

    setDocumentList([...resultArr]);
    mapBrochureList([...resultArr] , showImage);



  }

  const deSelectAllSheet = (key) =>{
    let arr = [...DocumentList];
    console.log("obj",DocumentList ,ProjectData.products  )
    let newArr = []
    ProjectData.products.forEach((data) => {
      data.product.forEach((item) => {

          let flArr = []
            // newArr?.push(item?.productInProjectId)
          item.files.forEach((file) => {
              if(file?.docName?.toLowerCase()?.includes(key?.toLowerCase())){
                flArr?.push(file?._id)
              } 
          });

          let obj = {
            productInProjectId : item?.productInProjectId,
            files : flArr[0]
          }

          newArr?.push(obj)

      });
    });

    console.log("obj",newArr)

    let resultArr = arr?.map((me)=>{
      let obj = newArr?.find((ne)=> me?.productInProjectId === ne?.productInProjectId)

      let a = me?.files
      let b = obj?.files

      console.log("obj" ,obj)
      console.log("obj" ,a)
      console.log("obj" ,b)

      let resultArray = me?.files?.filter((me) => me !== obj?.files)

      

      return {
        files : resultArray , productInProjectId  : me?.productInProjectId
      }

    })

    console.log("obj",resultArr)

    setDocumentList([...resultArr]);
    mapBrochureList([...resultArr] , showImage);
  }

  const handleShowImage = (key) =>{
    if(key === 'yes'){
      setShowImage(true)
    mapBrochureList([...DocumentList] , true);

    }
    else{
      setShowImage(false)
    mapBrochureList([...DocumentList] , false);

    }
  }

  return (
    <section>
      <div className="container">
        <div className="selectDocument">
          <div className="selectDocument__bottom">
            <div className="bottomLeft">
            <h1>Select Images</h1>
              <p>Do you want to show the images in PDF? </p>

              <div style={{ marginBottom: "5px" }}>
                <div>
                  <input checked={showImage} onClick={()=>handleShowImage("yes")} name="selectImage" defaultChecked type="radio" id="yes" value="yes" />
                  <label style={{ marginRight: "10px" }} for="yes">Yes</label>
                  <input checked={!showImage} onClick={()=>handleShowImage("no")} name="selectImage"  type="radio" id="no" value="no" />
                  <label for="no">No</label>
                </div>
              </div>
              <br />
              <h1>Select Documents</h1>
              <p>
                Please select from specification sheets, instruction manuals and
                technical drawings to be added to your project package{" "}
              </p>

              <div>
              <div  className="dualButton">
                  <div style={{marginBottom:'10px'}} className="btn-lft">
                    <Button
                      type={showSelectallDocument ? "secondaryselect" : "secondary"}
                      value={"Select All Document"}
                      click={() => {
                        selectAll()
                        setShowSelectallDocument(true);
                        setShowDSelectallDocument(false)
                      }
                      }
                    />
                  </div>
                  
                  <div style={{marginBottom:'10px'}} className="btn-right">
                    <Button
                       type={showDSelectallDocument ? "secondaryselect" : "secondary"}
                       value={"Deselect All Document"}
                       click={() => {
                         deselectAll()
                         setShowDSelectallDocument(true)
                         setShowSelectallDocument(false)
                       }}
                    />
                  </div>

                  <p style={{margin:'0' ,marginBottom:'10px'}}>Spec sheets</p>

                  <div className="dualButton">
                    <div style={{marginBottom:'10px'}} className="btn-lft">
                      <Button
                       type={showSelectSpecDocument ? "secondaryselect" : "secondary"}
                       value={"Select all spec sheets"}
                       click={() => {
                         selectAllSheet("SPEC") 
                         setShowSelectSpecDocument(true)
                         setShowDSelectSpecDocument(false)

                       }
                       }
                      />
                    </div>
                    
                    <div style={{marginBottom:'10px'}} className="btn-right">
                      <Button
                         type={showDSelectSpecDocument ? "secondaryselect" : "secondary"}
                         value={"Deselect all spec sheets"}
                         click={() => {
                           deSelectAllSheet("SPEC")
                           setShowDSelectSpecDocument(true)
                           setShowSelectSpecDocument(false)
 
                         }}
                      />
                    </div>
                  </div>

                  <p style={{margin:'0' ,marginBottom:'10px'}}>Instruction sheet</p>

                  <div className="dualButton">
                    <div style={{marginBottom:'10px'}} className="btn-lft">
                      <Button
                         type={showSelectInstructionDocument ? "secondaryselect" : "secondary"}
                         value={"Select all Instruction sheet"}
                         click={() => {
                           selectAllSheet("Instruction")
                           setShowSelectInstructionDocument(true)
                           setShowDSelectInstructionDocument(false)
 
                         }
                         }
                      />
                    </div>
                    
                    <div style={{marginBottom:'10px'}} className="btn-right">
                      <Button
                        type={showDSelectInstructionDocument ? "secondaryselect" : "secondary"}
                        value={"Deselect all Instruction sheet"}
                        click={() => {
                          deSelectAllSheet("Instruction")
                          setShowSelectInstructionDocument(false)
                          setShowDSelectInstructionDocument(true)
                        }}
                      />
                    </div>
                  </div>

                  <p style={{margin:'0' ,marginBottom:'10px'}}>Technical drawings</p>

                  <div className="dualButton">
                    <div style={{marginBottom:'10px'}} className="btn-lft">
                      <Button
                        type={showSelectTechDocument ? "secondaryselect" : "secondary"}
                        value={"Select all Technical drawings"}
                        click={() => {
                          selectAllSheet("Technical drawing")
                          setShowDSelectTechDocument(false)
                          setShowSelectTechDocument(true)
                        }}
                      />
                    </div>
                    
                    <div style={{marginBottom:'20px'}} className="btn-right">
                      <Button
                        type={showDSelectTechDocument ? "secondaryselect" : "secondary"}
                        value={"Deselect all Technical drawings"}
                        click={() => {
                          deSelectAllSheet("Technical drawing")
                          setShowDSelectTechDocument(true)
                          setShowSelectTechDocument(false)
                        }}
                      />
                    </div>
                  </div>

                </div>

                <div className="docandfile">
                  <p>Are you still missing a few products?</p>
                  <Button
                    type={"secondary"}
                    value={"Add More Products"}
                    click={props.onClick2}
                  />
                </div>
              </div>
            </div>
            <div className="bottomRight">
              {loader ? (
                <div className="loaderD">
                  <ClipLoader />
                </div>
              ) : (
                <div className="row">
                  {!Nodata ? (
                    ProjectData?.products?.map(
                      (data) =>
                        data.location !== null &&
                        data?.product?.map((item) => (
                          <div
                            className="row__half"
                            key={item?.productInProjectId}
                          >
                            <div className="card">
                              {console.log(ProjectData?.products)}

                              <div className="card__head">
                                {item?.detail?.images?.map(
                                  (img) =>
                                    item?.colorId === img?._id && (
                                      <img
                                        src={
                                          img?.fileName === "" ||
                                          img?.fileName === null ||
                                          img?.fileName === undefined
                                            ? Images.image.productImg
                                            : `${process.env.REACT_APP_IMAGE_BASEURL}${img?.fileName}`
                                        }
                                        className="card__head__img"
                                        alt="text"
                                      />
                                    )
                                )}

                                <span className="card__head__title">
                                  {item?.detail?.name}
                                </span>
                                <button
                                  type="button"
                                  className="btn btn-text"
                                  onClick={() =>
                                    onCard(item?.productInProjectId)
                                  }
                                >
                                  {OpenArray.includes(
                                    item?.productInProjectId
                                  ) ? (
                                    <BsFillCaretUpFill />
                                  ) : (
                                    <BsFillCaretDownFill />
                                  )}
                                </button>
                              </div>
                              {OpenArray.includes(item?.productInProjectId) && (
                                <div className="card__body">
                                  <h4>Documents</h4>
                                  <ul>
                                    {item?.files?.length !== 0 ? (
                                      item?.files?.map((file) => (
                                        <li>
                                          {
                                            <div className="innerCard">
                                              <input
                                                type="checkbox"
                                                onChange={() => {
                                                  const element =
                                                    DocumentList.find(
                                                      (t) =>
                                                        t.productInProjectId ===
                                                        item.productInProjectId
                                                    );
                                                  if (element) {
                                                    if (
                                                      element.files.includes(
                                                        file._id
                                                      )
                                                    ) {
                                                      element.files.splice(
                                                        element.files.indexOf(
                                                          file._id
                                                        ),
                                                        1
                                                      );
                                                    } else {
                                                      element.files.push(
                                                        file._id
                                                      );
                                                    }

                                                    setDocumentList([
                                                      ...DocumentList.filter(
                                                        (fil) =>
                                                          fil.productInProjectId !=
                                                          element.productInProjectId
                                                      ),
                                                      element,
                                                    ]);
                                                    mapBrochureList([
                                                      ...DocumentList.filter(
                                                        (fil) =>
                                                          fil.productInProjectId !=
                                                          element.productInProjectId
                                                      ),
                                                      element,
                                                    ], showImage);
                                                  }
                                                }}
                                                checked={DocumentList.find(
                                                  (t) =>
                                                    t.productInProjectId ===
                                                    item.productInProjectId
                                                )?.files.includes(file._id)}
                                              />
                                              <div className="titele">
                                                {file?.docFile !== ""
                                                  ? file?.docName
                                                  : file?.linkPath}
                                              </div>
                                            </div>
                                          }
                                        </li>
                                      ))
                                    ) : (
                                      <div>
                                        <p>No data found</p>
                                      </div>
                                    )}
                                  </ul>
                                </div>
                              )}
                              <div className="card__foot">
                                <ul>
                                  <li
                                    onClick={() =>
                                      selectAllIndividual(
                                        item?.productInProjectId
                                      )
                                    }
                                  >
                                    Select All
                                  </li>
                                  <li
                                    onClick={() =>
                                      deSelectAllIndividual(
                                        item?.productInProjectId
                                      )
                                    }
                                  >
                                    Deselect All
                                  </li>
                                  <li
                                    className="remove"
                                    onClick={() =>
                                      handleDelete(item?.productInProjectId)
                                    }
                                  >
                                    Remove
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))
                    )
                  ) : (
                    <div className="noData">
                      <p>No data found</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <ModalC
          message="Do you want to delete this product?"
          title="Delete Product"
          showmodal={show}
          handleClose={handleClose}
          deleteLoader={deleteLoader}
          handleYes={ProjectdeleteData}
        />
      )}
    </section>
  );
};

export default SelectDocumentBrochure;
