import React, { useEffect, useState } from "react";
import classes from "./createProjectTools.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import EditProjectTab from "components/tabs/editProjectTab";
import Button from "components/comman/button";
import { useForm, useWatch } from "react-hook-form";
import { FaRegTimesCircle } from "react-icons/fa";
import CreateSubmittalLanding from "pages/createSubmittalLanding";
import ProjectToolAction from "./ProjectToolAction";
import ProjectSelection from "./ProjectSelection";
import { API } from "API/API";
import { useAuthContext } from "../../context/auth/AuthContext";
import { useSearchContext } from "context/searchContext/searchContext";
import ProjectAccordion from "./ProjectAccordion";
import { toast } from "react-toastify";
import MainProgressBar from "components/progressBar";
import ModalC from 'components/comman/ModalC';
import CreateProjectModal from 'components/modal/CreateProjectModal';
import { projectData } from "constants/ProjectData";


const pdfShowList = [
  {
    id: 1,
    value: "yes",
    name: "Yes",
  },
  {
    id: 2,
    value: "no",
    name: "No",
  },
];

const packageList = [
  {
    id: 1,
    value: "selectAll",
    name: "Select All",
  },
  {
    id: 2,
    value: "deSelectAll",
    name: "Deselect All",
  },
];  

// Add More Products Modal
const AddMoreProducts = ({ close, setAddIndicator, isFilter, projId, replaceProduct, handleCloseRemoveProductModal }) => {
  console.log('filter', replaceProduct);
  return (
    <div className={classes.moreProduct}>
      <div className={classes.moreProduct_wrap}>
        <button
          type="button"
          className={classes.moreProduct_wrap_close}
          onClick={close}
        >
          <FaRegTimesCircle />
        </button>
        <div className={classes.moreProduct_wrap_overlay}>
          <CreateSubmittalLanding modal setAddIndicator={setAddIndicator} isFilter={isFilter} projectId={projId} replaceProduct={replaceProduct} handleCloseRemoveProductModal={handleCloseRemoveProductModal} />
        </div>
      </div>
    </div>
  );
};

const CreateProjectTools = (props) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(localStorage.getItem("pname"));
  const [addProduct, setAddProduct] = useState();
  const [updateProduct, setUpdateProduct] = useState();
  const [addIndicator, setAddIndicator] = useState(true);
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState(null);
  const [products, setProducts] = useState([]);
  const [locationTag, setLocationTag] = useState([]);
  const [loader, setLoader] = useState(false);


  const [DocumentList, setDocumentList] = useState([]);
  const [ProjectData, setProjectData] = useState({});
  const [showImage, setShowImage] = useState(false);
  // const [submittalEmail, setSubmittalEmail] = useState("");
  const [submittalImage, setSubmittalImage] = useState(null);
  const { setDocList } = useSearchContext();
  const [totalProduct, setTotalProduct] = useState(0);

  const [PDFLoader, setPDFLoader] = useState(false);
  const [percent, setPercent] = useState(5);

  const [show, setShow] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [rowData, setRowData] = useState({});
  const [modal, setModal] = useState(false);
  const [modalID, setModalID] = useState('')
  const [modalRowData, setModalRowData] = useState({});

  //drag
  const [orderList, setOrderList] = useState([])
  const { mapBrochureOrderList } = useSearchContext();
  const [projData, setProjData] = useState();

  const [replaceProduct, setReplaceProduct] = useState({
    productName: "",
    productId: null,
    locationId: null,
    color: null
  });

  const { control, setValue, getValues } = useForm({
    defaultValues: {
      pdfShow: "",
      selectionPackage: "",
      specSheets: "",
      instructionSheets: "",
      technicalDrawings: "",
      appendLogo: "",
      emailProduct: "",
    },
  });


  const params = useParams();


  const { token } = useAuthContext();

  const { brochurelist, showImageKeyBrochure } = useSearchContext();

  let obj = {
    filesArr: brochurelist,
    showImages: showImageKeyBrochure,
  };

  const regex =
    "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)";

  const watchedValues = useWatch({
    control,
    name: [
      "pdfShow",
      "selectionPackage",
      "specSheets",
      "instructionSheets",
      "technicalDrawings",
      "appendLogo",
      "emailProduct",
    ],
  })

  const obj2 = [
    "pdfShow",
    "selectionPackage",
    "specSheets",
    "instructionSheets",
    "technicalDrawings",
    "appendLogo",
    "emailProduct",
  ];


  //user preference and details to send to api
  // const userDetails = {
  //   imagePreference: watchedValues[0],
  //   logo: watchedValues[5],
  //   userEmail: watchedValues[6]
  // }


  // add product data api
  const showProjectProduct = async () => {
    setLoading(true);
    const response = await API.GetProjectsById(params.id, token);
    try {
      if (response?.success) {
        const Data = response?.data?.products;
        setProducts(Data);

        // document select code 
        const data = response.data;
        // setShowImage(data?.submittalShowImages)

        const y = [];

        let result = data.products.map((data) => {
          data.product.map((item) => {
            const x = {
              productInProjectId: item.productInProjectId,
              files: [],
            };
            item.files.map((file) => {
              if (item.productSubmittalFiles?.includes(file._id)) {
                x.files.push(file._id);
              }
              // x.files.push({
              //      [file._id]: item.productSubmittalFiles?.includes(file._id)
              // })
              return file;
            });
            y.push(x);
          });
        });
        localStorage.removeItem("pname");
        localStorage.setItem("pname", response.data.name);
        // console.log('data', response.data.products);
        setTitle(response.data.name);
        setRowData(response.data);
        setTotalProduct(y?.length)
        setDocumentList(y);
        setProjectData(response.data);
        setProjData(response.data.products);
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
      // setAddIndicator(false);
    }
  };

  //get location DropDown menu api
  const getLocationDropdownMenu = async () => {
    try {
      const response = await API.getLocationList(token);
      setLocationTag(response?.data || []);
    } catch (error) {
      return error;
    } finally {
    }
  };

  // product delete api 
  const handleProductDelete = async (productId) => {
    try {
      const response = await API.productsdelete(params.id, token, productId);
      if (response?.success) {
        toast.success('Product deleted successfully');
        showProjectProduct()
      }
    } catch (error) {
      return error;
    } finally {
    }
  };

  // select documents functions
  const selectAll = () => {
    const y = [];
    let result = ProjectData.products.map((data) => {
      data.product.map((item) => {
        const x = {
          productInProjectId: item.productInProjectId,
          files: [],
        };
        item.files.map((file) => {
          x.files.push(file._id);
          return file;
        });
        y.push(x);
      });
    });
    setDocumentList(y);
    // props.getList(DocumentList)
    // setDocList(y, showImage);
  };

  const deselectAll = () => {
    let arr = [...DocumentList];

    arr = arr.map((item) => {
      return { ...item, files: [] };
    });

    setDocumentList([...arr]);
    // setDocList([...arr], showImage);
  };

  const selectAllSheet = (key) => {
    let arr = [...DocumentList];
    let newArr = [];
    ProjectData.products.forEach((data) => {
      data.product.forEach((item) => {
        let flArr = [];
        // newArr?.push(item?.productInProjectId)
        item.files.forEach((file) => {
          if (file?.docName?.toLowerCase()?.includes(key?.toLowerCase())) {
            flArr?.push(file?._id);
          }
        });

        let obj = {
          productInProjectId: item?.productInProjectId,
          files: flArr,
        };

        newArr?.push(obj);
      });
    });

    // let resultArr = []
    let resultArr = arr?.map((me) => {
      let obj = newArr?.find(
        (ne) => me?.productInProjectId === ne?.productInProjectId
      );

      let a = me?.files;
      let b = obj?.files;

      const combinedArray = a?.concat(b);

      // Create a Set to store unique elements
      const uniqueSet = new Set(combinedArray);

      // Convert the Set back to an array
      const resultArray = Array.from(uniqueSet);

      return {
        files: resultArray,
        productInProjectId: me?.productInProjectId,
      };
    });

    setDocumentList([...resultArr]);
    // setDocList([...resultArr], showImage);
  };

  const deSelectAllSheet = (key) => {
    let arr = [...DocumentList];
    let newArr = [];
    ProjectData.products.forEach((data) => {
      data.product.forEach((item) => {
        let flArr = [];
        // newArr?.push(item?.productInProjectId)
        item.files.forEach((file) => {
          if (file?.docName?.toLowerCase()?.includes(key?.toLowerCase())) {
            flArr?.push(file?._id);
          }
        });

        let obj = {
          productInProjectId: item?.productInProjectId,
          files: flArr[0],
        };

        newArr?.push(obj);
      });
    });

    let resultArr = arr?.map((me) => {
      let obj = newArr?.find(
        (ne) => me?.productInProjectId === ne?.productInProjectId
      );

      let a = me?.files;
      let b = obj?.files;

      let resultArray = me?.files?.filter((me) => me !== obj?.files);

      return {
        files: resultArray,
        productInProjectId: me?.productInProjectId,
      };
    });

    setDocumentList([...resultArr]);
    // setDocList([...resultArr], showImage);
  };

  // usEffect handling
  useEffect(() => {
    if (token) {
      showProjectProduct();
      getLocationDropdownMenu();
    }
  }, [token]);

  useEffect(() => {
    return () => {
      if (sessionStorage.getItem(params.id)) {
        sessionStorage.removeItem(params.id);
      }
    }
  }, [])

  useEffect(() => {
    if (PDFLoader) {
      const intervalid = setInterval(() => {
        if (percent < 91) {
          // Generate a random number between 1 and 5
          const randomNumber = Math.floor(Math.random() * 5) + 1;

          // Add the random number to the current percentage
          const newPercent = percent + randomNumber;

          // Ensure the new percentage does not exceed 95
          setPercent(newPercent > 91 ? 91 : newPercent);
        }
      }, 2000);
      return () => clearInterval(intervalid);
    }
  }, [percent, PDFLoader]);



  // functions for onchange
  const setAllDocument = (val) => {
    if (val === "selectAll") {
      selectAll();
    } else if (val === 'deSelectAll') {
      deselectAll();
    }
  }

  const setAllSpecDocument = (val) => {
    if (val === "selectAll") {
      selectAllSheet("SPEC");
    } else if (val === 'deSelectAll') {
      deSelectAllSheet("SPEC");
    }

  }

  const setAllInstructionDocument = (val) => {
    if (val === "selectAll") {
      selectAllSheet("Instruction");
    } else if (val === 'deSelectAll') {
      deSelectAllSheet("Instruction");
    }
  }

  const setAllTechDocument = (val) => {
    if (val === "selectAll") {
      selectAllSheet("Technical drawing");
    } else if (val === 'deSelectAll') {
      deSelectAllSheet("Technical drawing");
    }
  }

  const setShowImages = (val) => {
    if (val === 'yes') {
      setShowImage(true);
    } else {
      setShowImage(false);
    }
  }

  const getAppendLogo = (val) => {
    setSubmittalImage(val);
  }

  //submittal api
  // generate pdf submittal api 
  const handlePdfSubmittal = async () => {
    const submittalEmail = getValues('emailProduct')
    let payload = {
      sentToEmail: true,
      download: false,
      email: submittalEmail,
      submittal: true,
      brochure: false,
      image: submittalImage,
    };

    try {
      let response = await API.generatePdfSubmittal(params.id, token, payload);
    } catch (error) {
      return error;
    } finally {
      toast.success("Email sent successfully.");
      setPercent(100);
      setTimeout(() => {
        navigate("/final-submittal");
      }, 1000);
    }

  }

  // add files submittal
  const handleAddFilesSubmittal = async () => {

    const submittalEmail = getValues('emailProduct')

    if (!submittalEmail.match(regex)) {
      toast.error("Please provide valid email address.");
    } else {
      setPDFLoader(true);
      const payloadObj = {
        showImages: showImage,
        filesArr: DocumentList
      }

      try {
        const response = await API.AddFilesSubmittal(token, payloadObj, params.id);
        if (response?.success) {
          handlePdfSubmittal();
        }
      } catch (error) {
        return error;
      } finally {
      }

    }
  };


  // brochure api
  const handlePdfBrochure = async () => { 
    const submittalEmail = getValues('emailProduct')

    let payload = {
      sentToEmail: true,
      download: false,
      email: submittalEmail,
      submittal: false,
      brochure: true,
      image: submittalImage,
    };

    try {
      let response = await API.generatePdfSubmittal(params.id, token, payload);
    } catch (error) {
      return error;
    } finally {
      toast.success("Email sent successfully.");
      setPercent(100);
      setTimeout(() => {
        navigate("/final-brochure");
      }, 1000);
    }
  }

  const handleAddFilesBrochure = async () => {
    const submittalEmail = getValues('emailProduct')

    if (!submittalEmail.match(regex)) {
      toast.error("Please provide valid email address.");
    } else {
      setPDFLoader(true);
      const payloadObj = {
        showImages: showImage,
        filesArr: DocumentList
      }
      try {
        const response = await API.AddFilesBrochure(token, payloadObj, params.id);
        if (response?.success) {
          handlePdfBrochure();
        } else {
          toast.error(response.error);
        }
      } catch (error) {
        return error;
      } finally {
      }
    }
  }

  // edit project code 
  const onAddProject = () => {
    setModal(false);
    showProjectProduct()

  }

  const onCreareProject = () => {
    setModalRowData({})
    setModal(!modal);
  }

  const handleEditProject = () => {
    setModal(true)
    setModalID(params.id)
    setModalRowData(rowData);
  }

  // delete project code
  const ProjectdeleteData = async () => {

    setDeleteLoader(true)
    try {
      const response = await API.projectdelete(params.id, token);

      if (response.success) {
        // fetchListingData()
        toast.success(response.message)
        navigate('/projects')
      }
      else
        toast.error(response.message)

    }
    catch (e) {
      toast.error('Network Error')

    }
    finally {
      setDeleteLoader(false)
      setShow(false)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleDeleteProject = () => {
    setShow(true)
  }




  // update location api 
  const handleUpdateLocation = async (locationName, locationId) => {
    const payload = {
      type: "location",
      locationName: locationName,
      oldLocationId: locationId
    }
    try {
      const response = await API.updateProjectInfo(payload, params.id, token);
      if (response?.success) {
        toast.success('Location update successfully');
        showProjectProduct();
      }
    } catch (error) {
      return error;
    } finally {
    }
  }

  // update price api
  const handleUpdatePrice = async (updatedPrice, productId) => {
    const payload = {
      type: "price",
      priceArr: [{
        productInProjectId: productId,
        price: updatedPrice
      }],
    }
    try {
      const response = await API.updateProjectInfo(payload, params.id, token);
      if (response?.success) {
        showProjectProduct();
      }
    } catch (error) {
      return error;
    } finally {
    }
  }

  // update notes api 
  const handleUpdateNotes = async (notes, productId) => {
    const payload = {
      type: "note",
      productInProjectId: productId,
      note: notes
    }
    try {
      const response = await API.updateProjectInfo(payload, params.id, token);
      if (response?.success) {
        showProjectProduct();
      }
    } catch (error) {
      return error;
    } finally {
    }
  }

  //drag & drop with button
  const moveUp = (id, index, sourceId) => {
    let sourceIndex = index;
    let destIndex = index - 1;

    console.log("fired");
    let result = []


    let finalResult = products?.map((data) => {

      if (data?.location?._id === id) {
        result = Array.from(data.product);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destIndex, 0, removed);

        return { ...data, product: result }
      }
      else {
        return data
      }
    })

    setProducts([...finalResult])
    finalResult.forEach((data, index) => {
      let arr = []
      {
        data.product.forEach((item, secIndex) => {

          let param = {
            productInProjectId: item.productInProjectId,
            productIndex: secIndex + 5
          }
          console.log(param, "sss");
          orderList.push(param)
        })
      }

    })
    console.log('tt1', orderList);
    mapBrochureOrderList(orderList);
  }

  const moveDown = (id, index) => {
    let sourceIndex = index;
    let destIndex = index + 1;

    let result = []

    let finalResult = products?.map((data) => {

      if (data?.location?._id === id) {
        result = Array.from(data.product);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destIndex, 0, removed);

        return { ...data, product: result }
      }
      else {
        return data
      }
    })

    setProducts([...finalResult])
    finalResult.forEach((data, index) => {
      let arr = []
      {
        data.product.forEach((item, secIndex) => {

          let param = {
            productInProjectId: item.productInProjectId,
            productIndex: secIndex + 5
          }

          orderList.push(param)
        })
      }

    })
    console.log('tt2', orderList);
    mapBrochureOrderList(orderList)
  }

  const onDragEnd = (e, id) => {
    let sourceIndex = e.source.index;
    let destIndex = e.destination.index;

    let result = []


    let finalResult = products?.map((data) => {

      if (data?.location?._id === id) {
        result = Array.from(data.product);

        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destIndex, 0, removed);

        return { ...data, product: result }
      }
      else {
        return data
      }
    })

    setProducts([...finalResult])

    finalResult.forEach((data, index) => {
      let arr = []
      {
        data.product.forEach((item, secIndex) => {

          let param = {
            productInProjectId: item.productInProjectId,
            productIndex: secIndex + 5
          }

          orderList.push(param)
        })
      }

    })
    console.log('tt3', orderList);
    mapBrochureOrderList(orderList)
  }


  const brochureOrderList = useSearchContext();
  console.log('dfdf', brochureOrderList);
  const handleUpdateProductIndex = async () => {


    const payload = brochureOrderList.brochureOrderList;


    payload.forEach((item1) => {
      products.forEach((item2, index2) => {
        if (item2.product.some(product => product.productInProjectId === item1.productInProjectId)) {
          item1.locationIndex = index2;
        }
      });
    });

    // Now newArray1 will have the updated array with locationIndex added where applicable
    console.log("fftf", payload);


    try {
      let response = await API.UpdateIndex(payload, params.id, token);
      if (response?.success) {
        handleAddFilesBrochure();
      }
    } catch (error) {
      return error;
    } finally {
    }
  }

  const openAddProductModal = (projectId, productName, productId, locationId, color) => {
    console.log("filter2", productName, productId, locationId, color)
    setReplaceProduct({
      productName: productName,
      productId: productId,
      locationId: locationId,
      color: color
    });
    setUpdateProduct(!updateProduct)
  }


  const handleCloseRemoveProductModal = () => {
    setUpdateProduct(false)
    showProjectProduct();
  }

  const handleDragEndOuter = (e) => {
    if (!e.destination) {
      return;
    }

    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;

    const updatedProducts = [...products];

    const [removed] = updatedProducts.splice(sourceIndex, 1);
    updatedProducts.splice(destIndex, 0, removed); 

    setProducts(updatedProducts);

    console.log('check', updatedProducts);

    updatedProducts.forEach((data, index) => {
      let arr = []
      {
        data.product.forEach((item, secIndex) => {

          let param = {
            productInProjectId: item.productInProjectId,
            productIndex: secIndex + 5
          }

          orderList.push(param)
        })
      }

    })
    console.log('tt3', orderList);
    mapBrochureOrderList(orderList)
  }

  console.log('stst', products)

  return (
    <div className={classes.wrapper}>
      <div className="container">
        <div className={classes.wrapper_ttlWrap}>
          <h2 className={classes.wrapper_title}>
            {title?.charAt(0).toUpperCase() + title?.slice(1)}
          </h2>
          <span
            className={classes.wrapper_back}
            onClick={() => navigate("/projects")}
          >
            Back To Project
          </span>
        </div>
        <div className={classes.wrapper_row}>
          <div className={classes.wrapper_row_colMd8}>
            <div className={classes.wrapper_form}>
              <EditProjectTab />
            </div>
            <div className={classes.wrapper_addProducts}>
              <label className={classes.wrapper_addProducts_lbl}>
                Are you still missing a few products?
              </label>
              <Button
                value="ADD MORE PRODUCTS"
                click={() => setAddProduct(!addProduct)}
                type="primary"
                grouClass={classes.wrapper_addProducts_btnWrap}
              />
            </div>
            {addProduct && (
              <AddMoreProducts
                close={() => {
                  setAddProduct(false)
                  showProjectProduct();
                }}
                setAddIndicator={setAddIndicator}
                isFilter={false}
                projId={params.id}
              />
            )}

            {updateProduct && (
              <AddMoreProducts
                close={() => {
                  setUpdateProduct(false)
                  showProjectProduct();
                }}
                setAddIndicator={setAddIndicator}
                isFilter={true}
                projId={params.id}
                replaceProduct={replaceProduct}
                handleCloseRemoveProductModal={handleCloseRemoveProductModal}
              />
            )}
          </div>
          <div className={classes.wrapper_row_colMd4}>
            <ProjectToolAction handleAddFilesBrochure={handleAddFilesBrochure} handleAddFilesSubmittal={handleAddFilesSubmittal} handleEditProject={handleEditProject} handleDeleteProject={handleDeleteProject} handleUpdateProductIndex={handleUpdateProductIndex} />
            <ProjectSelection
              control={control}
              pdfShowList={pdfShowList}
              packageList={packageList}
              setAllDocument={setAllDocument}
              setAllSpecDocument={setAllSpecDocument}
              setAllInstructionDocument={setAllInstructionDocument}
              setAllTechDocument={setAllTechDocument}
              setShowImages={setShowImages}
              getAppendLogo={getAppendLogo}
            />
          </div>
        </div>

        <ProjectAccordion
          mainTitle={title?.charAt(0).toUpperCase() + title?.slice(1)}
          allProducts={products}
          locationTag={locationTag}
          handleProductDelete={handleProductDelete}
          loading={loading}
          DocumentList={DocumentList}
          setDocumentList={setDocumentList}
          setDocList={setDocList}
          totalProduct={totalProduct}
          handleUpdateLocation={handleUpdateLocation}
          handleUpdatePrice={handleUpdatePrice}
          handleUpdateNotes={handleUpdateNotes}
          moveUp={moveUp}
          moveDown={moveDown}
          onDragEnd={onDragEnd}
          openAddProductModal={openAddProductModal}
          handleDragEndOuter={handleDragEndOuter}
        />

        {PDFLoader && (
          <div
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              height: "100%",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#00000090",
              zIndex: 99,
              padding: "18px",
            }}
          >
            <div style={{
              maxWidth: "800px",
              margin: "0 auto",
              width: "100%",
            }}>
              <MainProgressBar
                isLabelVisible={true}
                value={percent}
                height={"40px"}
              />
            </div>
          </div>
        )}

        {show && <ModalC message="Do you want to delete this record?" title="Delete Records"
          showmodal={true} handleClose={handleClose}
          deleteLoader={deleteLoader} handleYes={ProjectdeleteData} />
        }

        <CreateProjectModal rowID={rowData} clickProject={onCreareProject}
          clickTable={onAddProject} show={modal} />
      </div>
    </div>
  );
};

export default CreateProjectTools;
