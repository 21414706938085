import Button from "components/comman/button";
import ProjectDetailTab from "components/tabs/ProjectDetailTab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillExclamationCircle } from "react-icons/ai";
import InputBox from "components/comman/InputBox";
import { useSearchContext } from 'context/searchContext/searchContext';



const GenerateSubmittal = (props) => {
    let navigate = useNavigate()
    const { handleSubmit, control, setValue, getValue } = useForm({
    });
    const{setSubmittalMail,submittalEmail , submittalImage} = useSearchContext()
    const [selectedImagePreview, setSelectedImagePreview] = useState(null);

    const [mail, setMail] = useState("")
    const [selectedImage, setSelectedImage] = useState(null);

    const handleChane = (e) => {
        setMail(e.target.value)
        setSubmittalMail(e.target.value, submittalImage)
    }

    console.log("obj",submittalImage )



    useEffect(()=>{
        console.log("obj",submittalImage )
        if (submittalImage) {
            // Set the selected image file
            setSelectedImage(submittalImage);
      
            // Optionally, you can use FileReader to show a preview of the image
            const reader = new FileReader();
            reader.onload = (e) => {
              // Set the preview image URL
              setSelectedImagePreview(e.target.result);
            };
            reader.readAsDataURL(submittalImage);
          } 
       
    },[submittalImage]) 

    useEffect(()=>{
        if(submittalEmail){
           setMail(submittalEmail)
        }  
    },[submittalEmail])


    const handleImageChange = (event) => {

      const file = event.target.files[0];
  
      if (file) {
        // Set the selected image file
        setSelectedImage(file);
  
        // Optionally, you can use FileReader to show a preview of the image
        const reader = new FileReader();
        reader.onload = (e) => {
          // Set the preview image URL
          setSelectedImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
      }

      setSubmittalMail(mail, file)
    };
    
    return (
        <section >
            <div className="container">
                <div className="editProject">

                    <div className="editProject__bottom">
                        <div className="bottomLeft">
                            <h1>Project Review</h1>
                            <p>Project details appear on the final Submittal Prep and are mostly optional.
                                We recommend filling them all out to provide a comprehensive submittal package.</p>
                            <Button type={"secondary"} value={"Edit Project Details"} click={props.onClick} />
                            <br />
                            <div>
                                <div className="generation-method">
                                    <h2>Append logo</h2>
                                    <span className="generation-icon">
                                        <AiFillExclamationCircle />
                                    </span>
                                </div>

                                <div className="generation">
                                    <input style={{marginBottom:'10px'}}  type="file" accept="image/*" onChange={handleImageChange} />

                                    {selectedImage && (
                                        <div>
                                        <p>Selected Image:</p>
                                      
                                        <img style={{width:"100px"}}src={selectedImagePreview} alt="Preview" />
                                        </div>
                                    )}
                                    
                                </div>
                                <br/>

                                <div className="generation-method">
                                    <h2>Generation Method</h2>
                                    <span className="generation-icon">
                                        <AiFillExclamationCircle />
                                    </span>
                                </div>

                                <div className="generation">
                                    <div className="input-gen">
                                        <input  checked type="radio"
                                            id="emailRadio" name="generation" value="email" />
                                        <label for="emailRadio">Email Submittal</label>
                                    </div>
                                    
                                    <div className="input-main">
                                    <input type="email" onChange={(e)=>handleChane(e)}
                                      value={submittalEmail?submittalEmail:""}
                                     placeholder="dummymail@gmail.com" />
                                    </div>
                                </div>
                                <br />



                                <br />
                                <div className="docandfile">
                                    <h3>Document and File Review</h3>
                                    <p>Are you still missing a few products?</p>
                                    <Button type={"secondary"} value={"Add More Products"} click={props.onClick2} />
                                </div>
                            </div>
                        </div>
                        <div className="projectDetailTabmargin">
                            <ProjectDetailTab />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default GenerateSubmittal