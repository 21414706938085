import React from "react";
import InputBox from "components/comman/InputBox";
import SelectBox from "components/comman/SelectBox";
import classes from "./createProjectTools.module.scss";
import { FaExclamationCircle } from "react-icons/fa";


const CustomInputWrap = ({ title, children }) => {
  return (
    <div className={classes.customInput}>
      <label className={classes.customInput_ttl}>
        {title} <FaExclamationCircle className={classes.customInput_icon} />
      </label>
      {children}
    </div>
  );
};

function ProjectSelection({ control, pdfShowList, packageList, setAllDocument, setAllSpecDocument, setAllInstructionDocument, setAllTechDocument, setShowImages, getAppendLogo }) {
  return (
    <div className={classes.wrapper_selection}>
      <h3 className={classes.wrapper_selection_ttl}>Project Selection</h3>
      <div className={classes.wrapper_selection_wrap}>
        <SelectBox
          label="Do you want to show the images in PDF?"
          control={control}
          selected="Select"
          selectList={pdfShowList}
          name="pdfShow"
          selectDocuments={(val) => { setShowImages(val) }}
        />
        <SelectBox
          label="Please select from specification sheets, instruction manuals and technical drawings to be added to your project package"
          control={control}
          selected="Select"
          selectList={packageList}
          name="selectionPackage"
          selectDocuments={(val) => { setAllDocument(val) }}
        />
        <SelectBox
          label="Spec Sheets"
          control={control}
          selected="Select"
          selectList={packageList}
          name="specSheets"
          selectDocuments={(val) => { setAllSpecDocument(val) }}
        />
        <SelectBox
          label="Instruction Sheets"
          control={control}
          selected="Select"
          selectList={packageList}
          name="instructionSheets"
          selectDocuments={(val) => { setAllInstructionDocument(val) }}
        />
        <SelectBox
          label="Technical Drawings"
          control={control}
          selected="Select"
          name="technicalDrawings"
          selectList={packageList}
          selectDocuments={(val) => { setAllTechDocument(val) }}
        />
        <CustomInputWrap title="Append logo">
          <div className={classes.customInput_wrap}>
            <input type="file" name="appendLogo"
              onChange={(e) => {
                const file = e.target.files[0];
                getAppendLogo(file);
              }}
            />
          </div>
        </CustomInputWrap>
        <CustomInputWrap title="Generation Method">
          <div className={classes.customInput_wrap}>
            <label className={classes.customInput_wrap_radio}>
              <input type="radio" checked />
              <span>Email Submittal</span>
            </label>
            <InputBox
              grouClass={classes.customInput_wrap_field}
              placeholder="dummymail@gmail.com"
              control={control}
              name="emailProduct"
            />
          </div>
        </CustomInputWrap>
      </div>
    </div>
  );
}

export default ProjectSelection;
