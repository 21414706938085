import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "./comman/button";
import InputBox from "./comman/InputBox";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { async } from "q";
import { API } from "API/API";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';


const mess = "Email must be in valid format."

const schema = yup.object({
    email: yup.string().required(mess).email(mess),
})


const ResetPasswordForm = (props) => {
    const [loader,setLoader]=useState(false)
    const onSubmitform = (data) => {
        SendMail()
    }
    const { handleSubmit, control, setValue, getValues } = useForm({
        resolver: yupResolver(schema)
    });

    const navigate=useNavigate()
   
    // const resett = ()=>{
    //     navigate('/reset-password')

    // }
    const SendMail = async () =>{
        setLoader(true)
        let params={email:getValues('email')}
        try{
            let response = await API.changePassword(params)
            if(response.success){
                toast.success(response.message)
               props.toLogin()
                
            }
            else{
                toast.error(response.message)
                    
            }
        }
        catch(error){
            return error
        }
        finally{
            setLoader(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmitform)}>
            <InputBox placeholder={"Enter Email"} control={control} label="Email Addressss"
             type={'text'} name="email" />
            <div className="form-group text-center">
                {loader?<ClipLoader/> :
                <Button value="Send Email" type="primary" />
                }
            </div>
            <div className="form-group text-center">
                <span className="forgot" onClick={props.toLogin}>Login/Signup</span>
            </div>
        </form>
    )
}

export default ResetPasswordForm;