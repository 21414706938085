import { Images } from "./Images";

export const filterList = [
    {
        id: 1,
        title: 'Manufacturer',
        child: [
            {
                id: '1-1',
                title: 'Delta',
                child: [
                    {
                        id: '1-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '1-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '1-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '1-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '1-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '1-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '1-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '1-2',
                title: 'Brizo',
                child: [
                    {
                        id: '1-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '1-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '1-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '1-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '1-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '1-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '1-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '1-3',
                title: 'Peerless',
                child: [
                    {
                        id: '1-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '1-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '1-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '1-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '1-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '1-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '1-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        title: 'Building Type',
        child: [
            {
                id: '2-1',
                title: 'Delta',
                child: [
                    {
                        id: '2-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '2-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '2-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '2-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '2-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '2-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '2-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '2-2',
                title: 'Brizo',
                child: [
                    {
                        id: '2-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '2-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '2-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '2-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '2-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '2-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '2-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '2-3',
                title: 'Peerless',
                child: [
                    {
                        id: '2-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '2-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '2-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '2-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '2-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '2-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '2-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        title: 'Project Type',
        child: [
            {
                id: '3-1',
                title: 'Delta',
                child: [
                    {
                        id: '3-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '3-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '3-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '3-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '3-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '3-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '3-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '3-2',
                title: 'Brizo',
                child: [
                    {
                        id: '3-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '3-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '3-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '3-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '3-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '3-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '3-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '3-3',
                title: 'Peerless',
                child: [
                    {
                        id: '3-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '3-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '3-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '3-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '3-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '3-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '3-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 4,
        title: 'Product Category',
        child: [
            {
                id: '4-1',
                title: 'Delta',
                child: [
                    {
                        id: '4-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '4-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '4-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '4-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '4-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '4-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '4-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '4-2',
                title: 'Brizo',
                child: [
                    {
                        id: '4-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '4-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '4-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '4-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '4-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '4-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '4-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '4-3',
                title: 'Peerless',
                child: [
                    {
                        id: '4-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '4-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '4-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '4-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '4-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '4-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '4-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 5,
        title: 'Project Templates',
        child: [
            {
                id: '5-1',
                title: 'Delta',
                child: [
                    {
                        id: '5-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '5-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '5-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '5-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '5-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '5-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '5-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '5-2',
                title: 'Brizo',
                child: [
                    {
                        id: '5-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '5-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '5-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '5-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '5-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '5-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '5-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '5-3',
                title: 'Peerless',
                child: [
                    {
                        id: '5-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '5-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '5-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '5-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '5-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '5-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '5-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 6,
        title: 'Accessible & Code Compliant',
        child: [
            {
                id: '6-1',
                title: 'Delta',
                child: [
                    {
                        id: '6-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '6-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '6-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '6-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '6-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '6-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '6-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '6-2',
                title: 'Brizo',
                child: [
                    {
                        id: '6-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '6-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '6-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '6-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '6-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '6-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '6-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '6-3',
                title: 'Peerless',
                child: [
                    {
                        id: '6-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '6-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '6-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '6-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '6-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '6-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '6-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 7,
        title: 'Schedule Type',
        child: [
            {
                id: '7-1',
                title: 'Delta',
                child: [
                    {
                        id: '7-1-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '7-1-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '7-1-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '7-1-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '7-1-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '7-1-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '7-1-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    }
                ]
            },
            {
                id: '7-2',
                title: 'Brizo',
                child: [
                    {
                        id: '7-2-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '7-2-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '7-2-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '7-2-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '7-2-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '7-2-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '7-2-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
            {
                id: '7-3',
                title: 'Peerless',
                child: [
                    {
                        id: '7-3-1',
                        title: 'Kitchen',
                        child: [
                            {
                                id: '7-3-1-1',
                                title: 'Sink faucets',
                            },
                            {
                                id: '7-3-1-2',
                                title: 'Single handle',
                            },
                            {
                                id: '7-3-1-3',
                                title: 'Two handle',
                            },
                            {
                                id: '7-3-1-4',
                                title: 'Pull-down/pull-out',
                            },
                            {
                                id: '7-3-1-5',
                                title: 'High arc',
                            },
                            {
                                id: '7-3-1-6',
                                title: 'Pot filler',
                            },
                        ]
                    },
                ]
            },
        ]
    },
];

export const productData = [
    {
        id: 1,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: false,
    },
    {
        id: 2,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: true,
    },
    {
        id: 3,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: false,
    },
    {
        id: 4,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: false,
    },
    {
        id: 5,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: false,
    },
    {
        id: 6,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: true,
    },
    {
        id: 7,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: true,
    },
    {
        id: 8,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: true,
    },
    {
        id: 9,
        image: Images.image.productImg,
        title: 'Bosco 6030 IFS Acrylic Alcove Sink Faucets',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        price: 'MSRP CADS 1,502.00',
        type: 'Sink Faucets',
        category: 'Sink Faucets',
        select: true,
    },
]

export const Links = [
    {
        id: 1,
        text: 'Revit Series Spec Sheet'
    },
    {
        id: 2,
        text: 'Model Spec Sheet'
    },
    {
        id: 3,
        text: 'Install & Maintenance'
    },
];

export const    productInfo = [
    {
        id: 1,
        title: 'Activation Type',
        value: 'Electronic'
    },
    {
        id: 2,
        title: 'Complies with',
        value: 'Watersense,ASME A112.18.1/CSA B125.1'
    },
    {
        id: 3,
        title: 'Country',
        value: 'Canada,US'
    },
    {
        id: 4,
        title: 'Function Type',
        value: 'Tub/Shower Units'
    },
    {
        id: 5,
        title: 'Group',
        value: 'Showering'
    },
    {
        id: 6,
        title: 'Model Group(Series)',
        value: '860T E-Push Button Shower'
    },
    {
        id: 7,
        title: 'Operation',
        value: 'Electronic'
    },
    {
        id: 8,
        title: 'Product Group',
        value: 'Electronic Teck® Institutional'
    },
    {
        id: 9,
        title: 'Product Line',
        value: '860T1 - Push Button Activated, Electronically Metered Shower System'
    },
];

export const productResource = [
    {
        id: 1,
        title: 'Maintenance & Installation Sheet: 860T series - English'
    },
    {
        id: 2,
        title: 'Maintenance & Installation Sheet: R2570-MIXLF'
    },
    {
        id: 3,
        title: 'Repair Parts Diagram: 860T series - English'
    },
    {
        id: 4,
        title: 'Repair Parts Diagram: 860T series - French'
    },
    {
        id: 5,
        title: 'Repair Parts Diagram: 860T series - French'
    },
    {
        id: 6,
        title: 'Maintenance & Installation Sheet: R2570-MIXLF'
    },
];