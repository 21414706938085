import React, { useState } from "react"

const initialStates={
     isSearched:false    
}

const SearchContext = React.createContext({ initialStates })

export const useSearchContext = () => React.useContext(SearchContext)

export const SearchProvider = ({ children }) => {

     const [seachField, setSeachField] = React.useState("")
     const [isFirstStepDone, setIsFirstStepDone] = React.useState(false)
     const [call, setCall] = React.useState(false)
     const [onUpdateAdditionInfo , setOnUpdateAdditionInfo] = React.useState()
     const [projectProgess , setProjectProgress] = React.useState(0)
     const [additionalProgress , setAdditionProgress] = React.useState(0)
     const [contactProgress, setContactProgress] = React.useState(0)
     const [userData ,setUserData] =React.useState()

     

     const [list,setList]= useState([])
     const [showImageKeySubmittal,setShowImageKeySubmittal]= useState(true)
     const [showImageKeyBrochure,setShowImageKeyBrochure]= useState(true)
     const [brochurelist,setBrochureList]= useState([])
     const [brochureOrderList,setBrochureOrderList]= useState([])
     
     const [submittalEmail,setSubmittalEmail]= useState("")
     const [submittalImage,setSubmittalImage]= useState(null)
     const [brochureImage,setBrochureImage]= useState(null)
     const [brochureEmail,setBrochureEmail]= useState("")
     const [CurrentprojectData ,setCurrentprojectData] =React.useState()

     const setSubmittalMail = (email, file)=>{
          setSubmittalEmail(email)
          setSubmittalImage(file)
     }
     const setBrochureMail = (email, file)=>{
          setBrochureEmail(email)
          setBrochureImage(file)
     }
     const setDocList = (list , key)=>{
          setList(list)
          setShowImageKeySubmittal(key)
     }
     const mapBrochureList = (list,key)=>{
          setBrochureList(list)
          setShowImageKeyBrochure(key)
     }
     const mapBrochureOrderList = (list)=>{
          setBrochureOrderList(list)
     }

     const setProjectName = (value)=>{
          setCurrentprojectData(value)
     }

     const setSearch = (value)=>{
          setSeachField(value)
     }

     const onUpdateProjectStep = (value) =>{
          setIsFirstStepDone(value)
     }

     const OnAdditionInformationStep =(value)=>{
          setOnUpdateAdditionInfo(value)
     }
       
     const callUserApi =(value)=>{
          setCall(value)
     }

     const projectDetailBar =(value)=>{
          setProjectProgress(value)
     }

     const AdditionalDetailBar =(value) =>{
          setAdditionProgress(value)
     }

     const ContactDetailBar =(value) =>{
          setContactProgress(value)
     }

     const mapUserData =(value)=>{
          setUserData(value)
     }

     const data ={
          seachField,
          setProjectName,
          setSearch,
          onUpdateProjectStep,
          isFirstStepDone,
          useSearchContext,
          callUserApi,
          call,
          CurrentprojectData,
          projectDetailBar,
          projectProgess,
          AdditionalDetailBar,
          additionalProgress,
          ContactDetailBar,
          contactProgress,
          mapUserData,
          userData,
          OnAdditionInformationStep,
          onUpdateAdditionInfo,
          brochurelist,
          mapBrochureList,
          list,
          setDocList,
          brochureOrderList,
          mapBrochureOrderList,
          submittalEmail,
          setSubmittalMail,
          brochureEmail,
          setBrochureMail,
          showImageKeyBrochure,
          showImageKeySubmittal,
          submittalImage,
          brochureImage

     }
   
     return <SearchContext.Provider value={data}>{children}</SearchContext.Provider>
   
}
   
   
