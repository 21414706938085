import React, { useMemo } from "react";
import 'stylesheets/comman/inputBox.scss';
import { Controller, useForm, useFormState } from 'react-hook-form';


const TextareaBoxControl = (props) => {

     const { control, name = "", required } = props;
     const state = useFormState({ control, name });
     const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state]);
     const errorMessage = useMemo(() => state.errors[name]?.message || undefined, [state]);

     return (
         <>
         <Controller
          control={control}
          name={name}
          render={({field})=>(
               <div className="form-group">
               {props.label && <label style={{ fontSize: '14px' }} className="title">{props.label}</label>}
               <div className="position-relative">
                    <textarea className="form-control" onChange={props.onChange} 
                    value={props.value} placeholder={props.placeholder} {...field}
                     rows={2} cols={2}>
                          
                     </textarea>
                     {isErrorExist && <span className='error'>{errorMessage}</span>}
               </div>
          </div>
          )}
          
          />
         
         </>
     );
};
export default TextareaBoxControl;
