import React, { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import SelectBox from "./comman/SelectBox";
import "../stylesheets/headSubmittal.scss";
import Button from "./comman/button";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSearchContext } from "context/searchContext/searchContext";
import { useAuthContext } from "context/auth/AuthContext";
import { async } from "q";
import { API } from "API/API";
import { ClipLoader } from "react-spinners";
import MainProgressBar from "components/progressBar";

const SelectListt = [
  {
    id: 1,
    value: "Alphabetical (A to Z)",
  },
  {
    id: 2,
    value: "Alphabetical (Z to A)",
  },
];

const SubmittalHead = (props) => {
  const navigate = useNavigate();
  const { handleSubmit, control, setValue, getValue } = useForm({});
  const [PDFLoader, setPDFLoader] = useState(false);
  const { token } = useAuthContext();
  const [percent, setPercent] = useState(5);

  const param = useParams();

  const regex =
    "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)";

  const [project, setProjectName] = useState(localStorage.getItem("pname"));

  const {
    isFirstStepDone,
    CurrentprojectData,
    setSubmittalMail,
    list,
    submittalEmail,
    showImageKeySubmittal,
    submittalImage,
  } = useSearchContext();

  const checkIfDone = () => {
    // if (isFirstStepDone) {
    props.onClick();
    // }
    // else {
    //     toast.error("Please fill project details first.")
    //     // alert("asdasd")
    // }
  };  

  useEffect(() => {
    if (PDFLoader) {
      const intervalid = setInterval(() => {
        if (percent < 91) {
          // Generate a random number between 1 and 5
          const randomNumber = Math.floor(Math.random() * 5) + 1;
          
          // Add the random number to the current percentage
          const newPercent = percent + randomNumber;
          
          // Ensure the new percentage does not exceed 95
          setPercent(newPercent > 91 ? 91 : newPercent);
        }
      }, 2000); 
      return () => clearInterval(intervalid);
    } 
  }, [percent, PDFLoader]);

  const finalSubmittal = () => {
    if (!submittalEmail.match(regex)) {
      toast.error("Please provide valid email address.");
    } else {
      generatePdf();

      // setTimeout(() => {
      //   getProgressValue();
      // }, 1000);
    }
  };

  // const getProgressValue = async () => {
  //   setPercent((prev) => prev + 5);
  //   try {
  //     let response = await API.getProgressValue(token, param.id);

  //     console.log({ percent });
  //     if (response?.data < 100) {
  //       setTimeout(() => {
  //         getProgressValue();
  //       }, 200);
  //     }else{
  //       setPercent(100)
  //     }

  //     console.log("gone", response);
  //   } catch (error) {
  //     toast.error("Network error");
  //   }
  // };

  const generatePdf = async () => {
    setPDFLoader(true);
    let params = {
      sentToEmail: true,
      download: false,
      email: submittalEmail,
      submittal: true,
      brochure: false,
      image: submittalImage,
    };
    console.log("payload of pdf", params);
    try {
      let response = await API.generatePdfSubmittal(param.id, token, params);

      if (!response.success) {
        toast.error(response.message); 
       
      }
    } catch (error) {
      return error;
    } finally {
      // setPDFLoader(false);
      toast.success("Email sent successfully.");
      setPercent(100);
      setSubmittalMail("","")
      setTimeout(() => {
        navigate("/final-submittal");
      }, 1000);
      
    }

    // else {

    //     toast.error("Please provide email address.")
    // }
  };

  const addDocument = async () => {
    let obj = {
      filesArr: list,
      showImages: showImageKeySubmittal,
    };
    try {
      let response = await API.AddFilesSubmittal(token, obj, param.id);
      if (response.success) {
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      return error;
    }
  };

  const hadndleSelectDoc = () => {
    addDocument();
    props.onFirstClick();
  };

  return (
    <section>
      <div className="container">
        <div className="editProjecthead">
          <div className="inputBoxhead">
            <h1>Create a Submittal</h1>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b>{project?.charAt(0).toUpperCase() + project?.slice(1)}</b>

              {props.step !== 0 && (
                <span onClick={props.backStep} className="pp">
                  Back
                </span>
              )}
            </div>
          </div>
          <div className="stepHead">
            <div className="stepLine"></div>
            {props.step === 0 && (
              <div className="stepCircle">
                <div className="stepCircleInner">
                  <div className="CircleInner"> </div>
                </div>
              </div>
            )}
            {props.step === 0 && <div className="stepCircle"></div>}
            {props.step === 0 && <div className="stepCircle"></div>}
            {props.step === 0 && <div className="stepCircle"></div>}

            {props.step === 1 && (
              <div className="stepCirclegrey">
                <div className="fackeckbox">
                  <FaCheck color="white" />
                </div>
              </div>
            )}
            {props.step === 1 && (
              <div className="stepCircle">
                <div className="stepCircleInner">
                  <div className="CircleInner"> </div>
                </div>
              </div>
            )}
            {props.step === 1 && <div className="stepCircle"></div>}
            {props.step === 1 && <div className="stepCircle"></div>}

            {props.step === 2 && (
              <div className="stepCirclegrey">
                <div className="fackeckbox">
                  <FaCheck color="white" />
                </div>
              </div>
            )}
            {props.step === 2 && (
              <div className="stepCirclegrey">
                <div className="fackeckbox">
                  <FaCheck color="white" />
                </div>
              </div>
            )}
            {props.step === 2 && (
              <div className="stepCircle">
                <div className="stepCircleInner">
                  <div className="CircleInner"> </div>
                </div>
              </div>
            )}
            {props.step === 2 && <div className="stepCircle"></div>}

            {props.step === 3 && (
              <div className="stepCirclegrey">
                <div className="fackeckbox">
                  <FaCheck color="white" />
                </div>
              </div>
            )}
            {props.step === 3 && (
              <div className="stepCirclegrey">
                <div className="fackeckbox">
                  <FaCheck color="white" />
                </div>
              </div>
            )}
            {props.step === 3 && (
              <div className="stepCirclegrey">
                <div className="fackeckbox">
                  <FaCheck color="white" />
                </div>
              </div>
            )}
            {props.step === 3 && (
              <div className="stepCircle">
                <div className="stepCircleInner">
                  <div className="CircleInner"> </div>
                </div>
              </div>
            )}
          </div>
          {props.step === 0 && (
            <div style={{ marginLeft: "115px" }} className="sortHead">
              <p></p>
              <div className={`form-group`}>
                {/* <div className="position-relative ">
                                <select className="form-control custom-select"  >
                                    <option disabled selected value={'asc'}>{"Relevance"}</option>
                                    <option value={'asc'}>{"Alphabetical (A to Z)"}</option>
                                    <option value={'dsc'}>{"Alphabetical (Z to A)"}</option>
                                </select>
                            </div> */}
              </div>
              {/* <SelectBox grouClass="Selectcountry" selectList={SelectListt} selected={"Select Relevance"} control={control} /> */}
            </div>
          )}
          {props.step === 1 && (
            <div className="inputBoxheadbutton">
              <Button
                type="primary"
                value="Next - Create Submittal"
                click={() => hadndleSelectDoc()}
              />
            </div>
          )}
          {props.step === 2 && (
            <div className="inputBoxheadbutton">
              <Button
                type="primary"
                value="Next - Submittal Review"
                click={() => checkIfDone()}
              />
            </div>
          )}
          {props.step === 3 && (
            <>
              {PDFLoader ? (
                <div
                style={{
                  display: "flex",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  height: "100%",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#00000090",
                  zIndex: 99,
                  padding: "18px",
                }}
              >
                <div style={{
                  maxWidth: "800px",
                  margin: "0 auto",
                  width: "100%",
                }}>
                  <MainProgressBar
                    isLabelVisible={true}
                    value={percent}
                    height={"40px"}
                  />
                </div>
              </div>
              ) : (
                <Button
                  type="primary"
                  value="Generate Submittal"
                  click={() => finalSubmittal()}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default SubmittalHead;
