import React, { useState } from "react";
import Button from "components/comman/button";
import { useNavigate } from "react-router-dom";
import CreateSubmittal from "./createSubmittal";
import CreateSubmittalLanding from "./createSubmittalLanding";
import GenerateSubmittal from "./generateSubmittal";
import SubmittalHead from "components/headSubmittal";
import SelectDocument from "./selectDocument";
import { useSearchContext } from "context/searchContext/searchContext";
import { toast } from "react-toastify";

const MainSubmittal = (props)=>{
    const [step,setStep] = useState(0)
    const [list,setList] = useState([])

    const steps = () =>{
        setStep(1)
    }
    const stepss = () =>{
        setStep(2)
    }

    const stepsss = () =>{
        setStep(0)
    }

    const handleClick2 =()=>{
        setStep(0)
    }

    const backStep = () =>{
        setStep(step-1)
    }

    
    
    return(
        <section>
            <div style={{paddingTop:'30px'}} className="container">
                <SubmittalHead backStep={()=>backStep()} step={step} 
                onFirstClick={()=>setStep(2)}  onClick={()=>setStep(3)}/>

                {step === 0 && <CreateSubmittalLanding  changeStep={steps}/>}

                {step === 1 && <SelectDocument onClick2={()=>setStep(0)}
                  onClick={()=>setStep(2)} changeStep={steps}/>}

                {step === 2 && <CreateSubmittal  />}

                {step === 3 && <GenerateSubmittal onClick={()=>setStep(2)} onClick2={handleClick2} />}
            </div>
        </section>
    )
}
export default MainSubmittal