import { API } from 'API/API';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'stylesheets/project.scss';
import moment from 'moment';
import { useAuthContext } from 'context/auth/AuthContext';



const tabList = [
    {
        id: 1,
        label: 'Project Details'
    },
    {
        id: 2,
        label: 'Additional Information'
    },
    {
        id: 3,
        label: 'Contact Information'
    },
]

const ProjectDetailTab = (props) => {
    const { token } = useAuthContext()

    const [currentTab, setCurrentTab] = useState(1);

    const onTab = (id) => {
        setCurrentTab(prevID => {
            if ((prevID) === id) return id;
            return id;
        })
    }
    useEffect(() => {
        if(token)
        getSubmittalDetails()
    }, [token]);

    const [FormDataS, setFormDataS] = useState({})

    const params = useParams()
    const getSubmittalDetails = async () => {
        try {
            let response = await API.getSubmittalDetails(params.id, token)
            setFormDataS(response.data)
        }
        catch (error) {
            return error
        }
    }


    return (
        <div className='projectDetail__tabs'>
            <div className='tab'>
                <ul className="tab-ulp" style={{ textAlign: 'left' }}>
                    {tabList.map((item) => (
                        <li key={item.id} className={item.id === currentTab ? 'active' : ''} onClick={() => onTab(item.id)}>{item.label}</li>
                    ))}
                </ul>
            </div>
            <div className='tab__content'>
                {currentTab === 1 &&
                    <div className='detail'>
                        <div>
                            <b>Address Line 1</b>
                            <p>{FormDataS?.address?.line1 ? FormDataS?.address?.line1 : 'N/A'}</p>
                        </div>
                        <div>
                            <b>Address Line 2</b>
                            <p>{FormDataS?.address?.line2 ? FormDataS?.address?.line2 : 'N/A'}</p>
                        </div>
                        <div>
                            <b>Project number</b>
                            <p>{FormDataS?.projectNumber ? FormDataS?.projectNumber : 'N/A'}</p>
                        </div>
                        <div className='detail__state'>
                            <div className='city'>
                                <b>City</b>
                                <p>{FormDataS?.address?.city ? FormDataS?.address?.city : 'N/A'}</p>
                            </div>
                            <div>
                                <b>State</b>
                                <p>{FormDataS?.address?.state ? FormDataS?.address?.state : 'N/A'}</p>
                            </div>
                        </div>
                        <div className='detail__state'>
                            <div className='city'>
                                <b>Zip Code/Area Code</b>
                                <p>{FormDataS?.address?.zip ? FormDataS?.address?.zip : 'N/A'}</p>
                            </div>
                            <div>
                                <b>Country</b>
                                <p>{FormDataS?.address?.country ? FormDataS?.address?.country : 'N/A'}</p>
                            </div>
                        </div>
                        <div>
                            <b>Estimated Ship Date</b>
                            {FormDataS?.estimateShipDate ?
                                <p>{FormDataS?.estimateShipDate?moment(FormDataS?.estimateShipDate).format('DD-MM-YYYY'):'N/A'}</p> : <p>N/A</p>}

                        </div>
                        <div>
                            <b>Project Notes</b>
                            <p>{FormDataS?.notes ? FormDataS?.notes : 'N/A'} </p>
                        </div>

                    </div>
                }
                {currentTab === 2 &&
                    <div className='detail'>
                        <div>
                            <b>Project Budget</b>
                            <p>{FormDataS?.budget ? FormDataS?.budget : 'N/A'}</p>
                        </div>
                        <div>
                            <b>Project Size</b>
                            <p>{FormDataS?.projectSize ? FormDataS?.projectSize : 'N/A'}</p>
                        </div>
                        <div>
                            <b>Project Type</b>
                            <p>{FormDataS?.projectType ? FormDataS?.projectType : 'N/A'}</p>
                        </div>
                        <div>
                            <b>Owner Type</b>
                            <p>{FormDataS?.ownerType ? FormDataS?.ownerType : 'N/A'}</p>

                        </div>
                        <div>
                            <b>Project Phase</b>
                            <p>{FormDataS?.projectPhase ? FormDataS?.projectPhase : 'N/A'}</p>
                        </div>
                        <div>
                            <b>Owner Occupied</b>
                            {<p>{FormDataS?.ownerOccupied ? "No" : "Yes"}</p>}
                        </div>
                    </div>
                }
                {currentTab === 3 &&
                    <>
                        <div className='contact'>
                            {FormDataS?.contacts?.map((data, index) => (
                                <div>
                                    {index === 0 &&
                                        <div className='left'>
                                            <div className='headingBox'>
                                                <b className='heading'>Architect Information </b>
                                            </div>
                                            <br />
                                            <div>
                                                <b>Company Name</b>
                                                <p>{data?.companyName ? data?.companyName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Contact Name</b>
                                                <p>{data?.contactName ? data?.contactName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Email</b>

                                                {data.email ? <p>{data.email}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Phone</b>
                                                {data.phoneNumber ? <p>{data.phoneNumber}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Fax</b>
                                                {data.fax ? <p>{data.fax}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 1</b>
                                                {data.line1 ? <p>{data.line1}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 2</b>
                                                {data.line2 ? <p>{data.line2}</p> : <p>N/A</p>}
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>City</b>
                                                    {data.city ? <p>{data.city}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>State</b>
                                                    {data.state ? <p>{data.state}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>Zip Code/Area Code</b>
                                                    {data?.zip ? <p>{data?.zip}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>Country</b>
                                                    {data?.country ? <p>{data?.country}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {index === 1 &&
                                        <div>
                                            <div className='headingBox'>
                                                <b className='heading'>Contractor Information</b>
                                            </div>
                                            <br />
                                            <div>
                                                <b>Company Name</b>
                                                <p>{data?.companyName ? data?.companyName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Contact Name</b>
                                                <p>{data?.contactName ? data?.contactName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Email</b>

                                                {data.email ? <p>{data.email}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Phone</b>
                                                {data.phoneNumber ? <p>{data.phoneNumber}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Fax</b>
                                                {data.fax ? <p>{data.fax}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 1</b>
                                                {data.line1 ? <p>{data.line1}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 2</b>
                                                {data.line2 ? <p>{data.line2}</p> : <p>N/A</p>}
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>City</b>
                                                    {data.city ? <p>{data.city}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>State</b>
                                                    {data.state ? <p>{data.state}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>Zip Code/Area Code</b>
                                                    {data?.zip ? <p>{data?.zip}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>Country</b>
                                                    {data?.country ? <p>{data?.country}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>

                            ))}
                        </div>
                        <div className='contact'>
                            {FormDataS?.contacts?.map((data, index) => (
                                <div>
                                    {index === 2 &&
                                        <div className='left'>
                                            <div className='headingBox'>
                                                <b className='heading'>Distributor Information</b>
                                            </div>
                                            <br />
                                            <div>
                                                <b>Company Name</b>
                                                <p>{data?.companyName ? data?.companyName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Contact Name</b>
                                                <p>{data?.contactName ? data?.contactName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Email</b>

                                                {data.email ? <p>{data.email}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Phone</b>
                                                {data.phoneNumber ? <p>{data.phoneNumber}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Fax</b>
                                                {data.fax ? <p>{data.fax}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 1</b>
                                                {data.line1 ? <p>{data.line1}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 2</b>
                                                {data.line2 ? <p>{data.line2}</p> : <p>N/A</p>}
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>City</b>
                                                    {data.city ? <p>{data.city}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>State</b>
                                                    {data.state ? <p>{data.state}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>Zip Code/Area Code</b>
                                                    {data?.zip ? <p>{data?.zip}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>Country</b>
                                                    {data?.country ? <p>{data?.country}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {index === 3 &&
                                        <div>
                                            <div className='headingBox'>
                                                <b className='heading'>GC Information</b>
                                            </div>
                                            <br />
                                            <div>
                                                <b>Company Name</b>
                                                <p>{data?.companyName ? data?.companyName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Contact Name</b>
                                                <p>{data?.contactName ? data?.contactName : 'N/A'}</p>
                                            </div>
                                            <div>
                                                <b>Email</b>

                                                {data.email ? <p>{data.email}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Phone</b>
                                                {data.phoneNumber ? <p>{data.phoneNumber}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Fax</b>
                                                {data.fax ? <p>{data.fax}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 1</b>
                                                {data.line1 ? <p>{data.line1}</p> : <p>N/A</p>}
                                            </div>
                                            <div>
                                                <b>Address Line 2</b>
                                                {data.line2 ? <p>{data.line2}</p> : <p>N/A</p>}
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>City</b>
                                                    {data.city ? <p>{data.city}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>State</b>
                                                    {data.state ? <p>{data.state}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                            <div className='contact__state'>
                                                <div className='city'>
                                                    <b>Zip Code/Area Code</b>
                                                    {data?.zip ? <p>{data?.zip}</p> : <p>N/A</p>}
                                                </div>
                                                <div>
                                                    <b>Country</b>
                                                    {data?.country ? <p>{data?.country}</p> : <p>N/A</p>}
                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>

                            ))}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
export default ProjectDetailTab;