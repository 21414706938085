import React from "react";
import 'stylesheets/comman/button.scss';

const Button = props => {

    const {isDisabled=false}=props
    return (
        <div className={props.grouClass}>
            <button type="submit" className={`btn btn__${props.type}`} onClick={props.click} disabled={isDisabled}>
                {props.value}
            </button>
        </div>
    )
}
export default Button;