import React, { useState, useMemo } from "react";
import { FaMapMarkerAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import 'stylesheets/comman/inputBox.scss';
import { Controller, useForm, useFormState } from 'react-hook-form';

const InputBox = (props) => {


  const [showPass, setShowPass] = useState(false);
  const { control, name = "", required } = props;
  const state = useFormState({ control, name });
  const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state]);
  const errorMessage = useMemo(() => state.errors[name]?.message || undefined, [state]);

  // const exceptThisSymbols = ["."]; 

  

  return (

    <>

      {control !== undefined ? <Controller
        control={control}
        name={name}
        render={({ field }) => (

          <div className={`form-group ${props.grouClass}`}>
            {props.label && <label className={`title ${props.className}`}>{props.label}</label>}
            <div className="position-relative">
              <input type={
                props.type === "password" ? showPass ? 'text' : props.type : props.type
              }
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                // onKeyDown={(e) => {if(props.decimal) exceptThisSymbols.includes(e.key) && e.preventDefault()}} 
                className="form-control"
                step="0.01"
                required={required}
                {...field}
              />
              <> {props.date &&
                <input type={props.date}
                  placeholder={props.placeholder}
                  value={props.value}

                  className="form-control"
                  required={required}
                  {...field}
                />}
              </>

              {props.type === "password" && <button type="button" className="eyeBtn" onClick={() => setShowPass(!showPass)}>
                {showPass ? <FaRegEye /> : <FaRegEyeSlash />}
              </button>}
              {props.label === "Location" && <button type="button" className="getloc" onClick={() => setShowPass(!showPass)}>
                <FaMapMarkerAlt />
              </button>}
            </div>
            {isErrorExist && <span className='error'>{errorMessage}</span>}
          </div>
        )}
      /> :

        <div className="form-group">
          {props.label && <label className="title">{props.label}</label>}
          <div className="position-relative">
            <input type={
              props.type === "password" ? showPass ? 'text' : props.type : props.type
            }
              className="form-control"
              required={required}
            />

            {props.type === "password" && <button type="button" className="eyeBtn" onClick={() => setShowPass(!showPass)}>
              {showPass ? <FaRegEye /> : <FaRegEyeSlash />}
            </button>}
            {props.label === "Location" && <button type="button" className="getloc" onClick={() => setShowPass(!showPass)}>
              <FaMapMarkerAlt />
            </button>}
          </div>
          {/* {isErrorExist && <span className='form__group__error'>{errorMessage}</span>} */}
        </div>}
    </>

  )
};
export default InputBox;
