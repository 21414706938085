import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import React, { useEffect, useState } from "react";
import '../stylesheets/editProfile.scss'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import { useForm } from "react-hook-form";
import { API } from "API/API";
import { useAuthContext } from "context/auth/AuthContext";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { Navigate, useNavigate } from "react-router-dom";
import { useSearchContext } from "context/searchContext/searchContext";

const mess = "This field is required."
let email = "Email must be in valid format."
const schema = yup.object({
     firstname: yup.string().required(mess),
     lastname: yup.string().required(mess),
     companyName: yup.string().required(mess),
     industry: yup.string().required(mess),
     location: yup.string().required(mess),
     email: yup.string().required(mess).email(email),


})
const EditProfile = () => {
     const { mapUserData } = useSearchContext()

     const { token } = useAuthContext()
     const [data, setData] = useState()
     const { callUserApi, call } = useSearchContext()

     const [loader, setLoader] = useState()
     const { handleSubmit, control, setValue, getValues } = useForm({
          resolver: yupResolver(schema)
     });

     const onSubmit = (data) => {
          updateUserData(data)
     }
     const navigate = useNavigate()

     const updateUserData = async (data) => {
          setLoader(true)
          try {
               let response = await API.userUpdateProfile(token, data)
               if (response.success) {
                    const msg=response.message
                    getMainUser(msg)

                    // callUserApi(true)
               }
               else {
                    toast.error(response.data.message)
               }
          }
          catch (error) {
               toast.error(error.message)
          }
          finally {
               setLoader(false)
          }
     }

     const getMainUser = async (msg) => {
          try {
              let response = await API.UserProfile(token)
              if (response.data) {
                  const data = response.data
                  mapUserData(data)
                  toast.success(msg)
                  navigate('/')
              }
          }
          catch (error) {
              return error
          }
      }
  


     const getUserDetails = async () => {
          setLoader(true)
          try {
               let response = await API.getUserDetails(token)
               setData(response.data)
               let x = response.data
               setValue("firstname", x.firstname)
               setValue("lastname", x.lastname)
               setValue("companyName", x.companyName)
               setValue("industry", x.industry)
               setValue("location", x.location)
               setValue("email", x.email)
               setValue("industry", x.industry)
               setValue("industry", x.industry)
          }
          catch (error) {
               return error
          }
          finally {
               setLoader(false)
          }
     }
     const onError = (errors, e) => console.log("err", errors, e);
     useEffect(() => {
          if(token)
          getUserDetails()
     }, [token]);

     return (
          <><section className="editProfile" >
               <div className="container">
                    <div className="row">
                         <form onSubmit={handleSubmit(onSubmit, onError)} className="inner-Row">
                              <div className="heading">
                                   <h1>{"EDIT PROFILE"}</h1>
                              </div>
                              <div className="inputs">
                                   <InputBox grouClass="inputeditProfile" control={control} placeholder={"Enter first name"} label="First Name" type={'text'} name="firstname" />
                                   <InputBox grouClass="inputeditProfile" control={control} placeholder={"Enter last name"} label="Last Name" type={'text'} name="lastname" />
                                   <InputBox grouClass="inputeditProfile" control={control} placeholder={"Enter company name"} label="Company Name" type={'text'} name="companyName" />
                                   <InputBox grouClass="inputeditProfile" control={control} placeholder={"Enter industry"} label="Industry" type={'text'} name="industry" />
                                   <InputBox grouClass="inputeditProfile" control={control} placeholder={"Enter location"} label="Location" type={'text'} name="location" />
                                   <InputBox grouClass="inputeditProfile" control={control} placeholder={"Enter email address"} label="Email Address" type={'text'} name="email" />
                                   <div className="inputsbutton" > {
                                        loader ? <ClipLoader color="black" /> :
                                             <Button value="Update-Profile" type="primary" />
                                   }
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
          </section> </>
     )
}

export default EditProfile