import React from "react"

const initialStates={
     isProduct:false ,
     getProducts:()=>{ }   
}

const SearchContext = React.createContext({ initialStates })

export const useSearchContext = () => React.useContext(SearchContext)


export const ProductContext = ({ children }) => {

     const [product, setProduct] = React.useState("")


     const getProducts = (value)=>{

     }


    

     const data ={
        product,
        getProducts
      
     }
   
     return <SearchContext.Provider value={data}>{children}</SearchContext.Provider>
   
}
   
   
