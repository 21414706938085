import React from "react";
import Button from "components/comman/button";
import classes from "./createProjectTools.module.scss";
// import { API } from "API/API";

function ProjectToolAction({ handleAddFilesBrochure, handleAddFilesSubmittal, handleEditProject, handleDeleteProject, handleUpdateProductIndex }) {
    
  return (
    <div className={classes.wrapper_actn}>
      <h3 className={classes.wrapper_actn_ttl}>Project Tools</h3>
      <div className={classes.wrapper_actn_text}>
        <p>
          Create submittals, edit your project data or delete your project
          entirely.
        </p>
      </div>
      <div className={classes.wrapper_actn_wrap}>
        <Button
          value="Create A Brochure"
          type="secondary"
          grouClass={classes.wrapper_actn_wrap_btn}
          click={handleUpdateProductIndex}
          
        />
        <Button
          value="Create A Submittal"
          type="secondary"
          grouClass={classes.wrapper_actn_wrap_btn}
          click={handleAddFilesSubmittal}
          
        />
        <Button
          value="Edit Project"
          type="primary"
          grouClass={classes.wrapper_actn_wrap_btn}
          click={handleEditProject}
        />
        <Button
          value="Delete Project"
          type="primary"
          grouClass={classes.wrapper_actn_wrap_btn}
          click={handleDeleteProject}
        />
      </div>
    </div>
  );
}

export default ProjectToolAction;
