import React from "react";
import Button from "./comman/button";
import InputBox from "./comman/InputBox";
import SelectBox from "./comman/SelectBox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import ClipLoader from 'react-spinners/ClipLoader';
import { API } from "API/API";
import { toast } from "react-toastify";
import '../stylesheets/home.scss'
import { useNavigate } from "react-router-dom";

const selectOptions = [
    {
        id: 1,
        value: 'Lorem ipsum1'
    },
    {
        id: 2,
        value: 'Lorem ipsum22'
    },
    {
        id: 3,
        value: 'Lorem ipsum3'
    },
]

const mess = "This field is required."
let email = "Email must be in valid format."
const schema = yup.object({
    firstname: yup.string().required(mess).matches(/^(\S+$)/g, '* This field cannot contain only blankspaces'),
    lastname: yup.string().required(mess),
    companyName: yup.string().required(mess),
    industry: yup.string().required(mess),
    location: yup.string().required(mess),
    email: yup.string().required(mess).email(email),
    password: yup.string().required(mess)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"),
    confirmPassword: yup.string().required(mess)

        .oneOf([yup.ref('password'), null], 'Passwords must match.'),

})

const SignupForm = (props) => {
    const [loader, setLoader] = React.useState(false)
    const navigate = useNavigate()
    const { handleSubmit, control, setValue, getValue } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmitform = (data) => {
        data['userType'] = "user"
        // data['industry'] = "test"

        UserSignUp(data)
    }
    
    const UserSignUp = async (data) => {
        setLoader(true)

        try {
            let response = await API.userSignUp(data)
            if (response?.success) {

                toast.success(response.message) 
                props.onTab1()
                window.scrollTo(0, 0)
            }
            else {
                toast.error(response.message)
            }

        }
        catch (error) {
            toast.error(error.message)
        }
        finally {
            setLoader(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmitform)}>
            <div className="inputmarginbottom3" >
                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter first name"} label="First Name" type={'text'} name="firstname" />
                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter last name"} label="Last Name" type={'text'} name="lastname" />
                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter company name"} label="Company Name" type={'text'} name="companyName" />
                

                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter industry"} label="Industry" type={'text'} name="industry" />

                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter location"} label="Location" type={'text'} name="location" />
                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter email address"} label="Email Address" type={'text'} name="email" />
                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Enter password"} label="Password" type={'password'} name="password" />
                <InputBox grouClass="inputmarginbottom" control={control} placeholder={"Confirm password"} label="Confirm Password" type={'password'} name="confirmPassword" />
            
            <div  className="form-group text-center inputmarginbottom2" >
                {loader ? <ClipLoader /> : <Button value="Sign-Up"  type="primary"  />}
            </div>
            </div>
        </form>
    )
}
export default SignupForm;