import Button from 'components/comman/button';
import ProjectDetailTab from 'components/tabs/ProjectDetailTab';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'stylesheets/project.scss';
import { Images } from 'constants/Images';
import ProductCard from 'components/ProductCard';
import { API } from 'API/API';
import { useAuthContext } from 'context/auth/AuthContext';
import { toast } from 'react-toastify';
import ModalC from 'components/comman/ModalC';
import { ClipLoader } from 'react-spinners';
import ProjectDetailProductCard from 'components/proectDProductCard';




const ProjectDetailPage = () => {
    const { token } = useAuthContext()
    const [modal, setModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState()
    const [show, setShow] = useState()
    const [editLocationIndex, setEditLocationIndex] = useState("")
    const param = useParams()
    const [dataa, setDataa] = useState()
  const [titleArr, setTitleArr] = useState([]);
    const [rowId, setRowId] = useState(param.id)
    const [loader, setLoader] = useState()
    const [data, setData] = useState()
  const [titleLoader, setTitleLoader] = useState();
    const [loaderr, setLoaderr] = useState(false)
    const navigate = useNavigate()

    const ProjectdeleteData = async () => {
        setDeleteLoader(true)
        try {
            const response = await API.projectdelete(param.id, token);
            if (response.success) {
                toast.success(response.message)
                navigate('/projects')
            }
            else
                toast.error(response.message)
        }
        catch (e) {
            toast.error('Network Error')
        }
        finally {
            setDeleteLoader(false)
            setShow(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleDeleteProject = () => {
        setShow(true)
    }

    const getProjectData = async () => {
        setLoader(true)
        try {

            let response = await API.GetProjectsById(param.id, token)
            if (response.success) {
                const data = response.data
                setDataa(data)

                // getProductId(data.products)
            }
        }
        catch (error) {
            return error
        }
        finally {
            setLoader(false)
        }
    }

      const getListing = async () => {
        setTitleLoader(true);
        try {
          let response = await API.getTitleListing();
          if (response?.success) {
            setTitleArr(response?.data);
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          return error;
        } finally {
          setTitleLoader(false);
        }
      };

    useEffect(() => {
        if (token && token !== null) {
            getProjectData()
            getListing();
            // getProductDetail()
        }
    }, [token])


    const handleProductRemove = async (item) => {
        let id = item?.productInProjectId
        setLoaderr(true)
        try {
            let params = { productId: [id] }
            let response = await API.productsdelete(param.id, token, id)
            if (response.success) {

                getProjectData()
                toast.success(response.message)
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            toast.error(error)
        }
        finally {
            setLoaderr(false)
        }
    }

    const getProductDetail = async () => {
        try {
            let response = await API.getProductDetails(token)
            setData(response.data)
        }
        catch (error) {
            return error
        }
    }

    const setdATABrochure = () => {
        localStorage.removeItem("bname")
        localStorage.setItem("bname", dataa.name)
        navigate(`/create-project-tools/${param.id}`)
    }

    const setdATASubmittal = () => {
        localStorage.removeItem("pname")
        localStorage.setItem("pname", dataa.name)
        navigate(`/create-project-tools/${param.id}`)
    }

    const editProductLocation = (value , index) =>{
        console.log("obj",dataa)

        let res = dataa?.products?.map((me , i)=>{
            if(index === i){
                return {...me , location:{...me?.location , name : value}}
            }
            else{
                return{...me}
            }
        })

        // setDataa([...res])
        setDataa({...dataa , products:res})
    }

    console.log("obj", dataa)

    const onPriceChange = (value , item , index , pIndex) =>{
        console.log("obj" , value , item , index , pIndex)

        let res = dataa?.products?.map((me , i)=>{
            if(i===index){
                let arrr= []
                me?.product?.map((mee , idxx)=>{
                    if(pIndex === idxx){
                        let obj={
                            ...mee , 
                            price:value
                        }
                        arrr?.push(obj)
                    }
                    else{
                        let obj={
                            ...mee
                        }
                        arrr?.push(obj)
                    }
                })
                return{...me , product:arrr}
            }
            else{
                return {...me}
            }
           
        })

        console.log("obj",res)

        setDataa({...dataa , products:res})

    }

    return (
        <>
            {loader ? <div style={{ display: 'flex', justifyContent: 'center', minHeight: '80vh', alignItems: 'center' }}><ClipLoader /> </div> : <section className='projectDetail'>
                <div className='container'>
                    <div className='projectDetail__title'>
                        {/* <h1>{item?.name}</h1> */}
                    </div>
                    <div className='projectDetail__cta'>



                        <h2>{dataa?.name?.charAt(0).toUpperCase() + dataa?.name?.slice(1)}</h2>
                        <p onClick={() => navigate('/projects')}>Back to Projects</p>
                    </div>
                    <div className='tabNdCard'>
                        <div className='tab'>
                            <ProjectDetailTab />
                        </div>
                        <div className='Card'>
                            <div className='Card-inner'>
                                <div>
                                    <h1>Project Tools</h1>
                                    <p>Create submittals, edit your project data or delete your project entirely.</p>
                                </div>
                                <div className='btn-wrap'>
                                    <div className='btnCard'>
                                        <Button value="Create A Brochure" type="primaryyBtnCardW"
                                            click={() => setdATABrochure()} />
                                    </div>
                                    <div className='btnCard'>
                                        <Button value="Create A Submittal" type="primaryyBtnCardW"
                                            click={() => setdATASubmittal()} />
                                    </div>
                                    <div className='btnCard'>
                                        <Button value="Edit Project" type="primaryyBtnCard"
                                            click={() => navigate(`/edit-project/${param.id}`)} />
                                    </div>
                                    <div className='btnCard'>
                                        <Button value="Delete Project" type="primaryyBtnCard"
                                            click={() => handleDeleteProject()} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <h1>{"Products"}</h1>
                    {/* <p className='tagLocation'>"Tag Location"</p> */}
                    {!loader &&
                        <div className='ProductDiv'>
                            <h2>{"Location"}</h2>

                            <div className='row'>
                                {console.log("obj",dataa)}
                                {(dataa?.products?.length !== 0 ) ? dataa?.products?.map((item , i) => (
                                    <>
                                        <div className='editLocation'>
                                            {editLocationIndex === i ? <input value={item?.location?.name} 
                                            onChange={(e)=>editProductLocation(e.target.value , i)} /> : <b >{item?.location?.name}</b>}
                                            {/* <img  src={Images.icon.edit} alt='file'
                                                onClick={(e) => setEditLocationIndex(i)}
                                            /> */}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                                            {item?.location !== null &&
                                                item?.product?.map((datas , idxx) => (

                                                    <>
                                                    <div className='productCard'>
                                                    {/* <div>
                                                        <label style={{marginRight:'10px'}} >Price</label>
                                                        <input onChange={(e)=>onPriceChange(e.target.value , item , i , idxx)} 
                                                            style={{marginBottom:'10px'}} value={datas?.price}/>
                                                    </div> */}

                                                        <ProjectDetailProductCard
                                                            image={
                                                                datas?.detail?.image === "" || datas?.detail?.image === undefined
                                                                    || datas?.detail?.image === null
                                                                    ? Images?.image?.productImg :
                                                                    `${process.env.REACT_APP_IMAGE_BASEURL}${datas?.detail?.image}`
                                                            }
                                                            modelGroup={datas?.detail.modelGroup}
                                                            manuName={datas?.detail?.manufacturer}
                                                            color={datas?.detail?.images}
                                                            selectedColorId={datas?.colorId}
                                                            selectedColor={datas?.color}
                                                            titleArr={titleArr}
                                                            title={datas?.detail?.name ? datas?.detail?.name : ""}
                                                            text={datas?.detail?.description ? datas?.detail?.description : ""}
                                                            price={datas?.detail?.createdAt ? datas?.detail?.createdAt : ""}
                                                            
                                                            status={datas?.detail?.status ? datas?.detail?.status : ""}

                                                            
                                                           
                                                            // onSelect={() => onSelect(index)}
                                                            // goLink={() => onLink(item)}
                                                            removeProduct={() => handleProductRemove(datas)}
                                                        />
                                                    </div>
                                                    </>
                                                ))
                                            }
                                        </div>


                                    </>
                                )) :
                                    <div style={{ marginTop: "15px", display: 'flex', justifyContent: 'center' }} >
                                        <p style={{ fontWeight: 'bold', fontSize: '18px' }}>No Data Found</p>
                                    </div>

                                }
                            </div>
                        </div>}

                </div>
            </section>}
            {show && <ModalC message="Do you want to delete this Project?" title="Delete Records"
                showmodal={true} handleClose={handleClose}
                deleteLoader={deleteLoader} handleYes={ProjectdeleteData} />
            }


        </>
    )
}
export default ProjectDetailPage; 