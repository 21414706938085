import React, { useEffect, useState } from 'react';
import Button from 'components/comman/button';
import SelectBox from 'components/comman/SelectBox';
import { Images } from 'constants/Images';
import DataTable from 'react-data-table-component';
import 'stylesheets/project.scss';
import CreateProjectModal from 'components/modal/CreateProjectModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { GoCalendar } from 'react-icons/go';
import ModalC from 'components/comman/ModalC';
import cal from 'assets/icons/calendar.svg'
import { API } from 'API/API';
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { propTypes } from 'react-bootstrap/esm/Image';
import { useAuthContext } from 'context/auth/AuthContext';
import { useSearchContext } from 'context/searchContext/searchContext';

const shortBy = [
    {
        id: 1,
        value: 'Most Recent',
        type:"-createdAt"

    },
    {
        id: 2,
        value: 'Alphabetical (A to Z)',
        type:"name"
    },
    {
        id: 3,
        value: 'Alphabetical (Z to A)',
        type:"-name"
    },
]






const ProjectPage = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [modal, setModal] = useState(false);
    const [tData, setTData] = useState([])
    const [projectList, setProjectList] = useState([])
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false)
    const [cloneShow, setCloneShow] = useState(false)

    const [tokenMain , setTokenMain] = useState("")

    const [sortByName,setSortByName] = useState(null)
    const [rowId, setRowId] = useState(null)
    const [ClonerowId, setClonerowId] = useState(null)

    const { doLogin, assignToken, login, token } = useAuthContext()
    const { mapUserData } = useSearchContext()

    useEffect(() => {
        if(token)
        GetTableData()
    }, [token])


        console.log("jnbhbgv0" ,  token)
    
    // useEffect(()=>{
    //     console.log("jnbhbgv")
    //     assignToken(localStorage?.getItem("mytoken"))
    // },[localStorage?.getItem("mytoken")])


    useEffect(()=>{
        if(location?.search)
        // assignToken("")

        getMainUser(location?.search?.slice(1))
    },[location?.search])

    const getMainUser = async (tokenA) => {
        try {
            let response = await API.UserProfile(tokenA)
            if (response.data) {
                localStorage.removeItem('mytoken');
                localStorage.setItem('mytoken', tokenA);
                assignToken(tokenA)

                const data = response.data
                mapUserData(data)
                doLogin(true)
                // toast.success('Login successfully')

            }
            else {
                toast.error('Login successfully')

            }
        }
        catch (error) {
            return error
        }
    }

    const [deleteLoader, setDeleteLoader] = useState(false);
    const [cloneLoader, setcloneLoader] = useState(false);

    const [sortType, setSortType] = useState([])
    const [modalRowData, setModalRowData] = useState({})



    const onCreareProject = () => {
        setModalRowData({})
        setModal(!modal);

    }

    const onDetailPage = (item) => {
        navigate('/project-detail', { state: { item } });
    }

    const { handleSubmit, control, setValue, getValue } = useForm({

    });
    const {setProjectName,CurrentprojectData} = useSearchContext()
    
    const ProjectdeleteData = async () => {

        setDeleteLoader(true)
        try {
            const response = await API.projectdelete(rowId, token);

            if (response.success) {
                // fetchListingData()
                GetTableData()
                toast.success(response.message)
            }
            else
                toast.error(response.message)

        }
        catch (e) {
            toast.error('Network Error')

        }
        finally {
            setDeleteLoader(false)
            setShow(false)
        }
    }

    const cloneProject = async () => {
        setcloneLoader(true)
        try {
            let response = await API.cloneProjects(ClonerowId, token)
            if (response.success) {
                GetTableData()
                toast.success(response.message)
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            toast.error(error)
        }
        finally {
            setcloneLoader(false)
            setCloneShow(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }
    const handlecloneClose = () => {
        setCloneShow(false)
    }



    const GetTableData = async (value) => {
        setLoader(true)
        try {
            let response = await API.GetProjects(value,token)
            console.log("project id " , response);
            setProjectList([...response.data])
        }
        catch (error) {
            return error
        }
        finally {
            setLoader(false)
        }

    }

   


    const columns = [
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ fontSize: '15px', fontWeight: '600' }}>Project Name</th>
            </div>,
            selector: 'name',
            cell: row => <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '15px' }} >
                <p onClick={() => navigate(`/create-project-tools/${row?._id}`)} style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '400px', fontSize: '14    px' }} className="para">{row?.name}</p></div>,
            sortField: 'name'
        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ fontSize: '15px', fontWeight: '600' }}>Start Date</th>
            </div>,
            selector: 'createdAt',
            cell: row =>
                <ul style={{
                    display: 'flex', flexDirection: 'row', listStyle: 'none', width: '100% ',
                    padding: '0px', justifyContent: 'space-around', marginTop: '15px'
                }}>
                    <li>
                        <p className="para">{moment(row?.updatedAt).format('MM-DD-YYYY')}</p>
                    </li>
                    <li>
                        <img src={cal} alt='' />
                    </li>
                </ul>,
        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}   >
                <th style={{ display: 'flex', justifyContent: 'center', fontSize: '15px', fontWeight: '600' }}>Location</th>
            </div>,
            selector: 'zipCode',
            cell: row =>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '15px' }}> <p className="para">{row?.zipCode ? row?.zipCode : "-"}</p></div>,

        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ display: 'flex', justifyContent: 'center', fontSize: '15px', fontWeight: '600' }}>Project Type</th>
            </div>,
            selector: 'type',

            cell: row => <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '15px' }}>
                <p className="para">{row?.projectType ? row?.projectType : "N/A"}</p></div>,
        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ display: 'flex', justifyContent: 'center', fontSize: '15px', fontWeight: '600' }}>Owner</th>
            </div>,
            selector: 'ownerType',
            cell: row => <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '15px' }}>
                <p className="para">{row?.ownerType ? row?.ownerType : "N/A"}</p></div>,


        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ fontSize: '15px', fontWeight: '600' }}>Action</th>
            </div>,
            selector: 'action',
            cell: row =>
                <ul style={{
                    display: 'flex', justifyContent: 'space-around',
                    width: '100%', listStyle: 'none', padding: '0px'
                }}>
                    <li style={{ cursor: 'pointer', width: '20px' }}><img src={Images.icon.edit} alt='edit'
                        onClick={(e) => handleEdit(e, row._id, row)}
                    /></li>
                    <li style={{ cursor: 'pointer', width: '20px' }}><img src={Images.icon.files} alt='file'
                        onClick={(e) => handleCloneProject(row._id)} /></li>
                    <li style={{ cursor: 'pointer', width: '20px' }}><img src={Images.icon.delete} alt='delete'
                        onClick={(e) => handleDeleteClick(e, row._id)} /></li>
                </ul>
            ,

        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ fontSize: '15px', fontWeight: '600' }}>Submittal</th>
            </div>,
            selector: 'Download',
            cell: row => <div style={{ display: 'block', justifyContent: 'center', paddingRight: '0px' }}>
                <Button grouClass="table-btns" value='Create A Submittal'
                    click={() => setDataTools(row)} type='primaryy' />
            </div>,
        },
        {
            name: <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <th style={{ fontSize: '15px', fontWeight: '600' }}>Brochure</th>
            </div>,
            selector: 'Download',
            cell: row => <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '0px' }}>
                <Button grouClass="table-btns" value='Create A Brochure'
                    click={() => setDataTools(row)}
                     type='primaryy' />
            </div>,
        }
    ]

    const setdATA = (row) =>{
       setProjectName(row.name)
       localStorage.removeItem("pname")

       localStorage.setItem("pname",row.name)
        navigate(`/createSubmittal/${row._id}`)
    }

    const setDataTools = (row) =>{
        setProjectName(row.name)
        localStorage.removeItem("pname")
 
        localStorage.setItem("pname",row.name)
         navigate(`/create-project-tools/${row._id}`)
     }
 

    const setdATABrochure = (row) =>{
        // setBrochureName(row.name)
        localStorage.removeItem("bname")
 
        localStorage.setItem("bname",row.name)
         navigate(`/createBrochure/${row._id}`)
     }

    const handleEdit = (e, id, row) => {
        e.preventDefault()
        setModal(true)
        setModalID(id)
        setModalRowData(row)
     
    }



    const handleDeleteClick = (e, id) => {
        e.preventDefault()
        setRowId(id)
        setShow(true)
    }
    const handleCloneProject = (id) => {
        setCloneShow(true)
        setClonerowId(id)
    }
    const [modalID, setModalID] = useState('')



    const onSortClick =(e)=> {
       setSortByName(e.target.value)
       GetTableData(e.target.value) 
    }

    const onAddProject = () => {
        setModal(false);
        GetTableData()
    }

    return (
        <React.Fragment>
            <section className='projects'>
                <div className='container'>
                    <div className='projects__title'>
                        <h1>My Projects</h1>
                        <Button value='Create A Project' type='primary' click={onCreareProject} />
                    </div>
                    <div className='projects__list'>
                        <div className='tableHeader'>
                            <p>Showing All Projects</p>
                            {/* <SelectBox label='Sort By' selectList={shortBy} control={control} /> */}
                            <div className="form-group">
                                {<label className="title">{"Sort by"}</label>}
                                <div className="position-relative">
                                    <select className="form-control custom-select" onChange={(e) => onSortClick(e)}>
                                        {shortBy?.map(item => (
                                            <option key={item.id} value={item?.type}>{item?.value}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            {loader ?
                                <div style={{ display: 'flex', justifyContent: 'center', minHeight: '200px', alignItems: 'center' }} >
                                    <ClipLoader />
                                </div>
                                :
                                <DataTable
                                    pagination
                                    columns={columns}
                                    data={projectList}
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
            {show && <ModalC message="Do you want to delete this record?" title="Delete Records"
                showmodal={true} handleClose={handleClose}
                deleteLoader={deleteLoader} handleYes={ProjectdeleteData} />
            }
            {cloneShow &&
                <ModalC message="Do you want to clone this project?" title="Clone Project"
                    showmodal={true}
                    handleClose={handlecloneClose}
                    deleteLoader={cloneLoader}
                    handleYes={cloneProject}
                />
            }
            <CreateProjectModal rowID={modalRowData} clickProject={onCreareProject}
                clickTable={onAddProject} show={modal} />
        </React.Fragment>
    )
}
export default ProjectPage; 