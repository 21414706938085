import React from 'react';
import Button from './button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import '../../stylesheets/comman/modalc.scss';
const ModalC = props => {
  return (
    <React.Fragment  >
      <div>
      <Modal  isOpen={props?.showmodal} toggle={props?.handleClose}>
        <ModalHeader><h2 className='text-center'>{props.title}</h2></ModalHeader>
        <ModalBody>
          <p className='text-center'> {props.message}</p>
        </ModalBody>
        <ModalFooter>
          <div className='modalbutton'>
            <div>
              <button onClick={props?.handleClose} type="submit" className="btn btn__secondarymodal" >No</button>
            </div>
            <div>
              {!props.deleteLoader ? <button type="submit" className="btn btn__primarmodal"
                onClick={props?.handleYes} >Yes</button> : <ClipLoader color="black" size={30} />}
            </div>
          </div>
        </ModalFooter>
      </Modal>
      </div>
    </React.Fragment>
  )
}
export default ModalC;