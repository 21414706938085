import React, { useEffect, useState } from 'react';
import { Images } from 'constants/Images';
import InputBox from 'components/comman/InputBox';
import 'stylesheets/modal.scss';
import Button from 'components/comman/button';
import SelectBox from 'components/comman/SelectBox';
import TextareaBox from 'components/comman/TextareaBox';
import { useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { API } from 'API/API';
import { toast } from 'react-toastify';
import TextareaBoxControl from 'components/comman/TextBoxWithControl';
import { ClipLoader } from 'react-spinners';
const selectOptions = [
    {
        id: 1,
        value: 'Lorem ipsum1',
        name: 'Lorem ipsum1'
    },
    {
        id: 2,
        value: 'Lorem ipsum2',
        name: 'Lorem ipsum2'
    },
    {
        id: 3,
        value: 'Lorem ipsum3',
        name: 'Lorem ipsum3'
    },
]
const occupationOptions = [
    {
        id: 1,
        value: 'Architect',
        name: 'Architect'
    },
    {
        id: 2,
        value: 'Engineer',
        name: 'Engineer'
    },
    {
        id: 3,
        value: 'Doctor',
        name: 'Doctor'
    },
    {
        id: 4,
        value: 'Teacher',
        name: 'Teacher'
    },
]

const mess = "This field is required."
let email = "Email must be in valid format."
const schema = yup.object({
    firstname: yup.string().required(mess),
    lastname: yup.string().required(mess),
    email: yup.string().required(mess).email(email),
    zipcode: yup.string().required(mess),
    companyName: yup.string().required(mess),
    industry: yup.string().required(mess),
    occupation: yup.string().required(mess),
    message: yup.string().required(mess),
})




const ContactSaleModal = (props) => {
    const [loader, setLoader] = useState(true)
    const [showModal, setShowModal] = useState()
    const { handleSubmit, control, setValue, getValue } = useForm({
        resolver: yupResolver(schema)
    });

    const formSubmit = (data) => {
        
        data['message'] = "No message"
        // contactSalesRep(data)
        setShowModal(true)
    }

    const onError = (e) => {
    }
    const resetForm=()=>{

       
        setValue("firstname","" )
        setValue("lastname", "")
        setValue("email", "")
        setValue("zipcode", "")
        setValue("companyName", "")
        setValue("industry", 0)
        setValue("occupation", 0)
        setValue("message", "")
  
} 
useEffect(()=>{
resetForm()
},[!props.loader])
    
    return (
        <div className={`modal contactSale ${props.show  ? 'show' :"" }`}>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2>Contact Sales Rep</h2>
                        <button type='button' onClick={props.click} className='btn close'>
                            <img src={Images.icon.closeCircle1} alt="close" />
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(props.contact, formSubmit)} >
                        <div className='modal-body' >
                            <div className='row'>
                                <InputBox placeholder="Enter first name" control={control} label='First Name' type='text' name="firstname" />
                                <InputBox placeholder="Enter last name"control={control} label='Last Name' type='text' name="lastname" />
                                <InputBox placeholder="Enter email address" control={control} label='Email Address' type='email' name="email" />
                                <InputBox placeholder="Enter postal code" control={control} label='Postal Code' type='text' name="zipcode" />
                                <InputBox placeholder="Enter company name" control={control} label='Company' type='text' name="companyName" />
                                <SelectBox selected={"Select an Industry"} selectList={selectOptions} label={'Industry'} control={control} name="industry" />
                                <SelectBox selected={"Select an Occupation"} selectList={occupationOptions} label={'Occupation'} control={control} name="occupation" />
                                <TextareaBoxControl placeholder="Enter message" label={'Message Details'} control={control} name="message" />
                            </div>

                            <div className='submit'>
                             {props.loader?<ClipLoader color='black' />:  <Button type='primary' value={'Send Message'} />}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ContactSaleModal;