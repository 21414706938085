import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import SelectBox from "components/comman/SelectBox";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import ProjectDetailForm from "components/comman/ProjectDeatilForm";
import AdditionalInfoForm from "components/comman/additionalInformationForm";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { API } from "API/API";
import ButtonSecond from "components/comman/buttonSecond";
import { useAuthContext } from "context/auth/AuthContext";
import { countryList2 } from "constants/countryList";
import { useSearchContext } from "context/searchContext/searchContext";
import '../../stylesheets/editProjectTab.scss';

const TabData = [
    {
        id: 1,
        name: "Project Details"
    },
    {
        id: 2,
        name: "Additional Information"
    },
    {
        id: 3,
        name: "Contact Information"
    },
]

const schema = yup.object({
    // line1: yup.string().required(mess),
    // line2: yup.string().required(mess),
    // status: yup.string().required(mess),
    // city: yup.string().required(mess),
    // country: yup.string().required(mess),
    // zip: yup.string().required(mess),
    // state: yup.string().required(mess),
})

const schemaa = yup.object({
    // budget: yup.string().required(mess),
    // projectSize: yup.string().required(mess),
    // projectType: yup.string().required(mess),
    // ownerType: yup.string().required(mess),
    // projectPhase: yup.string().required(mess),
})

const schemaaa = yup.object().shape({
    // contacts: yup.array().of(
    //     yup.object().shape({
    //         companyName: yup.string().when('type', {
    //             is: 'text',
    //             then: yup.string().required(mess)
    //         }),

    //     })

    // )

})

const EditProjectTab = (props) => {
    const [currentTab, setCurrentTab] = useState(1);
    const [loader, setLoader] = useState(false)
    const [allDataBind, setAllDataBind] = useState({})
    const [isCreated, setIscreated] = useState(true)
    const params = useParams();
    const { token } = useAuthContext();

    const checkSchema = () => {
        if (currentTab === 1) {
            return schema
        }
        else if (currentTab === 2) {
            return schemaa
        }
        else {
            return schemaaa

        }

    }

    useEffect(() => {
        if (token)
            getSubmittalDetails()
    }, [isCreated, token])




    const { handleSubmit, control, setValue, getValues, reset } = useForm({
        defaultValues: {
            contacts: [
                {
                    companyName: "", contactName: "", email: "", phoneNumber: "", fax: "",
                    line1: "", line2: "", city: "", country: "", state: "", zip: "", _id: null,
                    contactType: 'architect'

                    // contactType: 'Contractor Information'
                },
                {
                    companyName: "", contactName: "", email: "", phoneNumber: "", fax: "",
                    line1: "", line2: "", city: "", country: "", state: "", zip: "", _id: null,
                    contactType: 'distributor',
                    // contactType: 'Distributor Information'
                },
                {
                    companyName: "", contactName: "", email: "", phoneNumber: "", fax: "",
                    line1: "", line2: "", city: "", country: "", state: "", zip: "", _id: null,
                    contactType: 'contractor'
                    // contactType: 'Architect Information'
                },
                {
                    companyName: "", contactName: "", email: "", phoneNumber: "", fax: "",
                    line1: "", line2: "", city: "", country: "", state: "", zip: "", _id: null,
                    contactType: 'gc'
                    // contactType: 'Gc Information'
                }

            ]
        },
        resolver: yupResolver(checkSchema())
    });
    const { fields, append } = useFieldArray({
        control,
        name: "contacts"
    });



    const [ProjectNotes, setProjectNotes] = useState('')
    const [ownerOccupied, setOwnerOccupied] = useState(null)
    const switchTab = (id) => {
        setCurrentTab(id)
    }

    const switchTabTo2 = () => {
        setCurrentTab(2)
    }
    const switchTabTo3 = () => {
        setCurrentTab(3)
    }



    const [startDate, setStartDate] = useState();

    const handleForm3 = (data3) => {
      
        UpdateProjectDetails(data3)
    }

    const { ContactDetailBar,AdditionalDetailBar } = useSearchContext()

    const UpdateProjectDetails = async (data) => {
        setLoader(true)
        try {
            let response = await API.UpdateSubmittal(data, params.id, token)
            
            if (response.success) {
                getSubmittalDetails()
                toast.success(response.message)
                if (response?.data?.contacts === []) {
                    ContactDetailBar(10)
                }
                else {
                    ContactDetailBar(100)
                }

               
            }
            else {
                toast.error(response.error_details)
            }
        }
        catch (error) {
            return error
        }
        finally {
            setLoader(false)
            // ContactDetailBar(100)
        }
    }

    const [projectdValid, setProjectdValid] = useState("")

    const chechIfDateValid = () => {
        if (startDate == "") {
            setProjectdValid("This field is required.")
        }
        else {
            setProjectdValid("")
        }
    }



    const [ProjectTextValid, setProjectTextValid] = useState("")
    const chechIfTectAreaValid = () => {
        if (ProjectNotes == "") {
            setProjectTextValid("This field is required.")
        }
        else {
            setProjectTextValid("")
        }
    }

    const onError = (errors, e) => console.log(errors, e);



    const forMap = [0, 1, 2, 3]

    const getSubmittalDetails = async () => {
        try {
            let response = await API.getSubmittalDetails(params.id, token)
            setIscreated(false)
            if (response.data.contacts.length > 0) {
                setValue("contacts", response?.data?.contacts)

                if (response?.data?.contacts === []) {
                    ContactDetailBar(10)
                }

                else {
                    ContactDetailBar(100)
                }

                if (response?.data?.projectSize !== undefined && response?.data?.budget !== undefined
                    && response?.data?.projectSize !== null && response?.data?.budget !== null) {
                    AdditionalDetailBar(100)
                }

                else {
                    AdditionalDetailBar(0)
                }
            }


        }
        catch (error) {
            return error
        }

    }

    const DiscardChange = () => {
        {
            forMap.map((data, index) => {
                setValue(`contacts.${index}.companyName`, "")
                setValue(`contacts.${index}.contactName`, "")
                setValue(`contacts.${index}.email`, "")
                setValue(`contacts.${index}.phoneNumber`, "")
                setValue(`contacts.${index}.fax`, "")
                setValue(`contacts.${index}.line1`, "")
                setValue(`contacts.${index}.line2`, "")
                setValue(`contacts.${index}.city`, "")
                setValue(`contacts.${index}.country`, 0)
                setValue(`contacts.${index}.state`, "")
                setValue(`contacts.${index}.zip`, "")
            })
        }

    }
    const checkContactName = () => {

        // if (companyName1 === "") {
        //     setCompanyName1Valid("This field is required.")
        //     return true
        // }
        // else {
        //     setCompanyName1Valid("")
        //     return false
        // }

        // if(companyName2===""){
        //     setCompanyName2Valid("This field is required.")
        // }
        // else{
        //     setCompanyName2Valid("")
        // }

        // if(companyName3===""){
        //     setCompanyName3Valid("This field is required.")
        // }
        // else{
        //     setCompanyName3Valid("")
        // }

        // if(companyName4===""){
        //     setCompanyName4Valid("This field is required.")
        // }
        // else{
        //     setCompanyName4Valid("")
        // }

    }





    return (
        <div className="tabWrapper">
            <div className="tab">
                <ul className="tab-ul">
                    {TabData.map((data) => (
                        <li key={data.id} className={data.id === currentTab ? 'active' : ''}
                            onClick={() => switchTab(data.id)}>
                            {data.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="tab__content">
                {currentTab === 1 &&
                    <ProjectDetailForm click={switchTabTo2} />
                }
                {
                    currentTab === 2 &&
                    <AdditionalInfoForm click={switchTabTo3} />
                }
                {
                    currentTab === 3 &&
                    <div>
                        <form className="form-contractor" onSubmit={handleSubmit(handleForm3, onError)} >

                            <>
                                {fields.map((item, index) => (

                                    <div className="contactInfoo" key={item.id}>
                                        <div className="contactInfoLeftt">
                                            <div className="marginOfcontact">

                                                <b>{item.contactType === 'architect' ? 'Architect Information' : " "}</b>
                                                <b>{item.contactType === 'distributor' ? 'Distributor Information' : " "}</b>
                                                <b>{item.contactType === 'contractor' ? 'Contractor Information' : " "}</b>
                                                <b>{item.contactType === 'gc' ? 'Gc Information' : " "}</b>

                                            </div>

                                            <InputBox name={`contacts.${index}.companyName`}
                                                control={control} className={'inputCustom'} label={"Company Name"} />
                                            {/* <div>{`contacts.${index}.companyName`?.message}</div> */}
                                            <InputBox name={`contacts.${index}.contactName`} control={control}
                                                className={'inputCustom'} label={"Contact Name"} />
                                            <InputBox name={`contacts.${index}.email`} control={control}
                                                className={'inputCustom'} label={"Email"} />
                                            <InputBox name={`contacts.${index}.phoneNumber`} control={control}
                                                className={'inputCustom'} label={"Phone"} />
                                            <InputBox name={`contacts.${index}.fax`} control={control}
                                                className={'inputCustom'} label={"Fax"} />
                                            <InputBox name={`contacts.${index}.line1`} control={control}
                                                className={'inputCustom'} label={"Address Line 1"} />
                                            <InputBox name={`contacts.${index}.line2`} control={control}
                                                className={'inputCustom'} label={"Address Line 2"} />
                                            <InputBox name={`contacts.${index}.city`} control={control}
                                                className={'inputCustom'} label={"City"} />
                                            <SelectBox grouClass="Selectcountry" name={`contacts.${index}.country`} selectList={countryList2} label={"Country"} control={control} />
                                            <InputBox name={`contacts.${index}.state`} control={control}
                                                className={'inputCustom'} label={"State/Province/Regions"} />
                                            <InputBox name={`contacts.${index}.zip`} control={control}
                                                className={'inputCustom'} label={"Zip Code/Area Code"} />

                                        </div>
                                    </div>

                                ))}


                            </>






                            <div className="doubleButton">
                                {loader ?
                                    <div style={{ paddingLeft: '50px' }}> <ClipLoader /></div> :
                                    <Button type='primary' value={"Save Change"} />}
                                <ButtonSecond click={() => DiscardChange()} type='secondary' value={"Discard Change"} />
                            </div>


                        </form>

                    </div>
                }
            </div>
        </div>
    )
}

export default EditProjectTab