import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/comman/button';
import 'stylesheets/home2.scss';
import { Images } from 'constants/Images';
import { UserContext } from 'components/UserContext';
import { API } from 'API/API';
import { toast } from 'react-toastify';
import ContactSaleModal from 'components/modal/ContactSaleModal';
import homePageBanner from 'assets/images/Group 295 (4).svg';
import rectIcon from 'assets/images/Rectangle 80.svg';
import crossIcon from 'assets/images/Group 297.svg'
const serviceData = [
    {
        id: 1,
        title: 'Product Catalogue',
        text: 'Find BIM, CAD and other technical product information for products found in our catalog.',
        img: Images.image.serviceImg1,
        path: '/products',
        button: 'Search for Products',
        modal: false
    },
    {
        id: 2,
        title: 'Project Builder',
        text: 'Add products to your project and receive a detailed Project Submittal.',
        img: Images.image.serviceImg2,
        path: '/projects',
        button: 'Start a project',
        modal: false
    },
    {
        id: 3,
        title: 'Contact Us',
        text: 'Find additional product information, new products, smart solutions, support and resources.',
        img: Images.image.serviceImg3,
        path: '/manufacturer-websites',
        button: 'Contact Us',
        modal: true
    },
];

const HomePage2 = () => {
    let message = useContext(UserContext)
    const [AlldATA, setAlldATA] = useState(false)

    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState()


    const navigatePath = (item) => {
        if ((localStorage.getItem('mytoken') === 'null' || localStorage.getItem('mytoken') === null) && item.id === 2) {
            toast.error("You must login first")
            navigate('/login-signup')
        }
        else {
            navigate(item.path)
        }
    }

    const contactSalesRep = async (data) => {
        setLoader(true)
        try {
            let response = await API.contactSalesRep(data)
            if (response.success) {
                toast.success(response.message)
            }
            else {
                toast.error(response.data.message)
            }
        }
        catch (error) {
            toast.error(error.message)
        }
        finally {
            setLoader(false)
            setShowModal(false)
        }
    }

    const navigate = useNavigate();
    return (
        <React.Fragment>
            {/* Banner */}
            <section className='homebanner'>
                <img src={homePageBanner} alt='' />
                <div className='overlay'>
                    <div className='container'>
                        <img className='rectIcon' src={rectIcon} alt="" />
                        <div className='homepageHeading'>
                            <h1>CREATIVE</h1>
                            <h1>PROJECTS</h1>
                            {/* <h1>CREATIVE<br />PROJECTS</h1> */}
                        </div>
                        <div className='searchButton'>
                            <button onClick={() => navigate('/products')}>SEARCH FOR PRODUCTS</button>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className='banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='banner__text'>
                            <h2>plan/space®</h2>
                            <p>The leading manufacturers at the tip of your fingers. Create and collaborate on your
                upcoming project by utilizing the most in demand products on the market. Plan/Space gives you the tools to create an all in one plumbing fixture package curated to your vision.</p>
                            <Button type="primary" value="Search for Products" click={() => navigate('/products')} />
                        </div>

                        <div className='banner__img'>
                            <div className='row'>
                                <div className='img'>
                                    <img src={Images.image.bannerImg1} alt='' />
                                </div>
                                <div className='img'>
                                    <img src={Images.image.bannerImg2} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='services'>
                <div className='container'>
                    <div className='row'>
                        {serviceData.map((item) => (
                            <div className='services__box' key={item.id}>
                                <div className='cover'>
                                    <img src={item.img} alt='' />
                                    <div className='text'>
                                        <span className='number'>0{item.id}</span>
                                        <h3>{item.title}</h3>
                                        <p>{item.text}</p>
                                        {item.modal ? 
                                            <Button value={item.button} type="secondary" click={() =>  setShowModal(true)} />
                                            :
                                            <Button value={item.button} type="secondary" click={() =>  navigatePath(item)} />
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            {/* <ContactSaleModal click={() => setShowModal(!showModal)} show={showModal} contact={contactSalesRep} loader={loader} /> */}
        </React.Fragment>
    )
}
export default HomePage2;