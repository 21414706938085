import React, { useEffect, useState } from 'react';
import { Images } from 'constants/Images';
import InputBox from 'components/comman/InputBox';
import 'stylesheets/modal.scss';
import Button from 'components/comman/button';
import { templateList } from 'constants/ProjectData';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { AiOutlineCheck } from 'react-icons/ai';
import { useForm } from "react-hook-form";
import { API } from 'API/API';
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from 'context/auth/AuthContext';
import SelectBox from 'components/comman/SelectBox';
import Select from 'react-select';


const createType = [
    {
        id: 0,
        label: 'Use Existing Project',
    },
    {
        id: 1,
        label: 'Start New Project From Scratch',
    }
];
var Candaregex = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
const mess = "This field is required."
const schema1 = yup.object({
    name: yup.string().required(mess),
    // zipCode: yup.string().required(mess).matches(
    //     /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
    //     "Postal code is not valid"
    // ),
    selectLocation: yup.string().required(mess),
})
const schema2 = yup.object({
    selectLocation: yup.string().required(mess),
    selectProjects: yup.string().required(mess)
})
const ProductToProjectModal = (props) => {
    const [selected, setSelected] = useState(0);

    const { handleSubmit, control, setValue, getValues, register, formState: { errors } } = useForm({
        resolver: yupResolver(selected === 0 ? schema2 : schema1)
    });
    const [selectItem, setSelectItem] = useState(null);
    const [selectTemp, setSelectTemp] = useState(null);
    const [selectedVal, setSelectedVal] = useState('None');
    const [loader, setLoader] = useState(false)
    const [mainModalData, setMainModalData] = useState({})

    const [locationS, setLocationS] = useState("nill")
    const [projectS, setProjectS] = useState("nill")
    const [selectedLocation, setSelectedLocation] = useState({
        value: null,
        label: "Select Tag Location"
    })
    const [selectedProject, setSelectedProject] = useState({
        value: null,
        label: "Select a project"
    })

    // const [projectName,setProjectName]= useState('')
    // const [location,setLocation] = useState('')
    const navigate = useNavigate()
    const { token } = useAuthContext()

    const onChoose = (id) => {
        setSelected((prevID) => {
            if (prevID !== id) return id;
            return id;
        })
    }

    const onSubmitform = (data) => {

        console.log(data, "klkl");

        if (selected === 0) {
            props.onAddProduct1(data.selectLocation, data.selectProjects)
        }

        else {
            props.onAddProduct2(data.selectLocation, data.name, data?.zipCode)
        }
    }

    const clearValue = () => {
        setValue('name', "")
        // setValue('zipCode', "")
        setValue('selectLocation', "")
        setValue('selectProjects', "")
        setSelected(0)
        setSelectedLocation({ value: null, label: "Select Tag Location" })
        setSelectedProject({ value: null, label: "Select a project" })
    }

    const onError = (errors) => console.log(errors);

    useEffect(() => {
        console.log('cleared');
        clearValue()
    }, [props.show]);

    // const getModalDetails = () => {
    //     if (props?.rowID?._id) {
    //         setValue('name', props?.rowID?.name)
    //         setValue('zipCode', props?.rowID?.zipCode)
    //     }
    //     else {
    //         setValue('name', '')
    //         setValue('zipCode', '')
    //     }
    // }

    const UpdateEditProject = async (data, id) => {
        setLoader(true)
        try {
            let response = await API.UpdateSubmittal(data, id, token)
            if (response.success) {
                toast.success(response.message)
                props.clickTable()
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            return error
        }
        finally {
            setLoader(false)
        }
    }

    // Handle value change in React Select
    const handleSelectLocationChange = (selectedOption) => {
        setValue('selectLocation', selectedOption.value);
        setSelectedLocation({ value: selectedOption.value, label: selectedOption.label })
    };

    const handleSelectProjectsChange = (selectedOption) => {
        setValue('selectProjects', selectedOption.value);
        setSelectedProject({ value: selectedOption.value, label: selectedOption.label })
    };




    return (
        <div className={`modal createProject ${props?.show ? 'show' : ''}`}>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2>Add Products to Project</h2>
                        <button type='button' onClick={props.clickProject} className='btn close'>
                            <img src={Images?.icon?.closeCircle1} alt="close" />
                        </button>
                    </div>
                    <div className='modal-body'>
                        <ul>
                            {createType?.map((item) => (
                                <li key={item?.id}>
                                    <label>
                                        <input type={'radio'} name="createProject" checked={item?.id === selected}
                                            className={item?.id === selected ? 'active' : ''} onClick={() => onChoose(item?.id)} />
                                        <span>{item?.label}</span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div style={{ paddingTop: '10px' }}>
                            <form onSubmit={handleSubmit(onSubmitform, onError)} className='row'>
                                <div className={`form-group `}>
                                    <label className="title">{"Tag location"}</label>
                                    <div className="position-relative">
                                        <Select
                                            name='selectLocation'
                                            {...register("selectLocation", {
                                                required: true
                                            })} 
                                            value={
                                                selectedLocation
                                            }
                                            options={props?.list?.map(item => ({
                                                value: item._id,
                                                label: item.name
                                            }))}
                                            placeholder="Select Tag Location" 
                                            onChange={handleSelectLocationChange}   
                                        // disabled={item.matched}
                                        />
                                        <div style={{ color: 'red' }} className='errormsg'>{errors?.selectLocation?.message}</div>
                                    </div>
                                </div>
                                {selected === 1 ? <> <InputBox name="name" control={control} label='Project Name' type='text' />

                                    {/* <InputBox name="zipCode" type="text" control={control} label='Location - Postal Code' /> */}
                                </> :
                                    <div className={`form-group `}>
                                        <label className="title">{"Project list"}</label>
                                        <div className="position-relative ">
                                            <Select
                                                {...register("selectProjects", {
                                                    required: true
                                                })}
                                                value={
                                                    selectedProject
                                                }
                                                options={props?.list1?.map(item => ({
                                                    value: item._id,
                                                    label: item.name
                                                }))}
                                                placeholder="Select Project"
                                                onChange={handleSelectProjectsChange}
                                                isSearchable={false}
                                            />
                                            <div style={{ color: 'red' }} className='errormsg'>{errors?.selectProjects?.message}</div>

                                        </div>
                                    </div>
                                }
                                {
                                    props.loader ?
                                        <div style={{ marginLeft: '50px', marginTop: '10px' }} >
                                            <ClipLoader />
                                        </div>
                                        :
                                        <div style={{ marginTop: '10px' }} className='submit'>
                                            {selected === 1 ? <Button type='primary'
                                                value={'Add to new Project'} /> :
                                                <Button type='primary'
                                                    value={'Add to project'} />}
                                        </div>
                                }

                            </form>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductToProjectModal;