import Button from "components/comman/button";

import React, { useEffect, useState } from "react";
import { Images } from "constants/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import '../stylesheets/createSubmittal.scss'
import SubmittalProductCard from "components/SumittalProducrCard";
import { API } from "API/API";

import { productData } from "constants/ProductData";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import "../stylesheets/submittalLanding.scss";

import { useForm } from "react-hook-form";
import ProductToProjectModal from "components/modal/ProductToProjectModal";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuthContext } from "context/auth/AuthContext";
import { useSearchContext } from "context/searchContext/searchContext";
import { toast } from "react-toastify";
import TagLoctionModal from "components/modal/tagLocationModal";
import { BiSelectMultiple } from "react-icons/bi";
import ProductCard from "components/ProductCard";
import PlusIcon from '../assets/icons/plusIcon.png'
import InputBox from "components/comman/InputBox";

const filterList = [
  {
    id: 1,
    type: "manufacturer",
    title: "Manufacturer",
    level: 3,
    showChild: false,
  },
  {
    id: 6,
    type: "buildingtype",
    title: "Building Type",
    level: 1,
    showChild: false,
  },

  {
    id: 4,
    type: "producttype",
    title: "Product Type",
    level: 1,
    showChild: false,
  },
  {
    id: 3,
    type: "productcategory",
    title: "Product Category",
    level: 1,
    showChild: false,
  },
  {
    id: 2,
    type: "projecttemplates",
    title: "Segment",
    level: 1,
    showChild: false,
  },
  {
    id: 5,
    type: "accessiblecode",
    title: "Accessible & Code",
    level: 1,
    showChild: false,
  },

  {
    id: 7,
    type: "scheduletype",
    title: "Schedule Type",
    level: 1,
    showChild: false,
  },
];

const CreateSubmittalLanding = (props) => {
    
  const { seachField } = useSearchContext();
  const [productCount, setProductCount] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const { token } = useAuthContext();
  const [currentChild, setCurrentChild] = useState(null);
  const [currentSubChild, setCurrentSubChild] = useState(null);
  const [currentSubCatChild, setCurrentSubCatChild] = useState(null)
  const [modifiedFilterList, setModifiedFilterList] = useState([...filterList]);
  const [FilterArr, setFilterArr] = useState([]);
  // const [FilterArr, setFilterArr] = useState(["dsdsd","asads"])
  const [sortSelect, setSortSelect] = useState("");
  const [modal, setModal] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [sampleProductList, setSampleProductList] = useState([]);
  const SampleArr = [1, 2, 3, 4, 5, 6, 7, 8];
  const [data, setData] = useState(productData);  
  const [mobileFilter, setMobileFilter] = useState(false);
  const [datas, setDatas] = useState([]);
  const [datass, setDatass] = useState([]);
  const [titleLoader, setTitleLoader] = useState();
  const [titleArr, setTitleArr] = useState([]);
  const location = useLocation();

  const [colorID, setColorID] = useState("");

  const [loaderr, setLoaderr] = useState(false);
  const [LoaderId, setLoaderId] = useState("");
  const [totalAddedProducts, setTotalAddedProducts] = useState(0);
  const [modalLoader, setModalLoader] = useState(false);
  const [multiple, setMultiple] = useState();
  const [item, setItem] = useState();
  const [productId, setProductId] = useState();
  const [ProjectData, setProjectData] = useState({});
  const [colorNloc, setColorNloc] = useState([]);

  const [noOfRecord, setNoOfRecord] = useState(24)
  const [maxProduct, setMaxProduct] = useState(0)
  const [page, setPage] = useState(1)
  const [loadMore, setLoadMore] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const [searchBar, setSearchBar] = useState("")
  const { setSearch } = useSearchContext();

  const [loadSearch, setLoadSearch] = useState(false);
  const [filterParams, setFilterParams] = useState({
    manufacturer: {
      manufacturerId: [],
      manufacturer2Id: [],
      manufacturer3Id: [],
      manufacturer4Id: [],
    },
    building: {
      buildingTypeId: [],
    },
    type: {
      typeId: [],
      type2Id: [],
    },
    category: {
      categoryId: [],
      category2Id: [],
      category3Id: [],
      category4Id: [],
    },
    accessible: {
      accessibleComplaintId: [],
    },
    schedule: {
      scheduleTypeId: [],
    },
    curated: {
      curatedSelectionId: [],
      curatedSelection2Id: [],
      curatedSelection3Id: [],
      curatedSelection4Id: [],
    },
  });

  const handleClose = () => {
    setModal(false);
  };
  const handleselect = (items, clrId) => {
    let result = datas.map((me) => {
      if (me._id === items._id) {
        return { ...me, isSelected: !me.isSelected, clrId: clrId };
      } else {
        return me;
      }
    });
    let count = result.reduce(
      (acc, { isSelected }) => acc + (isSelected ? 1 : 0),
      0
    );
    setDatas([...result]);
    setProductCount(count);
  };

  const getLocationList = async () => {
    try {
      let response = await API.getLocationList(token);
      setLocationList(response.data);
    } catch (error) {
      return error;
    }
  };

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { handleSubmit, control, setValue, getValue } = useForm({});
  const onSelect = (index) => {
    let itemArray = [...data];
    itemArray[index].select = !itemArray[index].select;
    setData([...itemArray]);
  };

  const onLink = (item) => {
    navigate("/product-detail", { state: { item } });
  };

  useEffect(() => {
    if (token && token !== null) {
    }
  }, [token]);

  const params = useParams();

  const getProjectData = async () => {
    setLoaderr(true);
    setLoader(true)
    try {
      let response = await API.GetProjectsById(params.id, token);
      if (response.success) {
        const data = response?.data;
        setProjectData(data);
        productFliter(filterParams , sortSelect , data , noOfRecord ,page)

      }
    } catch (error) {
      return error;
    } finally {
      setLoaderr(false)
    }
  };

  const handleOnSelect = async (item, cid) => {
    console.log('item', cid);
    if (!props.isFilter) {
      setItem(item);
      setProductId(item._id);
      console.log('clco', item);
      setModal(true);
    } else {
      const obj = {
        oldProduct: {
          productId: props.replaceProduct.productId,
          locationId: props.replaceProduct.locationId,
          color: props.replaceProduct.color,
        },
        newProduct: {
          productId: item._id,
          color: cid
        }
      }
      try {
        let response = await API.replaceProductInProject(obj, params.id, token);
        if (response?.success) {
          toast.success(response?.message);
          props.handleCloseRemoveProductModal();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        return error;
      }
    }

  };

  const getProductId = (data) => {
    let filtered = data.map((me) => me.product._id);
    setProductId([...filtered]);
  };

  useEffect(() => {
    if (token && token !== null) {
      // getProjectList()
      getHierarchyListing();
      getProjectData();
      getLocationList();
      getListing();
    }
  }, [token]);

 
  let param = useParams();
  const handleProductAdd = async (location, projectId) => {
    let id = projectId ? projectId : param.id;
    setLoader(true);
    try {
      let result = datas
        .filter((me) => me.isSelected)
        .map((me) => {
          if (me.isSelected) {
            return {
              product: me._id,
              location: location,
              color: me.clrId,
            };
          }
        });

      let params = { products: result };

      let response = await API.addproducttoproject(params, token, id);
      
      if (response.success) {
        setSortSelect("asc");
        getProjectData();
        setProductCount(0);
        toast.success(response.message);
        setMultiple(false);
        clearAll();
       
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
      setModalLoader(false);
      setModal(false);
    }
  };

  const handleProductRemove = async (dlId, item) => {
    setLoaderId(item?._id);
    setLoaderr(true);

    try {
      // let params = { productId: [dlId] };
      let response = await API.productsdelete(param.id, token, dlId);
      if (response.success) {
        toast.success(response.message);
        
        getProjectData();

        productFliter(filterParams , sortSelect , ProjectData , noOfRecord , page)

      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoaderr(false);
    }
  };

 
  useEffect(() => {
    if(isDataLoaded)
    setPage(1)
    productFliter(filterParams , sortSelect , ProjectData , noOfRecord ,1)
  }, [seachField]);

  const filterData = () => {
    if (seachField === "") {
      setDatas(datass);
    } else {
      const result = datass?.filter((dataList) => {
        // return dataList?.name?.toLowerCase().match(search.toLowerCase());

        if (dataList?.name?.toLowerCase().match(seachField.toLowerCase())) {
          return dataList;
        } else if (
          dataList?.modelNumber?.toLowerCase().match(seachField.toLowerCase())
        ) {
          return dataList;
        } else if (
          dataList?.description?.toLowerCase().match(seachField.toLowerCase())
        ) {
          return dataList;
        } else if (
          dataList?.manufacturerId_data[0]?.title
            ?.toLowerCase()
            .match(seachField.toLowerCase())
        ) {
          return dataList;
        } else if (
          dataList?.images?.find((me) =>
            me?.modelName?.toLowerCase()?.includes(seachField.toLowerCase())
          )
        ) {
          return dataList;
        }
      });

      setDatas(result);
    }

    // let text = seachField.toLowerCase()
    // let result = datass.filter(name => {
    //     return name.name?.toLowerCase().includes(text)
    // });
    // setDatas([...result])
  };

  const mapListingData = (data) => {
    const keys = Object.keys(data);
    const filterList = [];
    keys.forEach((key) => {
      const found = modifiedFilterList.find((mf) => mf.id === Number(key));
      if (found) filterList.push({ ...found, parentList: data[key] });
    });
    setModifiedFilterList([...filterList]);
  };

  const getHierarchyListing = async () => {
    try {
      let response1 = await API.ManufacturerListing();
      let response2 = await API.curatedSectionListing();
      let response3 = await API.ProductCategoryListing();
      let response4 = await API.ProductTypeListing();
      let response5 = await API.complaintsListing();
      let response6 = await API.BuildingTypeListing();
      let response7 = await API.scheduleTypeListing();
      const data = {
        1: response1.data,
        5: response5.data,
        3: response3.data,
        4: response4.data,
        2: response2.data,
        6: response6.data,
        7: response7.data,
      };
      mapListingData(data);
    } catch (error) {
      toast.error("Network Error");
      console.error(error);
    }
  };

  const getListing = async () => {
    setTitleLoader(true);
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        setTitleArr(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    } finally {
      setTitleLoader(false);
    }
  };

  const checkedFilterList = (e, item, type, level) => {
    var params1 = {}
    let Ids = {}
    switch (true) {

        case type === 'manufacturer' && level === 'first':
            params1 = filterParams
            Ids = params1.manufacturer.manufacturerId;

            if (Ids.includes(item._id)) {
                params1.manufacturer.manufacturerId= Ids.filter(me => me !== item._id)

            }
            else {
                Ids.push(item._id)
            }

            setFilterParams(params1)

            break;
        case type === 'manufacturer' && level === 'second':
            params1 = filterParams

            Ids = params1.manufacturer.manufacturer2Id;
            if (Ids.includes(item._id)) {
                params1.manufacturer.manufacturer2Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;
        case type === 'manufacturer' && level === 'third':
            params1 = filterParams

            Ids = params1.manufacturer.manufacturer3Id;
            if (Ids.includes(item._id)) {
                params1.manufacturer.manufacturer3Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;

            case type === 'manufacturer' && level === 'four':
                params1 = filterParams

                Ids = params1.manufacturer.manufacturer4Id;
                if (Ids.includes(item._id)) {
                    params1.manufacturer.manufacturer4Id = Ids.filter(me => me !== item._id)
                }
                else {
                    Ids.push(item._id)
                }
                setFilterParams(params1)
                break;


        case type === 'accessiblecode' && level === 'first':
            params1 = filterParams
            Ids =  params1.accessible.accessibleComplaintId;
            if (Ids.includes(item._id)) {
                params1.accessible.accessibleComplaintId = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;


        case type === 'buildingtype' && level === 'first':
            params1 = filterParams
            Ids = params1.building.buildingTypeId
            if (Ids.includes(item._id)) {
                params1.building.buildingTypeId = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;


        case type === 'producttype' && level === 'first':
            params1 = filterParams
            Ids= params1.type.typeId
            if (Ids.includes(item._id)) {
                params1.type.typeId = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;
        case type === 'producttype' && level === 'second':
            params1 = filterParams
            Ids= params1.type.type2Id

            if (Ids.includes(item._id)) {
                params1.type.type2Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;


        case type === 'productcategory' && level === 'first':
            params1 = filterParams
            Ids = params1.category.categoryId
            if (Ids.includes(item._id)) {
                params1.category.categoryId = Ids .filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)

            }
            setFilterParams(params1)
            break;
        case type === 'productcategory' && level === 'second':
            params1 = filterParams
            Ids = params1.category.category2Id
           
            if (Ids.includes(item._id)) {
                params1.category.category2Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)

            }
            setFilterParams(params1)
            break;

            case type === 'productcategory' && level === 'third':
                params1 = filterParams
                Ids = params1.category.category3Id
               
                if (Ids.includes(item._id)) {
                    params1.category.category3Id = Ids.filter(me => me !== item._id)
                }
                else {
                    Ids.push(item._id)

                }
                setFilterParams(params1)
                break;

            case type === 'productcategory' && level === 'four':
                params1 = filterParams
                Ids = params1.category.category4Id
                
                if (Ids.includes(item._id)) {
                    params1.category.category4Id = Ids.filter(me => me !== item._id)
                }
                else {
                    Ids.push(item._id)

                }
                setFilterParams(params1)
                break;


        case type === 'scheduletype' && level === 'first':
            params1 = filterParams
            Ids = params1.schedule.scheduleTypeId;
            if (Ids.includes(item._id)) {
                params1.schedule.scheduleTypeId = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)

            }
            setFilterParams(params1)
            break;

        case type === 'accessiblecode' && level === 'first':
            params1 = filterParams

            if (params1.accessibleComplaintId.includes(item._id)) {
                params1.accessibleComplaintId = params1.accessibleComplaintId.filter(me => me !== item._id)
            }
            else {
                params1.accessibleComplaintId.push(item._id)

            }
            setFilterParams(params1)
            break;

        case type === 'projecttemplates' && level === 'first':
            params1 = filterParams
            Ids = params1.curated.curatedSelectionId;
            if (Ids.includes(item._id)) {
                params1.curated.curatedSelectionId = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)

            break;
        case type === 'projecttemplates' && level === 'second':
            params1 = filterParams
            Ids = params1.curated.curatedSelection2Id;

            if (Ids.includes(item._id)) {
                params1.curated.curatedSelection2Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;
        case type === 'projecttemplates' && level === 'third':
            params1 = filterParams
            Ids = params1.curated.curatedSelection3Id;
            
            if (Ids.includes(item._id)) {
                params1.curated.curatedSelection3Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;

        case type === 'projecttemplates' && level === 'four':
            params1 = filterParams
            Ids = params1.curated.curatedSelection4Id;
            
            if (Ids.includes(item._id)) {
                params1.curated.curatedSelection4Id = Ids.filter(me => me !== item._id)
            }
            else {
                Ids.push(item._id)
            }
            setFilterParams(params1)
            break;
    }
    let keyResult = {
        type: type,
        level: level,
        id: item._id,
        name: item.title
    }
    if (e.target.checked === true && !FilterArr.includes(item)) {
        setFilterArr([...FilterArr, keyResult])
    }
    else {
        let result = FilterArr.filter((items) => {
            return items.id !== item._id
        })
        setFilterArr(result)
    }
    productFliter(params1, "" , ProjectData , noOfRecord ,page)
  }

  const handleFilter = (e) => {
    setSortSelect(e.target.value);
    productFliter(filterParams, e.target.value, ProjectData , noOfRecord ,page);
  };

  const productFliter = async (params, sortdsc, tempData , record ,pages) => {
    if(!isDataLoaded)
    setLoader(true);

    setIsDataLoaded(true)

    
    let dataMain = {
      sort: sortdsc ,
      search: seachField,
      prm: params ||filterParams,
      noOfRecord: 24 ,
      pages: pages || page,
      key:'createdAt'
      
    };


    try {
      let response = await API.productFilter(dataMain, token, props.projectId, props.isFilter);
      setMaxProduct(response?.totalCount)

      const data = response.data;
      let child = tempData === undefined ? ProjectData.products : tempData?.products;

      let locations = {};
      let colorNlocation = [];
      let counts = 0
      child.forEach((ch) => {
        ch.product.forEach((pr) => {
          counts++;
          locations[pr?.detail?._id] = {
            uniqueId: pr.productInProjectId,
            location: ch?.location,
          };

          let paramInfo = {
            location: ch?.location?._id,
            color: pr?.colorId,
            product: pr?.detail?._id,
            pInpId: pr?.productInProjectId,
          };
          colorNlocation.push(paramInfo);
        });
      });
      setTotalAddedProducts(counts);

      let result = data.map((pr) => {
        const location = locations[pr._id];
        let modifiedColor = [];

        colorNlocation.forEach((clr) => {
          if (pr?._id === clr?.product) {
            modifiedColor.push(clr);
          }
        });

        return {
          ...pr,
          location: location?.location,
          matched: !!location,
          uniqueId: location?.uniqueId,
          isSelected: false,
          isCheckClr: modifiedColor
        };
      });

      
      if(pages === 1){
        setDatas(() => {
          setLoader(false);
          return [ ...result];
        });
      }
      
      else{
        setDatas((prev) => {
          setLoader(false);
          return [...datas, ...result];
        });
      }
      
    } catch (error) {
      return error;
    } finally {
      // setTimeout(function() {
      //   setLoader(false);
      // }, 1500);
      setLoadMore(false)
    }
  };

  // onAddProduct1  function

  const onAddProduct1 = async (location, project, item) => {
    setModalLoader(true);
    console.log('gfgf', colorID)
    if (multiple) {
      handleProductAdd(location, project, item);
    } else {
      try {
        let params = {
          products: [
            { product: productId, location: location, color: colorID },
          ],
        };
        let response = await API.addproducttoproject(params, token, project);
        // console.log("button addd" , response);
        if (response.success) {
          setSortSelect("asc");
          clearAll();
          getProjectData();
          productFliter(filterParams , sortSelect , ProjectData , noOfRecord ,page)
          setModal(false);
          toast.success(response.message);
          setProductCount(0);
          props.setAddIndicator(true);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setModalLoader(false);
      }
    }
  };

  const onAddProduct2 = async (location, name, zipCode) => {
    setModalLoader(true);
    try {
      let params = {
        name: name.toLowerCase(),
        zipCode: zipCode,
      };
      let response = await API.AddProjects(params, token);
      if (response.success) {
        let projectId = response.data._id;
        if (multiple) handleProductAdd(location, projectId);
        else onAddProduct1(location, response.data._id);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // useEffect(()=>{
  //     mapProjectDataToProduct()
  // },[])

  // const mapProjectDataToProduct = () =>{

  //     let result = productData.map((prod)=>{
  //         colorNlocation.map((col)=>{
  //             if(prod?._id === col?.product){
  //               return {...prod , checkClrLoc:col}
  //             }
  //         })
  //         // return {...prod}
  //     })


  // }

  const deleteFilterList = (data) => {
    let Ids={}
    var params1 = filterParams
    switch (true) {

        case data.type === 'manufacturer' && data.level === 'first':
            params1 = filterParams
            Ids = params1.manufacturer.manufacturerId;
            if (Ids.includes(data.id)) {
                params1.manufacturer.manufacturerId = params1.manufacturer.manufacturerId.filter(me => me !== data.id)
            }
            setFilterParams(params1)

            break;
        case data.type === 'manufacturer' && data.level === 'second':
            params1 = filterParams
            Ids =  params1.manufacturer.manufacturer2Id ; 
            if (Ids.includes(data.id)) {
                params1.manufacturer.manufacturer2Id = params1.manufacturer.manufacturer2Id .filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'manufacturer' && data.level === 'third':
            params1 = filterParams
            Ids =  params1.manufacturer.manufacturer3Id ; 

            if (Ids.includes(data.id)) {
                params1.manufacturer.manufacturer3Id = params1.manufacturer.manufacturer3Id.filter(me => me !== data.id)
            }


            setFilterParams(params1)
            break;
        case data.type === 'manufacturer' && data.level === 'four':
            params1 = filterParams
            Ids =  params1.manufacturer.manufacturer4Id ; 

            if (Ids.includes(data.id)) {
                params1.manufacturer.manufacturer4Id = params1.manufacturer.manufacturer4Id.filter(me => me !== data.id)
            }


            setFilterParams(params1)
            break;
        case data.type === 'accessiblecode' && data.level === 'first':
            params1 = filterParams
            Ids =  params1.accessible.accessibleComplaintId;
             if (Ids.includes(data.id)) {
                params1.accessible.accessibleComplaintId = params1.accessible.accessibleComplaintId.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'buildingtype' && data.level === 'first':
            params1 = filterParams
            Ids = params1.building.buildingTypeId;
            if (Ids.includes(data.id)) {
                params1.building.buildingTypeId = params1.building.buildingTypeId.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'producttype' && data.level === 'first':
            params1 = filterParams
            Ids = params1.type.typeId;
            if (Ids.includes(data.id)) {
                params1.type.typeId= params1.type.typeId.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'producttype' && data.level === 'second':
            params1 = filterParams
            Ids = params1.type.type2Id;

            if (Ids.includes(data.id)) {
                params1.type.type2Id = params1.type.type2Id.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'productcategory' && data.level === 'first':
            params1 = filterParams
            Ids= params1.category.categoryId;
            if (Ids.includes(data.id)) {
                params1.category.categoryId = params1.category.categoryId.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'productcategory' && data.level === 'second':
            params1 = filterParams
             Ids =  params1.category.category2Id; 
            if (Ids.includes(data.id)) {
                params1.category.category2Id = params1.category.category2Id.filter(me => me !== data.id)
            }

            setFilterParams(params1)

            break;
        case data.type === 'productcategory' && data.level === 'third':
            params1 = filterParams
            Ids = params1.category.category3Id;
            if (Ids.includes(data.id)) {
                params1.category.category3Id = params1.category.category3Id.filter(me => me !== data.id)
            }

            setFilterParams(params1)

            break;
        case data.type === 'productcategory' && data.level === 'four':
            params1 = filterParams
            Ids = params1.category.category4Id;
            if (Ids.includes(data.id)) {
                params1.category.category4Id = params1.category.category4Id.filter(me => me !== data.id)
            }

            setFilterParams(params1)

            break;
        case data.type === 'scheduletype' && data.level === 'first':
            params1 = filterParams
            Ids = params1.schedule.scheduleTypeId;
            if (Ids.includes(data.id)) {
                params1.schedule.scheduleTypeId = params1.schedule.scheduleTypeId.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;

        case data.type === 'accessiblecode' && data.level === 'first':
            params1 = filterParams
              Ids =  params1.accessible.accessibleComplaintId;
            if (Ids.includes(data.id)) {
                params1.accessible.accessibleComplaintId = params1.accessible.accessibleComplaintId.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;

        case data.type === 'projecttemplates' && data.level === 'first':
            params1 = filterParams
            Ids = params1.curated.curatedSelectionId; 
            if (Ids.includes(data.id)) {
                params1.curated.curatedSelectionId = params1.curated.curatedSelectionId.filter(me => me !== data.id)
            }

            setFilterParams(params1)

            break;
        case data.type === 'projecttemplates' && data.level === 'second':
            params1 = filterParams
            Ids = params1.curated.curatedSelection2Id; 
            if (Ids.includes(data.id)) {
                params1.curated.curatedSelection2Id = params1.curated.curatedSelection2Id.filter(me => me !== data.id)
            }

            setFilterParams(params1)
            break;
        case data.type === 'projecttemplates' && data.level === 'third':
            params1 = filterParams
            Ids = params1.curated.curatedSelection3Id; 

            if (Ids.includes(data.id)) {
                params1.curated.curatedSelection3Id =params1.curated.curatedSelection3Id.filter(me => me !== data.id)
            }
            setFilterParams(params1)
            break;
        case data.type === 'projecttemplates' && data.level === 'four':
            params1 = filterParams
            Ids = params1.curated.curatedSelection4Id; 

            if (Ids.includes(data.id)) {
                params1.curated.curatedSelection4Id =params1.curated.curatedSelection4Id.filter(me => me !== data.id)
            }
            setFilterParams(params1)
            break;
    }
    let result = FilterArr.filter((item) => {
        return item.id !== data.id
    })
    setFilterArr(result)
    productFliter(params1, sortSelect , ProjectData ,noOfRecord , page);
  }

  const onChildClick = (id) => {
    if (id !== currentChild) setCurrentChild(id);
    else setCurrentChild(null);
  };

  const onSubChildClick = (id) => {
    if (id !== currentSubChild) setCurrentSubChild(id);
    else setCurrentSubChild(null);
  };

  const onSubCatChildClick =(id) =>{
    if (id !== currentSubCatChild)
    setCurrentSubCatChild(id)
    else
    setCurrentSubCatChild(null)
  }

  const onCategoryClick = (id, index) => {
    let result = modifiedFilterList.map((me) => {
      if (me.id === id) {
        return {
          ...me,
          showChild: !me.showChild,
        };
      } else {
        return me;
      }
    });
    setModifiedFilterList([...result]);
  };

  const onToggleFilter = () => {
    setMobileFilter(!mobileFilter);
  };

  const clearAll = () => {
    let prm = {
      manufacturer: {
        manufacturerId: [],
        manufacturer2Id: [],
        manufacturer3Id: [],
        manufacturer4Id: [],
      },
      building: {
        buildingTypeId: [],
      },
      type: {
        typeId: [],
        type2Id: [],
      },
      category: {
        categoryId: [],
        category2Id: [],
        category3Id: [],
        category4Id: [],
      },
      accessible: {
        accessibleComplaintId: [],
      },
      schedule: {
        scheduleTypeId: [],
      },
      curated: {
        curatedSelectionId: [],
        curatedSelection2Id: [],
        curatedSelection3Id: [],
        curatedSelection4Id: [],
      },  
    }
    setFilterParams(prm);
    productFliter(prm, sortSelect , ProjectData ,noOfRecord ,page);
    setSortSelect("asc");
    setFilterArr([]);
  };

  const GetTableData = async (value) => {
    setLoader(true);
    try {
      let response = await API.GetProjects(value, token);
      setProjectList([...response.data]);
    } catch (error) {
      return error;
    } finally {
      // setLoader(false);
    }
  };

  useEffect(() => {
    if (token) GetTableData();
  }, [token]);

  const isChecked = (item, type, level) => {
    switch (true) {

        case type === 'manufacturer' && level === 'first':
            return filterParams.manufacturer.manufacturerId.includes(item._id)
        case type === 'manufacturer' && level === 'second':
            return filterParams.manufacturer.manufacturer2Id.includes(item._id)
        case type === 'manufacturer' && level === 'third':
            return filterParams.manufacturer.manufacturer3Id.includes(item._id)
        case type === 'manufacturer' && level === 'four':
        return filterParams.manufacturer.manufacturer4Id.includes(item._id)

        case type === 'buildingtype' && level === 'first':
            return filterParams.building.buildingTypeId.includes(item._id)

        case type === 'producttype' && level === 'first':
            return filterParams.type.typeId.includes(item._id)
        case type === 'producttype' && level === 'second':
            return filterParams.type.type2Id.includes(item._id)

        case type === 'productcategory' && level === 'first':
            return filterParams.category.categoryId.includes(item._id)
        case type === 'productcategory' && level === 'second':
            return filterParams.category.category2Id.includes(item._id)
        case type === 'productcategory' && level === 'third':
            return filterParams.category.category3Id.includes(item._id)
        case type === 'productcategory' && level === 'four':
        return filterParams.category.category4Id.includes(item._id)



        case type === 'accessiblecode' && level === 'first':
            return filterParams.accessible.accessibleComplaintId.includes(item._id)
        case type === 'scheduletype' && level === 'first':
            return filterParams.schedule.scheduleTypeId.includes(item._id)

        case type === 'projecttemplates' && level === 'first':
            return filterParams.curated.curatedSelectionId.includes(item._id)
        case type === 'projecttemplates' && level === 'second':
            return filterParams.curated.curatedSelection2Id.includes(item._id)
        case type === 'projecttemplates' && level === 'third':
            return filterParams.curated.curatedSelection3Id.includes(item._id)
        case type === 'projecttemplates' && level === 'four':
        return filterParams.curated.curatedSelection4Id.includes(item._id)
    }
  }

  const handleModal = () => {
    setModal(true);
    setMultiple(true);
  };

  const locationSubmit = (e, id) => {
    let location = e.target.value;
    let result = datas.map((me) => {
      if (me._id === id) return { ...me, location: location };
      else return me;
    });
    setDatas([...result]);
  };

  const getColorId = (id) => {
    setColorID(id);
  };

  const getMoreData = () =>{
    setLoadMore(true)


    setTimeout(function() {
        // Your code to be executed after 3 seconds
        setNoOfRecord(noOfRecord+24)
        setPage(page+1)
    }, 2500);

    
    productFliter(filterParams, sortSelect ,ProjectData ,noOfRecord+24 , page+1 )

}
  let debounceTimer;
  const handleSeachFilter = (e) => {
    setLoader(true);
    setSearchBar(e.target.value)
    debounceTimer = setTimeout(() => {
      setSearch(e.target.value);
    //   if (!location.pathname.includes("createSubmittal") && !location.pathname.includes("createBrochure")) {
    //     navigate("/products");
    //   }
    }, 1000);
  };

  return (
    <>
      <section className="landingSubmittal">
        <div className="container">
          <div className="editProject">
            {!props.modal && <div className="sortHead">
              <p>Sort By</p>
              <div className={`form-group`}>
                {/* <label className="title">{props.label}</label>} */}
                <div className="position-relative ">
                  <select
                    onChange={(e) => handleFilter(e)}
                    value={sortSelect}
                    className="form-control custom-select"
                  >
                    <option disabled selected value={"asc"}>
                      {"Relevance"}
                    </option>
                    <option value={""}>{" Alphabetical (A to Z)"}</option>
                    <option value={"-"}>{"Alphabetical (Z to A)"}</option>
                  </select>
                </div>
              </div>
              {/* <SelectBox grouClass="Selectcountry" selectList={SelectListt} selected={"Select Relevance"} control={control} /> */}
            </div>}
            <div className="editProject__bottom">
              <div className="bottomLeftt" style={{ paddingTop: props.modal && 0 }}>
                <div
                  className={`bottomLeftt__filter ${
                    mobileFilter ? "show" : ""
                  }`}
                  style={{ paddingTop: props.modal && 0, paddingLeft: props.modal && 0 }}
                >
                  <div className="mobileTitle">
                    <h4>Filter</h4>
                    <button
                      type="button"
                      className="sortBy__filter"
                      onClick={onToggleFilter}
                    >
                      <img src={Images.icon.closeCircle} alt="close" />
                    </button>
                  </div>
                  {titleLoader ? (
                    <div style={{ textAlign: "center", marginTop: "100px" }}>
                      <ClipLoader />
                    </div>
                  ) : (
                    <ul style={{ marginBottom: "10px" }}>
                      {modifiedFilterList?.map(
                        (data, index) =>
                          titleArr?.find((titl) => titl?.id === data.id)
                            ?.status === 1 && (
                            <li key={data.id}>
                              <button
                                onClick={() => onCategoryClick(data.id, index)}
                              >
                                {titleArr?.find((titl) => titl?.id === data.id)
                                    ?.name }
                                <span className="caret">
                                  {data._id === data.showChild ? ( <AiOutlineCaretUp />  ) : (<AiOutlineCaretDown /> )}
                                </span>
                              </button>
                              {data.showChild && (
                                <ul>
                                  {data &&
                                    data?.parentList && data?.parentList?.length > 0 && data?.parentList?.map(
                                      (parent, index) =>
                                        parent.status === 1 && (
                                          <li key={parent._id}>
                                            <div
                                              style={{  display: "flex", flexDirection: "row", }}
                                            >
                                              <input
                                                disabled={loader}
                                                type={"checkbox"}
                                                checked={isChecked( parent,data.type,"first")}
                                                onChange={(e) => checkedFilterList(e,parent,data.type,"first")}
                                              />

                                              <button
                                                onClick={() => onChildClick(parent?._id) }
                                              >
                                                {parent.title}
                                                {parent.child.length !== 0 && (
                                                  <span className="caret">
                                                    {parent._id ===
                                                    currentChild ? (<AiOutlineCaretUp />) : ( <AiOutlineCaretDown />)}
                                                  </span>
                                                )}
                                              </button>
                                            </div>
                                            {parent._id === currentChild && (
                                              <ul>
                                                {parent.child.map(
                                                  (subChild, index) =>
                                                    subChild.status === 1 && (
                                                      <li key={subChild._id}>
                                                        <div
                                                          style={{ display: "flex", flexDirection: "row", }}
                                                        >
                                                          <input
                                                            disabled={loader} type={"checkbox"} checked={isChecked(
                                                              subChild, data.type, "second"
                                                            )}
                                                            onChange={(e) =>
                                                              checkedFilterList( e,subChild,data.type,"second") }
                                                          />
                                                          <button  type="button" onClick={() => onSubChildClick(subChild._id)}
                                                          >
                                                            <span> {subChild.title} </span> {subChild?.child
                                                              ?.length !==  0 && (
                                                              <span className="caret">
                                                                {subChild._id === currentSubChild ? ( <AiOutlineCaretUp />
                                                                ) : ( <AiOutlineCaretDown /> )}
                                                              </span>
                                                            )}
                                                          </button>
                                                        </div>
                                                        {subChild._id === currentSubChild && (
                                                          <ul>
                                                            {subChild.child.map(
                                                              (x, index) => x.status === 1 && (
                                                                  <li key={x._id} >
                                                                    <div
                                                                      style={{ display: "flex", flexDirection: "row",
                                                                      }}
                                                                    >
                                                                      <input
                                                                        disabled={ loader }
                                                                        type={ "checkbox" }
                                                                        checked={isChecked( x,  data.type, "third" )}
                                                                        onChange={(e) => checkedFilterList( e,
                                                                            x, data.type, "third" ) }
                                                                      />
                                                                      <button type="button" onClick={() => onSubCatChildClick(x?._id)}>
                                                                        <span>{x.title}</span>
                                                                            {x?.child?.length !== 0 &&
                                                                                <span className='caret'>{x._id === currentSubChild ?
                                                                                    <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</span>
                                                                            }
                                                                      </button>
                                                                    </div>
                                                                   
                                                                      {(x._id === currentSubCatChild) &&
                                                                            <ul>
                                                                            {x?.child?.map((is, index) => (
                                                                                <li key={is._id}>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        <input disabled={loader} type={'checkbox'}
                                                                                        checked={isChecked(is, data.type, 'four')}
                                                                                        onChange={(e) => checkedFilterList(e, is, data.type, 'four')}
                                                                                            />
                                                                                        <button type='button'>
                                                                                            {is.title}
                                                                                        </button>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                            </ul>
                                                                            }
                                                                       
                                                                  </li>
                                                                )
                                                            )}
                                                          </ul>
                                                        )}
                                                      </li>
                                                    )
                                                )}
                                              </ul>
                                            )}
                                          </li>
                                        )
                                    )}
                                </ul>
                              )}
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
              </div>
              <div className="bottomRightt" style={{ paddingTop: props.modal && 0 }}>
                <div className="headRight">
                  {/* {loader ? ( */}
                  {/* <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ClipLoader />
                    </div> */}
                  {/* ) : ( */}
                    <>
                      {ProjectData && Object.keys(ProjectData).length > 0 && (
                        <div className="headRightTop">
                            <div className="formGroup">
                              <input
                                type={"text"}
                                value={searchBar}
                                className="formControl"
                                onChange={(e) => handleSeachFilter(e)}
                                placeholder="What are you looking for ?"
                              />
                            </div>
                          <p>Add products to your current project</p>

                        {/* <h1>
                            {ProjectData?.name?.charAt(0).toUpperCase() +
                              ProjectData?.name?.slice(1)}
                          </h1> */}

                        <h1>
                          {props?.replaceProduct?.productName}
                        </h1>
                          <p>
                            {ProjectData?.address
                              ? ProjectData?.address?.line1
                                ? ProjectData?.address?.line1
                                : "" +
                                  " " +
                                  ProjectData?.address?.line2 ? ProjectData?.address?.line2 : '' +
                                  " " +
                                  ProjectData?.address?.city ? ProjectData?.address?.city : '' +
                                  " " +
                                  ProjectData?.address?.state ? ProjectData?.address?.state : '' +
                                  " " +
                                  ProjectData?.address?.zip ? ProjectData?.address?.zip : '' +
                                  " " +
                                  ProjectData?.address?.country ? ProjectData?.address?.country : ''
                              : ""}
                          </p>
                        {!props.isFilter ? <p
                            style={{ color: "red", marginBottom: "15px" }}
                        >{`${totalAddedProducts} Products In Project`}</p> : <p></p>}
                          {productCount > 0 && (
                            <p style={{ fontWeight: "500px", color: "black" }}>
                              {" "}
                              {productCount} Products Selected{" "}
                              <div style={{ marginTop: "7px" }}>
                                <Button
                                  type="primary"
                                  value="Add products to project"
                                  click={() => handleModal()}
                                />
                              </div>{" "}
                            </p>
                          )}
                        </div>
                      )}
                      <div className="headRightBottom">
                          {props.modal ?
                            //     <Button
                            //   type="primary"
                            //   value="Save Products"
                            // />
                            <></>
                        :
                        <>
                          {location.pathname === `/createBrochure/${param.id}` ? (
                            <Button
                              type="primary"
                              value="Create A Brochure"
                              click={props.changeStep}
                              isDisabled={totalAddedProducts > 0 ? false : true}
                            />
                          ) : (
                            <Button
                              type="primary"
                              value="Create A Submittal"
                              click={props.changeStep}
                              isDisabled={totalAddedProducts > 0 ? false : true}
                            />
                          )}
                          <br />
                          <b onClick={() => navigate("/projects")}>
                            Choose Another Project
                          </b>
                            </>
                          }
                      </div>
                  </>
                </div>

                {FilterArr.length !== 0 && (
                  <div className="sortBy">
                    <div className="sortBy__tags">
                      <ul>
                        {FilterArr.map((data) => (
                          <li>
                            <span className="tag">
                              {data.name}
                              <img
                                src={Images.icon.closeCircle}
                                alt="close"
                                onClick={() => deleteFilterList(data)}
                              />
                            </span>
                          </li>
                        ))}
                        <li>
                          {FilterArr.length !== 0 && (
                            <span onClick={() => clearAll()} className="clear">
                              Clear All
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {loader ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ClipLoader />
                  </div>
                ) : (
                  <div className="row">
                    {datas && datas?.length > 0 ? (
                      datas?.map((item) => (
                        <div
                          className="productCard"
                          //  key={item._id}
                        >
                          <SubmittalProductCard
                            image={
                              item.image === undefined ||
                              item.image === null ||
                              item.image === ""
                                ? Images.image.productImg
                                : `${process.env.REACT_APP_IMAGE_BASEURL}${item.image}`
                            }
                            title={item?.name ? item?.name : "N/A"}
                            text={item.description}
                            titleArr={titleArr}
                            // price={item.createdAt}
                            // status={item.status}
                            id={item._id}
                            onSelect={(cid) => handleOnSelect(item, cid)}
                            // goLink={() => onLink(item)}
                            onSelectClick={(clrId) => handleselect(item, clrId)}
                            item={item}
                            manuName={item?.manufacturerId_data}
                            modelGroup={item?.modelGroup}
                            isSelected={item?.isSelected}
                            isChecked={item?.matched}
                            remove={(dlId) => handleProductRemove(dlId, item)}
                            loader={loader}
                            loaderId={LoaderId}
                            color={item?.images}
                            getColorId={(id) => getColorId(id)}
                            isCheckClr={item?.isCheckClr}
                            isFilter={props.isFilter}
                            // btn={sampleProductList.includes(item._id) ? true : false}
                          />
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p>No Data Found</p>
                      </div>
                    )}
                  </div>
                )}
                {/* {console.log("gone",maxProduct ,noOfRecord)} */}
                {
                  datas?.length > 0 && (maxProduct > datas?.length) && 
                  <div className='addMoreButtonDiv'>
                  {
                      
                      <button disabled={loadMore} onClick={()=>getMoreData()} className='addMoreButton' >{ 
                          loadMore ?
                            <div className='loader'>
                          <ClipLoader color={"black"} loading={loadMore} size={25} />
                          </div> :
                          <div className='viewMoreLus'>
                            VIEW 24 MORE
                            <img src={PlusIcon}/>

                          </div>
                            }
                          </button>
                  }
                    </div>
                }


                {/* {(datas && datas?.length > 0) ? datas?.map((item, index) => (
                                    <div className="productInfoCards">
                                        {!item.matched ? <div className="inputCheckBox" >
                                            <input type="checkbox" className="inputt" id="select"
                                                name="select" checked={item.isSelected} onClick={() => handleselect(item)} />

                                        </div> : ''}
                                        <div className="first">
                                            <div className="firstImg">

                                                <img src={item.image === undefined || item.image === null || item.image === "" ?
                                                    Images.image.productImg : `${process.env.REACT_APP_IMAGE_BASEURL}/${item.image}`} />

                                            </div>
                                        </div>
                                        <div className="second">
                                            {
                                                <div style={{ width: '60%' }} >
                                                    <div className={`form-group `}>
                                                        <label className="title">{props.label}</label>
                                                        <div className="position-relative ">
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            <h1>{item.name}</h1>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="third">
                                            <div className="thirdChild">
                                                <div className="thirdChildd" >
                                                    <b>Product Type</b>
                                                    <p>{item?.typeId_data?.title ? item?.typeId_data?.title : "N/A"}</p>
                                                </div>
                                                <div className="thirdChildd" >
                                                    <b>Operation</b>
                                                    <p>{item.operation ? item.operation : "N/A"}</p>
                                                </div>
                                                                                            </div>

                                            {item.matched ?

                                                (loader && LoaderId === item._id ?
                                                    <ClipLoader /> : <Button grouClass="thirdButton" type="secondary"
                                                        value={"Remove from project"}
                                                        click={() => handleProductRemove(item)} />) :

                                                <>{item.isSelected ? "" :
                                                    (loader && LoaderId === item._id ?
                                                        <ClipLoader />
                                                        : <Button grouClass="thirdButton" type="primary"
                                                            value={"Add to Project"}
                                                            click={() => handleOnSelect(item)}
                                                        />
                                                    )
                                                }
                                                </>}

                                        </div>
                                    </div>)) :
                                    <div style={{ marginTop: "15px", display: 'flex', justifyContent: 'center' }} >
                                        <p>No Data Found</p>
                                    </div>

                                } */}
              </div>
            </div>
          </div>
        </div>
        <ProductToProjectModal
          list={locationList}
          loader={modalLoader}
          show={modal}
          clickProject={handleClose}
          projectIDs={param.id}
          list1={projectList}
          projectdata={ProjectData}
          onAddProduct1={onAddProduct1}
          onAddProduct2={onAddProduct2}
        />
      </section>
    </>
  );
};

export default CreateSubmittalLanding;
