export const countryList = [
   
    {"value":"Afghanistan","code":"AF","id":1},
    {"value":"Aland Islands","code":"AX","id":2},
    {"value":"Albania","code":"AL","id":3},
    {"value":"Algeria","code":"DZ","id":4},
    {"value":"American Samoa","code":"AS","id":5},
    {"value":"Andorra","code":"AD","id":6},
    {"value":"Angola","code":"AO","id":7},
    {"value":"Anguilla","code":"AI","id":8},
    {"value":"Antarctica","code":"AQ","id":9},
    {"value":"Antigua and Barbuda","code":"AG","id":10},
    {"value":"Argentina","code":"AR","id":11},
    {"value":"Armenia","code":"AM","id":12},
    {"value":"Aruba","code":"AW","id":13},
    {"value":"Australia","code":"AU","id":14},
    {"value":"Austria","code":"AT","id":15},
    {"value":"Azerbaijan","code":"AZ","id":16},
    {"value":"Bahamas","code":"BS","id":17},
    {"value":"Bahrain","code":"BH","id":18},
    {"value":"Bangladesh","code":"BD","id":19},
    {"value":"Barbados","code":"BB","id":20},
    {"value":"Belarus","code":"BY","id":21},
    {"value":"Belgium","code":"BE","id":22},
    {"value":"Belize","code":"BZ","id":23},
    {"value":"Benin","code":"BJ","id":24},
    {"value":"Bermuda","code":"BM","id":25},
    {"value":"Bhutan","code":"BT","id":26},
    {"value":"Bolivia","code":"BO","id":27},
    {"value":"Bonaire, Sint Eustatius and Saba","code":"BQ","id":28},
    {"value":"Bosnia and Herzegovina","code":"BA","id":29},
    {"value":"Botswana","code":"BW","id":30},
    {"value":"Bouvet Island","code":"BV","id":31},
    {"value":"Brazil","code":"BR","id":32},
    {"value":"British Indian Ocean Territory","code":"IO","id":33},
    {"value":"Brunei Darussalam","code":"BN","id":34},
    {"value":"Bulgaria","code":"BG","id":35},
    {"value":"Burkina Faso","code":"BF","id":36},
    {"value":"Burundi","code":"BI","id":37},
    {"value":"Cambodia","code":"KH","id":38},
    {"value":"Cameroon","code":"CM","id":39},
    {"value":"Canada","code":"CA","id":40},
    {"value":"Cape Verde","code":"CV","id":41},
    {"value":"Cayman Islands","code":"KY","id":42},
    {"value":"Central African Republic","code":"CF","id":43},
    {"value":"Chad","code":"TD","id":44},
    {"value":"Chile","code":"CL","id":45},
    {"value":"China","code":"CN","id":46},
    {"value":"Christmas Island","code":"CX","id":47},
    {"value":"Cocos (Keeling) Islands","code":"CC","id":48},
    {"value":"Colombia","code":"CO","id":49},
    {"value":"Comoros","code":"KM","id":50},
    {"value":"Congo","code":"CG","id":51},
    {"value":"Congo, Democratic Republic of the Congo","code":"CD","id":52},
    {"value":"Cook Islands","code":"CK","id":53},
    {"value":"Costa Rica","code":"CR","id":54},
    {"value":"Cote D'Ivoire","code":"CI","id":55},
    {"value":"Croatia","code":"HR","id":56},
    {"value":"Cuba","code":"CU","id":57},
    {"value":"Curacao","code":"CW","id":58},
    {"value":"Cyprus","code":"CY","id":59},
    {"value":"Czech Republic","code":"CZ","id":60},
    {"value":"Denmark","code":"DK","id":61},
    {"value":"Djibouti","code":"DJ","id":62},
    {"value":"Dominica","code":"DM","id":63},
    {"value":"Dominican Republic","code":"DO","id":64},
    {"value":"Ecuador","code":"EC","id":65},
    {"value":"Egypt","code":"EG","id":66},
    {"value":"El Salvador","code":"SV","id":67},
    {"value":"Equatorial Guinea","code":"GQ","id":68},
    {"value":"Eritrea","code":"ER","id":69},
    {"value":"Estonia","code":"EE","id":70},
    {"value":"Ethiopia","code":"ET","id":71},
    {"value":"Falkland Islands (Malvinas)","code":"FK","id":72},
    {"value":"Faroe Islands","code":"FO","id":73},
    {"value":"Fiji","code":"FJ","id":74},
    {"value":"Finland","code":"FI","id":75},
    {"value":"France","code":"FR","id":76},
    {"value":"French Guiana","code":"GF","id":77},
    {"value":"French Polynesia","code":"PF","id":78},
    {"value":"French Southern Territories","code":"TF","id":79},
    {"value":"Gabon","code":"GA","id":80},
    {"value":"Gambia","code":"GM","id":81},
    {"value":"Georgia","code":"GE","id":82},
    {"value":"Germany","code":"DE","id":83},
    {"value":"Ghana","code":"GH","id":84},
    {"value":"Gibraltar","code":"GI","id":85},
    {"value":"Greece","code":"GR","id":86},
    {"value":"Greenland","code":"GL","id":87},
    {"value":"Grenada","code":"GD","id":88},
    {"value":"Guadeloupe","code":"GP","id":89},
    {"value":"Guam","code":"GU","id":90},
    {"value":"Guatemala","code":"GT","id":91},
    {"value":"Guernsey","code":"GG","id":92},
    {"value":"Guinea","code":"GN","id":93},
    {"value":"Guinea-Bissau","code":"GW","id":94},
    {"value":"Guyana","code":"GY","id":95},
    {"value":"Haiti","code":"HT","id":96},
    {"value":"Heard Island and Mcdonald Islands","code":"HM","id":97},
    {"value":"Holy See (Vatican City State)","code":"VA","id":98},
    {"value":"Honduras","code":"HN","id":99},
    {"value":"Hong Kong","code":"HK","id":100},
    {"value":"Hungary","code":"HU","id":101},
    {"value":"Iceland","code":"IS","id":102},
    {"value":"India","code":"IN","id":103},
    {"value":"Indonesia","code":"ID","id":104},
    {"value":"Iran, Islamic Republic of","code":"IR","id":105},
    {"value":"Iraq","code":"IQ","id":106},
    {"value":"Ireland","code":"IE","id":107},
    {"value":"Isle of Man","code":"IM","id":108},
    {"value":"Israel","code":"IL","id":109},
    {"value":"Italy","code":"IT","id":110},
    {"value":"Jamaica","code":"JM","id":111},
    {"value":"Japan","code":"JP","id":112},
    {"value":"Jersey","code":"JE","id":113},
    {"value":"Jordan","code":"JO","id":114},
    {"value":"Kazakhstan","code":"KZ","id":115},
    {"value":"Kenya","code":"KE","id":116},
    {"value":"Kiribati","code":"KI","id":117},
    {"value":"Korea, Democratic People's Republic of","code":"KP","id":118},
    {"value":"Korea, Republic of","code":"KR","id":119},
    {"value":"Kosovo","code":"XK","id":120},
    {"value":"Kuwait","code":"KW","id":121},
    {"value":"Kyrgyzstan","code":"KG","id":122},
    {"value":"Lao People's Democratic Republic","code":"LA","id":123},
    {"value":"Latvia","code":"LV","id":124},
    {"value":"Lebanon","code":"LB","id":125},
    {"value":"Lesotho","code":"LS","id":126},
    {"value":"Liberia","code":"LR","id":127},
    {"value":"Libyan Arab Jamahiriya","code":"LY","id":128},
    {"value":"Liechtenstein","code":"LI","id":129},
    {"value":"Lithuania","code":"LT","id":130},
    {"value":"Luxembourg","code":"LU","id":131},
    {"value":"Macao","code":"MO","id":132},
    {"value":"Macedonia, the Former Yugoslav Republic of","code":"MK","id":133},
    {"value":"Madagascar","code":"MG","id":134},
    {"value":"Malawi","code":"MW","id":135},
    {"value":"Malaysia","code":"MY","id":136},
    {"value":"Maldives","code":"MV","id":137},
    {"value":"Mali","code":"ML","id":138},
    {"value":"Malta","code":"MT","id":139},
    {"value":"Marshall Islands","code":"MH","id":140},
    {"value":"Martinique","code":"MQ","id":141},
    {"value":"Mauritania","code":"MR","id":142},
    {"value":"Mauritius","code":"MU","id":143},
    {"value":"Mayotte","code":"YT","id":144},
    {"value":"Mexico","code":"MX","id":145},
    {"value":"Micronesia, Federated States of","code":"FM","id":146},
    {"value":"Moldova, Republic of","code":"MD","id":147},
    {"value":"Monaco","code":"MC","id":148},
    {"value":"Mongolia","code":"MN","id":149},
    {"value":"Montenegro","code":"ME","id":150},
    {"value":"Montserrat","code":"MS","id":151},
    {"value":"Morocco","code":"MA","id":152},
    {"value":"Mozambique","code":"MZ","id":153},
    {"value":"Myanmar","code":"MM","id":154},
    {"value":"Namibia","code":"NA","id":155},
    {"value":"Nauru","code":"NR","id":156},
    {"value":"Nepal","code":"NP","id":157},
    {"value":"Netherlands","code":"NL","id":158},
    {"value":"Netherlands Antilles","code":"AN","id":159},
    {"value":"New Caledonia","code":"NC","id":160},
    {"value":"New Zealand","code":"NZ","id":161},
    {"value":"Nicaragua","code":"NI","id":162},
    {"value":"Niger","code":"NE","id":163},
    {"value":"Nigeria","code":"NG","id":164},
    {"value":"Niue","code":"NU","id":165},
    {"value":"Norfolk Island","code":"NF","id":166},
    {"value":"Northern Mariana Islands","code":"MP","id":167},
    {"value":"Norway","code":"NO","id":168},
    {"value":"Oman","code":"OM","id":169},
    {"value":"Pakistan","code":"PK","id":170},
    {"value":"Palau","code":"PW","id":171},
    {"value":"Palestinian Territory, Occupied","code":"PS","id":172},
    {"value":"Panama","code":"PA","id":173},
    {"value":"Papua New Guinea","code":"PG","id":174},
    {"value":"Paraguay","code":"PY","id":175},
    {"value":"Peru","code":"PE","id":176},
    {"value":"Philippines","code":"PH","id":177},
    {"value":"Pitcairn","code":"PN","id":178},
    {"value":"Poland","code":"PL","id":179},
    {"value":"Portugal","code":"PT","id":180},
    {"value":"Puerto Rico","code":"PR","id":181},
    {"value":"Qatar","code":"QA","id":182},
    {"value":"Reunion","code":"RE","id":183},
    {"value":"Romania","code":"RO","id":184},
    {"value":"Russian Federation","code":"RU","id":185},
    {"value":"Rwanda","code":"RW","id":186},
    {"value":"Saint Barthelemy","code":"BL","id":187},
    {"value":"Saint Helena","code":"SH","id":188},
    {"value":"Saint Kitts and Nevis","code":"KN","id":189},
    {"value":"Saint Lucia","code":"LC","id":190},
    {"value":"Saint Martin","code":"MF","id":191},
    {"value":"Saint Pierre and Miquelon","code":"PM","id":192},
    {"value":"Saint Vincent and the Grenadines","code":"VC","id":193},
    {"value":"Samoa","code":"WS","id":194},
    {"value":"San Marino","code":"SM","id":195},
    {"value":"Sao Tome and Principe","code":"ST","id":196},
    {"value":"Saudi Arabia","code":"SA","id":197},
    {"value":"Senegal","code":"SN","id":198},
    {"value":"Serbia","code":"RS","id":199},
    {"value":"Serbia and Montenegro","code":"CS","id":200},
    {"value":"Seychelles","code":"SC","id":201},
    {"value":"Sierra Leone","code":"SL","id":202},
    {"value":"Singapore","code":"SG","id":203},
    {"value":"Sint Maarten","code":"SX","id":204},
    {"value":"Slovakia","code":"SK","id":205},
    {"value":"Slovenia","code":"SI","id":206},
    {"value":"Solomon Islands","code":"SB","id":207},
    {"value":"Somalia","code":"SO","id":208},
    {"value":"South Africa","code":"ZA","id":209},
    {"value":"South Georgia and the South Sandwich Islands","code":"GS","id":210},
    {"value":"South Sudan","code":"SS","id":211},
    {"value":"Spain","code":"ES","id":212},
    {"value":"Sri Lanka","code":"LK","id":213},
    {"value":"Sudan","code":"SD","id":214},
    {"value":"Surivalue","code":"SR","id":215},
    {"value":"Svalbard and Jan Mayen","code":"SJ","id":216},
    {"value":"Swaziland","code":"SZ","id":217},
    {"value":"Sweden","code":"SE","id":218},
    {"value":"Switzerland","code":"CH","id":219},
    {"value":"Syrian Arab Republic","code":"SY","id":220},
    {"value":"Taiwan, Province of China","code":"TW","id":221},
    {"value":"Tajikistan","code":"TJ","id":222},
    {"value":"Tanzania, United Republic of","code":"TZ","id":223},
    {"value":"Thailand","code":"TH","id":224},
    {"value":"Timor-Leste","code":"TL","id":225},
    {"value":"Togo","code":"TG","id":226},
    {"value":"Tokelau","code":"TK","id":227},
    {"value":"Tonga","code":"TO","id":228},
    {"value":"Trinidad and Tobago","code":"TT","id":229},
    {"value":"Tunisia","code":"TN","id":230},
    {"value":"Turkey","code":"TR","id":231},
    {"value":"Turkmenistan","code":"TM","id":232},
    {"value":"Turks and Caicos Islands","code":"TC","id":233},
    {"value":"Tuvalu","code":"TV","id":234},
    {"value":"Uganda","code":"UG","id":235},
    {"value":"Ukraine","code":"UA","id":236},
    {"value":"United Arab Emirates","code":"AE","id":237},
    {"value":"United Kingdom","code":"GB","id":238},
    {"value":"United States","code":"US","id":239},
    {"value":"United States Minor Outlying Islands","code":"UM","id":240},
    {"value":"Uruguay","code":"UY","id":241},
    {"value":"Uzbekistan","code":"UZ","id":242},
    {"value":"Vanuatu","code":"VU","id":243},
    {"value":"Venezuela","code":"VE","id":244},
    {"value":"Viet Nam","code":"VN","id":245},
    {"value":"Virgin Islands, British","code":"VG","id":246},
    {"value":"Virgin Islands, U.s.","code":"VI","id":247},
    {"value":"Wallis and Futuna","code":"WF","id":248},
    {"value":"Western Sahara","code":"EH","id":249},
    {"value":"Yemen","code":"YE","id":250},
    {"value":"Zambia","code":"ZM","id":251},
    {"value":"Zimbabwe","code":"ZW","id":252}
];
export const  countryList2 = [
    {"value":"Select a country","code":"sc","id":0},
     {"value":"Afghanistan","code":"AF","id":1},
     {"value":"Aland Islands","code":"AX","id":2},
     {"value":"Albania","code":"AL","id":3},
     {"value":"Algeria","code":"DZ","id":4},
     {"value":"American Samoa","code":"AS","id":5},
     {"value":"Andorra","code":"AD","id":6},
     {"value":"Angola","code":"AO","id":7},
     {"value":"Anguilla","code":"AI","id":8},
     {"value":"Antarctica","code":"AQ","id":9},
     {"value":"Antigua and Barbuda","code":"AG","id":10},
     {"value":"Argentina","code":"AR","id":11},
     {"value":"Armenia","code":"AM","id":12},
     {"value":"Aruba","code":"AW","id":13},
     {"value":"Australia","code":"AU","id":14},
     {"value":"Austria","code":"AT","id":15},
     {"value":"Azerbaijan","code":"AZ","id":16},
     {"value":"Bahamas","code":"BS","id":17},
     {"value":"Bahrain","code":"BH","id":18},
     {"value":"Bangladesh","code":"BD","id":19},
     {"value":"Barbados","code":"BB","id":20},
     {"value":"Belarus","code":"BY","id":21},
     {"value":"Belgium","code":"BE","id":22},
     {"value":"Belize","code":"BZ","id":23},
     {"value":"Benin","code":"BJ","id":24},
     {"value":"Bermuda","code":"BM","id":25},
     {"value":"Bhutan","code":"BT","id":26},
     {"value":"Bolivia","code":"BO","id":27},
     {"value":"Bonaire, Sint Eustatius and Saba","code":"BQ","id":28},
     {"value":"Bosnia and Herzegovina","code":"BA","id":29},
     {"value":"Botswana","code":"BW","id":30},
     {"value":"Bouvet Island","code":"BV","id":31},
     {"value":"Brazil","code":"BR","id":32},
     {"value":"British Indian Ocean Territory","code":"IO","id":33},
     {"value":"Brunei Darussalam","code":"BN","id":34},
     {"value":"Bulgaria","code":"BG","id":35},
     {"value":"Burkina Faso","code":"BF","id":36},
     {"value":"Burundi","code":"BI","id":37},
     {"value":"Cambodia","code":"KH","id":38},
     {"value":"Cameroon","code":"CM","id":39},
     {"value":"Canada","code":"CA","id":40},
     {"value":"Cape Verde","code":"CV","id":41},
     {"value":"Cayman Islands","code":"KY","id":42},
     {"value":"Central African Republic","code":"CF","id":43},
     {"value":"Chad","code":"TD","id":44},
     {"value":"Chile","code":"CL","id":45},
     {"value":"China","code":"CN","id":46},
     {"value":"Christmas Island","code":"CX","id":47},
     {"value":"Cocos (Keeling) Islands","code":"CC","id":48},
     {"value":"Colombia","code":"CO","id":49},
     {"value":"Comoros","code":"KM","id":50},
     {"value":"Congo","code":"CG","id":51},
     {"value":"Congo, Democratic Republic of the Congo","code":"CD","id":52},
     {"value":"Cook Islands","code":"CK","id":53},
     {"value":"Costa Rica","code":"CR","id":54},
     {"value":"Cote D'Ivoire","code":"CI","id":55},
     {"value":"Croatia","code":"HR","id":56},
     {"value":"Cuba","code":"CU","id":57},
     {"value":"Curacao","code":"CW","id":58},
     {"value":"Cyprus","code":"CY","id":59},
     {"value":"Czech Republic","code":"CZ","id":60},
     {"value":"Denmark","code":"DK","id":61},
     {"value":"Djibouti","code":"DJ","id":62},
     {"value":"Dominica","code":"DM","id":63},
     {"value":"Dominican Republic","code":"DO","id":64},
     {"value":"Ecuador","code":"EC","id":65},
     {"value":"Egypt","code":"EG","id":66},
     {"value":"El Salvador","code":"SV","id":67},
     {"value":"Equatorial Guinea","code":"GQ","id":68},
     {"value":"Eritrea","code":"ER","id":69},
     {"value":"Estonia","code":"EE","id":70},
     {"value":"Ethiopia","code":"ET","id":71},
     {"value":"Falkland Islands (Malvinas)","code":"FK","id":72},
     {"value":"Faroe Islands","code":"FO","id":73},
     {"value":"Fiji","code":"FJ","id":74},
     {"value":"Finland","code":"FI","id":75},
     {"value":"France","code":"FR","id":76},
     {"value":"French Guiana","code":"GF","id":77},
     {"value":"French Polynesia","code":"PF","id":78},
     {"value":"French Southern Territories","code":"TF","id":79},
     {"value":"Gabon","code":"GA","id":80},
     {"value":"Gambia","code":"GM","id":81},
     {"value":"Georgia","code":"GE","id":82},
     {"value":"Germany","code":"DE","id":83},
     {"value":"Ghana","code":"GH","id":84},
     {"value":"Gibraltar","code":"GI","id":85},
     {"value":"Greece","code":"GR","id":86},
     {"value":"Greenland","code":"GL","id":87},
     {"value":"Grenada","code":"GD","id":88},
     {"value":"Guadeloupe","code":"GP","id":89},
     {"value":"Guam","code":"GU","id":90},
     {"value":"Guatemala","code":"GT","id":91},
     {"value":"Guernsey","code":"GG","id":92},
     {"value":"Guinea","code":"GN","id":93},
     {"value":"Guinea-Bissau","code":"GW","id":94},
     {"value":"Guyana","code":"GY","id":95},
     {"value":"Haiti","code":"HT","id":96},
     {"value":"Heard Island and Mcdonald Islands","code":"HM","id":97},
     {"value":"Holy See (Vatican City State)","code":"VA","id":98},
     {"value":"Honduras","code":"HN","id":99},
     {"value":"Hong Kong","code":"HK","id":100},
     {"value":"Hungary","code":"HU","id":101},
     {"value":"Iceland","code":"IS","id":102},
     {"value":"India","code":"IN","id":103},
     {"value":"Indonesia","code":"ID","id":104},
     {"value":"Iran, Islamic Republic of","code":"IR","id":105},
     {"value":"Iraq","code":"IQ","id":106},
     {"value":"Ireland","code":"IE","id":107},
     {"value":"Isle of Man","code":"IM","id":108},
     {"value":"Israel","code":"IL","id":109},
     {"value":"Italy","code":"IT","id":110},
     {"value":"Jamaica","code":"JM","id":111},
     {"value":"Japan","code":"JP","id":112},
     {"value":"Jersey","code":"JE","id":113},
     {"value":"Jordan","code":"JO","id":114},
     {"value":"Kazakhstan","code":"KZ","id":115},
     {"value":"Kenya","code":"KE","id":116},
     {"value":"Kiribati","code":"KI","id":117},
     {"value":"Korea, Democratic People's Republic of","code":"KP","id":118},
     {"value":"Korea, Republic of","code":"KR","id":119},
     {"value":"Kosovo","code":"XK","id":120},
     {"value":"Kuwait","code":"KW","id":121},
     {"value":"Kyrgyzstan","code":"KG","id":122},
     {"value":"Lao People's Democratic Republic","code":"LA","id":123},
     {"value":"Latvia","code":"LV","id":124},
     {"value":"Lebanon","code":"LB","id":125},
     {"value":"Lesotho","code":"LS","id":126},
     {"value":"Liberia","code":"LR","id":127},
     {"value":"Libyan Arab Jamahiriya","code":"LY","id":128},
     {"value":"Liechtenstein","code":"LI","id":129},
     {"value":"Lithuania","code":"LT","id":130},
     {"value":"Luxembourg","code":"LU","id":131},
     {"value":"Macao","code":"MO","id":132},
     {"value":"Macedonia, the Former Yugoslav Republic of","code":"MK","id":133},
     {"value":"Madagascar","code":"MG","id":134},
     {"value":"Malawi","code":"MW","id":135},
     {"value":"Malaysia","code":"MY","id":136},
     {"value":"Maldives","code":"MV","id":137},
     {"value":"Mali","code":"ML","id":138},
     {"value":"Malta","code":"MT","id":139},
     {"value":"Marshall Islands","code":"MH","id":140},
     {"value":"Martinique","code":"MQ","id":141},
     {"value":"Mauritania","code":"MR","id":142},
     {"value":"Mauritius","code":"MU","id":143},
     {"value":"Mayotte","code":"YT","id":144},
     {"value":"Mexico","code":"MX","id":145},
     {"value":"Micronesia, Federated States of","code":"FM","id":146},
     {"value":"Moldova, Republic of","code":"MD","id":147},
     {"value":"Monaco","code":"MC","id":148},
     {"value":"Mongolia","code":"MN","id":149},
     {"value":"Montenegro","code":"ME","id":150},
     {"value":"Montserrat","code":"MS","id":151},
     {"value":"Morocco","code":"MA","id":152},
     {"value":"Mozambique","code":"MZ","id":153},
     {"value":"Myanmar","code":"MM","id":154},
     {"value":"Namibia","code":"NA","id":155},
     {"value":"Nauru","code":"NR","id":156},
     {"value":"Nepal","code":"NP","id":157},
     {"value":"Netherlands","code":"NL","id":158},
     {"value":"Netherlands Antilles","code":"AN","id":159},
     {"value":"New Caledonia","code":"NC","id":160},
     {"value":"New Zealand","code":"NZ","id":161},
     {"value":"Nicaragua","code":"NI","id":162},
     {"value":"Niger","code":"NE","id":163},
     {"value":"Nigeria","code":"NG","id":164},
     {"value":"Niue","code":"NU","id":165},
     {"value":"Norfolk Island","code":"NF","id":166},
     {"value":"Northern Mariana Islands","code":"MP","id":167},
     {"value":"Norway","code":"NO","id":168},
     {"value":"Oman","code":"OM","id":169},
     {"value":"Pakistan","code":"PK","id":170},
     {"value":"Palau","code":"PW","id":171},
     {"value":"Palestinian Territory, Occupied","code":"PS","id":172},
     {"value":"Panama","code":"PA","id":173},
     {"value":"Papua New Guinea","code":"PG","id":174},
     {"value":"Paraguay","code":"PY","id":175},
     {"value":"Peru","code":"PE","id":176},
     {"value":"Philippines","code":"PH","id":177},
     {"value":"Pitcairn","code":"PN","id":178},
     {"value":"Poland","code":"PL","id":179},
     {"value":"Portugal","code":"PT","id":180},
     {"value":"Puerto Rico","code":"PR","id":181},
     {"value":"Qatar","code":"QA","id":182},
     {"value":"Reunion","code":"RE","id":183},
     {"value":"Romania","code":"RO","id":184},
     {"value":"Russian Federation","code":"RU","id":185},
     {"value":"Rwanda","code":"RW","id":186},
     {"value":"Saint Barthelemy","code":"BL","id":187},
     {"value":"Saint Helena","code":"SH","id":188},
     {"value":"Saint Kitts and Nevis","code":"KN","id":189},
     {"value":"Saint Lucia","code":"LC","id":190},
     {"value":"Saint Martin","code":"MF","id":191},
     {"value":"Saint Pierre and Miquelon","code":"PM","id":192},
     {"value":"Saint Vincent and the Grenadines","code":"VC","id":193},
     {"value":"Samoa","code":"WS","id":194},
     {"value":"San Marino","code":"SM","id":195},
     {"value":"Sao Tome and Principe","code":"ST","id":196},
     {"value":"Saudi Arabia","code":"SA","id":197},
     {"value":"Senegal","code":"SN","id":198},
     {"value":"Serbia","code":"RS","id":199},
     {"value":"Serbia and Montenegro","code":"CS","id":200},
     {"value":"Seychelles","code":"SC","id":201},
     {"value":"Sierra Leone","code":"SL","id":202},
     {"value":"Singapore","code":"SG","id":203},
     {"value":"Sint Maarten","code":"SX","id":204},
     {"value":"Slovakia","code":"SK","id":205},
     {"value":"Slovenia","code":"SI","id":206},
     {"value":"Solomon Islands","code":"SB","id":207},
     {"value":"Somalia","code":"SO","id":208},
     {"value":"South Africa","code":"ZA","id":209},
     {"value":"South Georgia and the South Sandwich Islands","code":"GS","id":210},
     {"value":"South Sudan","code":"SS","id":211},
     {"value":"Spain","code":"ES","id":212},
     {"value":"Sri Lanka","code":"LK","id":213},
     {"value":"Sudan","code":"SD","id":214},
     {"value":"Surivalue","code":"SR","id":215},
     {"value":"Svalbard and Jan Mayen","code":"SJ","id":216},
     {"value":"Swaziland","code":"SZ","id":217},
     {"value":"Sweden","code":"SE","id":218},
     {"value":"Switzerland","code":"CH","id":219},
     {"value":"Syrian Arab Republic","code":"SY","id":220},
     {"value":"Taiwan, Province of China","code":"TW","id":221},
     {"value":"Tajikistan","code":"TJ","id":222},
     {"value":"Tanzania, United Republic of","code":"TZ","id":223},
     {"value":"Thailand","code":"TH","id":224},
     {"value":"Timor-Leste","code":"TL","id":225},
     {"value":"Togo","code":"TG","id":226},
     {"value":"Tokelau","code":"TK","id":227},
     {"value":"Tonga","code":"TO","id":228},
     {"value":"Trinidad and Tobago","code":"TT","id":229},
     {"value":"Tunisia","code":"TN","id":230},
     {"value":"Turkey","code":"TR","id":231},
     {"value":"Turkmenistan","code":"TM","id":232},
     {"value":"Turks and Caicos Islands","code":"TC","id":233},
     {"value":"Tuvalu","code":"TV","id":234},
     {"value":"Uganda","code":"UG","id":235},
     {"value":"Ukraine","code":"UA","id":236},
     {"value":"United Arab Emirates","code":"AE","id":237},
     {"value":"United Kingdom","code":"GB","id":238},
     {"value":"United States","code":"US","id":239},
     {"value":"United States Minor Outlying Islands","code":"UM","id":240},
     {"value":"Uruguay","code":"UY","id":241},
     {"value":"Uzbekistan","code":"UZ","id":242},
     {"value":"Vanuatu","code":"VU","id":243},
     {"value":"Venezuela","code":"VE","id":244},
     {"value":"Viet Nam","code":"VN","id":245},
     {"value":"Virgin Islands, British","code":"VG","id":246},
     {"value":"Virgin Islands, U.s.","code":"VI","id":247},
     {"value":"Wallis and Futuna","code":"WF","id":248},
     {"value":"Western Sahara","code":"EH","id":249},
     {"value":"Yemen","code":"YE","id":250},
     {"value":"Zambia","code":"ZM","id":251},
     {"value":"Zimbabwe","code":"ZW","id":252}
 ];