import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'components/comman/Toaster';
import { AuthProvider } from 'context/auth/AuthContext';
import { SearchProvider } from 'context/searchContext/searchContext';
import { ProductContext } from 'context/ProductToProject/ProductContext';


const root = ReactDOM.createRoot(document.getElementById('root'));

const AuthWrapper=()=> {
  return   <AuthProvider><SearchProvider><ProductContext><App/></ProductContext></SearchProvider></AuthProvider>
  }
root.render(
  <>
  <React.StrictMode>
  <Toaster />
  <AuthWrapper/>
  </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
