import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiCheck, BiMinus } from "react-icons/bi";
import Button from "./comman/button";
import SelectBox from "./comman/SelectBox";
import { useLocation, useParams } from "react-router-dom";
import { API } from "API/API";
import { toast } from "react-toastify";

const ProductCard = (props) => {
  let location = useLocation();
  const [image, setImage] = useState();
  let param = useParams();
  const { handleSubmit, control, setValue, getValues } = useForm({
    yupResolver: yupResolver(),
  });
  const [check, setCheck] = useState(false);
  const [current, setCurrent] = useState("");
  const [active, setActive] = useState();
  const [colorID, setColorID] = useState("");
  const [titleLoader, setTitleLoader] = useState();

  const [titleArr, setTitleArr] = useState([]);

  // useEffect(()=>{

  // },[props?.titleArr])

  useEffect(() => {
    props?.color?.map((data, index) => {
      if (index === 0) {
        setCurrent(data._id);
      }
    });
    // getListing();
  }, []);

  const handleColorSelect = (index, item, id) => {
    setImage(item);
    setActive(true);
    setCurrent(id);
    setColorID(id);
    passColorId(id);
  };
  const passColorId = (id) => {
    props.getColorId(id);
  };

  const getListing = async () => {
    setTitleLoader(true);
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        setTitleArr(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    } finally {
      setTitleLoader(false);
    }
  };

  const onSelect = () => {
    if (colorID === "") {
      props?.color?.map((data, index) => {
        if (index === 0) {
          setColorID(data._id);
          passColorId(data._id);
        }
      });
    }
    setColorID("");
    props.onSelect();
  };

  return (
    <div className="productCard__over">
      <div>
        <div
          style={{
            padding: "20px",
            minHeight: "280px",
            display: "flex",
            alignItems: "center",
            maxHeight: "280px",
            justifyContent: "center",
          }}
          className={
            location.pathname === `/project-detail/${param.id}`
              ? "productCard__imgg"
              : "productCard__img"
          }
        >
          {props?.color?.length !== 0 &&
            props?.color?.map(
              (datas) =>
                datas._id === current && (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}${datas?.fileName}`}
                    alt={props.title}
                    onClick={props.goLink}
                  />
                )
            )}

          {location.pathname === `/project-detail/${param.id}` ||
          location.pathname === `/product-detail/${param.id}` ? (
            ""
          ) : (
            <input
              type="checkbox"
              className="inputt"
              id="select"
              checked={props.isChecked}
              name="select"
              onClick={() => props.onSelectClick(current)}
            />
          )}
        </div>
      </div>
      <div
        className="productCard__btn"
        style={{
          paddingLeft: "15px",
          paddingBottom: "5px",
          paddingRight: "15px",
        }}
      >
        <div className="productCard__textDetails">
          {location.pathname === `/project-detail/${param.id}` ? (
            <h4 onClick={props.goLink} style={{ height: "25px" }}>
              {props?.title}
            </h4>
          ) : (
            <h4 onClick={props.goLink}>{props?.title}</h4>
          )}

          <div className="productCard__color">
            <ul>
              {props.isChecked
                ? props?.color?.length !== 0 &&
                  props?.color?.map(
                    (item, index) =>
                      item._id === current && (
                        <li>

                          {item?.code2 ? (
                            <div className="mainDual">
                              <div
                                style={{
                                  backgroundColor: `${
                                    item?.code !== "" ? item.code : ""
                                  }`,
                                }}
                                className="colorInner"
                              ></div>
                              <div
                                style={{
                                  backgroundColor: `${
                                    item?.code2 !== "" ? item.code2 : ""
                                  }`,
                                }}
                                className="colorInner2"
                              ></div>
                            </div>
                          ) : (
                            <div className="main">
                              <div
                                style={{
                                  border: "1.5px solid gray",

                                  backgroundColor: `${item?.code}`,
                                }}
                              ></div>
                            </div>
                          )}
                        </li>
                      )
                  )
                : props?.color?.length !== 0 &&
                  props?.color?.map((item, index) => (
                    <li
                      onClick={() =>
                        handleColorSelect(index, item.fileName, item._id)
                      }
                    >

                      {item?.code2 ? (
                        <div className="mainDual">
                        
                          <div
                            style={{
                              backgroundColor: `${
                                item?.code !== "" ? item.code : ""
                              }`,
                            }}
                            className="colorInner"
                          ></div>
                          <div
                            style={{
                              backgroundColor: `${
                                item?.code2 !== "" ? item.code2 : ""
                              }`,
                            }}
                            className="colorInner2"
                          ></div>
                        </div>
                      ) : (
                        <div className="main">
                          <div
                            style={{
                              border: "1.5px solid gray",

                              backgroundColor: `${item?.code}`,
                            }}
                          ></div>
                        </div>
                      )}
                    </li>
                  ))}
            </ul>
          </div>
          <h5>
            <b>{`${
              props?.titleArr?.find((titl) => titl?.id === 1)?.name
            } : `}</b>
            {props?.titleArr?.find((titl) => titl?.id === 1)?.status === 1
              ? props?.manuName?.length > 0
                ? props?.manuName[0].title
                : "N/A"
              : "N/A"}
          </h5>

          {
            <span className="pp">
              <b>Model :</b>{" "}
              {`${props.color?.find((clr) => clr?._id === current)?.modelName}`}
            </span>
          }
          <p>{props?.text}</p>
          {props.location && <p>{props.location ? props.location : "N/A"}</p>}
        </div>
      </div>
      {props.btn !== true && (
        <div
          className="productCard__btn"
          style={{
            paddingLeft: "15px",
            paddingBottom: "5px",
            paddingRight: "15px",
          }}
        >
          {location.pathname === `/project-detail/${param.id}` ? (
            <Button
              type="secondaryProject"
              value="Remove From Project"
              click={props.removeProduct}
            />
          ) : location.pathname === `/product-detail/${param.id}` ? (
            <Button
              type="primaryyProjectt"
              value="Add to Project"
              click={() => onSelect()}
            />
          ) : props?.isSelected === undefined || !props.isSelected ? (
            <Button
              type="primaryyProject"
              value="Add to Project"
              click={() => onSelect()}
            />
          ) : (
            <Button
              type="primaryyProject"
              value="Remove From Project"
              click={props.onSelect}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default ProductCard;
