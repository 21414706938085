
export const projectData = [
    {
        id: 1,
        name: 'Project 1',
        date: '04-08-2022',
        type: 'NA',
        location: 'Zip: 110052',
        owner: 'NA',
    },
    {
        id: 2,
        name: 'Project 2',
        date: '04-08-2022',
        type: 'NA',
        location: 'Zip: 110052',
        owner: 'NA',
    },
    {
        id: 3,
        name: 'Project 3',
        date: '04-08-2022',
        type: 'NA',
        location: 'Zip: 110052',
        owner: 'NA',
    },
    {
        id: 4,
        name: 'Project 4',
        date: '04-08-2022',
        type: 'NA',
        location: 'Zip: 110052',
        owner: 'NA',
    },
];

export const templateList = [
    {
        id: 1,
        label: 'Hospitality',
        child: [
            {
                id: '1-1',
                label: 'Hotel',
            },
            {
                id: '1-2',
                label: 'Restaurant',
            },
        ]
    },
    {
        id: 2,
        label: 'Multi-family',
        child: [
            {
                id: '2-1',
                label: 'Condominium',
            },
        ]
    },
    {
        id: 3,
        label: 'Public Facilities',
        child: [
            {
                id: '3-1',
                label: 'Office',
            },
            {
                id: '3-2',
                label: 'Chain',
            },
            {
                id: '3-3',
                label: 'Government',
            },
            {
                id: '3-4',
                label: 'Recreation',
            },
        ]
    },
    {
        id: 4,
        label: 'Education',
        child: [
            {
                id: '4-1',
                label: 'Student Residence',
            },
            {
                id: '4-2',
                label: 'School',
            },
        ]
    },
    {
        id: 5,
        label: 'Healthcare',
        child: [
            {
                id: '5-1',
                label: 'Clinic',
            },
            {
                id: '5-2',
                label: 'Long Term Care',
            },
            {
                id: '5-3',
                label: 'Hospital',
            },
        ]
    },
];