import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import EditProjectTab from "components/tabs/editProjectTab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import '../stylesheets/createSubmittal.scss';
import MainProgressBar from "components/progressBar";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSearchContext } from "context/searchContext/searchContext";

const CreateSubmittal = (props) => {
    const {projectProgess,additionalProgress,contactProgress, projectDetailBar , isFirstStepDone} = useSearchContext()
    let navigate = useNavigate()
    const { handleSubmit, control, setValue, getValue } = useForm({
    });

    return (
        <section>
            <div className="container">
                <div className="editProject">
                    <div className="editProject__bottom">
                        <div className="bottomLeft">
                            <h1>Edit Project</h1>
                            <p>Project details appear on the final Submittal Prep and are mostly optional.
                                We recommend filling them all out to provide a comprehensive submittal package.</p>
                            <b> Project Details</b>
                            <MainProgressBar value={projectProgess} />
                            <br />
                            <b> Additional Information</b>
                            <MainProgressBar value={additionalProgress} />
                            <br />
                            <b> Contact Information</b>
                            <MainProgressBar value={contactProgress} />
                            <br />
                        </div>
                        <br />
                        <div className="bottomRight">
                            <EditProjectTab  />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateSubmittal