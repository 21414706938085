import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import '../stylesheets/editProject.scss'
import MainProgressBar from "components/progressBar";
import EditProjectTab from '../components/tabs/editProjectTab'
import { useNavigate, useParams } from "react-router-dom";
import { useSearchContext } from "context/searchContext/searchContext";
import { API } from "API/API";
import { useAuthContext } from "context/auth/AuthContext";
import moment from 'moment';
// import CustomProgressBar from "components/progressBar";

const EditProject = () => {
    const { handleSubmit, control, setValue, getValue } = useForm({
    });
    const { projectProgess, additionalProgress, contactProgress, projectDetailBar, isFirstStepDone } = useSearchContext()
    const { token } = useAuthContext();
    const navigate = useNavigate()
    const params = useParams()
    const [data, setData] = useState({})
    useEffect(() => {
        if (token && token !== null) {

            getProjectData()
        }
    }, [token]);

    const getProjectData = async () => {

        try {
            let response = await API.GetProjectsById(params.id, token)
            if (response.success) {
                const data = response?.data
                setData(data)

            }
        }
        catch (error) {
            return error
        }

    }


    return (

        <section >
            <div className="container" >
                <div className="editProject ">
                    <div className="editProject__head">
                        <div className="inputBoxheadd">
                            <a className="backButton"
                                style={{ color: "#343E47", textDecoration: 'none', cursor: 'pointer' }}
                                onClick={() => navigate(`/project-detail/${params.id}`)}>
                                <p>Back to Project</p>
                                </a>
                        </div>
                        {/* <p>{"Created:"+" "+data?.createdAt?.slice(0,10)}</p> */}
                        <p>{"Created:" + " " + moment(data?.createdAt).format('MMM DD, YYYY')}</p>




                    </div>
                    <div className="editProject__bottom">

                        <div className="bottomLeftt">
                            <h1>Edit Project</h1>
                            <p>Project details appear on the final Submittal Prep and are mostly optional.
                                We recommend filling them all out to provide a comprehensive submittal package.</p>
                            <b> Project Details</b>
                            <MainProgressBar value={projectProgess} />
                            <br />
                            <b> Additional Information</b>
                            <MainProgressBar value={additionalProgress} />
                            <br />
                            <b> Contact Information</b>
                            <MainProgressBar value={contactProgress} />
                            <br />
                        </div>

                        <EditProjectTab />


                    </div>
                </div>
            </div>
        </section>

    )
}

export default EditProject