import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/comman/button";
import "stylesheets/product.scss";
import {
  Links,
  productData,
  productInfo,
  productResource,
} from "constants/ProductData";
import { AiOutlineTable } from "react-icons/ai";
import ProductCard from "components/ProductCard";
import { FaFileDownload } from "react-icons/fa";
import { Images } from "constants/Images";
import { API } from "API/API";
import ClipLoader from "react-spinners/ClipLoader";
import ContactSaleModal from "components/modal/ContactSaleModal";
import { toast } from "react-toastify";
import { useAuthContext } from "context/auth/AuthContext";
import ProductToProjectModal from "components/modal/ProductToProjectModal";
import parse from "html-react-parser";

const ProductDetailPage = () => {
  const navigate = useNavigate();
  // const { state } = useLocation();
  // const { item } = state;
  const [currentTab, setCurrentTab] = useState(1);
  const [relatedData, setRelatedData] = useState(productData);
  const [MainData, setMainData] = useState({});
  const [loader, setLoader] = useState(false);
  const [Similarloader, setSimilarLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { token, login } = useAuthContext();
  const param = useParams();
  const [similarData, setSimilarData] = useState([]);
  const [links, setLinks] = useState([]);
  const [locationList, setLocationList] = useState();
  const [projectList, setProjectList] = useState();
  const [modalLoader, setModalLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [titleArr, setTitleArr] = useState([]);
  const [titleLoader, setTitleLoader] = useState();
  const [colorID, setColorID] = useState("");

  const [ProductId, setProductId] = useState("");
  const [isProductId, setIsProductId] = useState(false);

  const [current, setCurrent] = useState(0);
  const [image, setImage] = useState();
  const [currentModel, setCurrentModel] = useState("");

  const tabs = [
    {
      id: 4,
      label: "Specification",
    },
    {
      id: 3,
      label: "Resources",
    },
    {
      id: 1,
      label: "Related Products",
    },
    {
      id: 2,
      label: "Product Information",
    },
  ];

  const onTab = (id) => {
    setCurrentTab((prebID) => {
      if (prebID !== id) return id;
      return id;
    });
  };
  const onSelect = (id) => {
    if (!login && token === null) {
      toast.error("You must login first.");
    } else {
      setProductId(id);
      setIsProductId(true);
      setModal(true);
    }
  };

  useEffect(() => {
    if (token) {
      getLocationList();
      GetTableData();
    }
  }, [token]);

  useEffect(() => {
    if (param?.id) {
      getAllDetails();
    }
  }, [param?.id]);

  useEffect(() => {
    getListing();
  }, []);

  const getListing = async () => {
    setTitleLoader(true);
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        setTitleArr(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    } finally {
      setTitleLoader(false);
    }
  };

  const getAllDetails = async () => {
    setLoader(true);
    try {
      let response = await API.ProductDetailById(param.id, token);
      if (response.success) {
        let data = response.data;
        setMainData(data);
        // if(!data?.additional_info || data?.additional_info=== undefined || data?.additional_info === ""){
        //   setCurrentTab(3)
        // }
        setLinks(response.data.productFiles.label);
        // SimilarProduct(response.data);
        if (
          currentModel === "" ||
          currentModel !== data?.images[0]?.modelName
        ) {
          setCurrentModel(data?.images[0]?.modelName);
        }
      }
    } catch (error) {
      return error;
    } finally {
      setLoader(false);
      setMainLoader(false);
    }
  };

  const obj = {
    maintenance_and_part: "Maintenance and part",
    maintenance_and_installation: "Maintenance and installation",
    repair_parts_diagram: "Repair parts diagram",
    spec_sheets_drawings: "Spec sheets drawings",
    specification_sheet_series_specific: "Specification sheet series specific",
    specifications_model_specific: "Specifications model specific",
    revit: "Revit",
  };

  const contactSalesRep = async (data) => {
    setLoader(true);
    try {
      let response = await API.contactSalesRep(data);

      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
      setShowModal(false);
    }
  };

  const handleAskLocalRep = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  const onLink = (id) => {
    navigate(`/product-detail/${id}`);
    // setMainLoader(true)
    // getAllDetails()
  };

  const getLocationList = async () => {
    try {
      let response = await API.getLocationList(token);
      setLocationList(response.data);
    } catch (error) {
      return error;
    }
  };

  const GetTableData = async () => {
    try {
      let response = await API.GetProjects("-createdAt", token);
      setProjectList([...response.data]);
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    if (!login && token === null) {
      toast.error("You must login first");
    } else {
      if (colorID === "") {
        MainData?.images?.map((data, index) => {
          if (index === 0) {
            setColorID(data._id);
          }
        });
      }
      setModal(true);
    }
  };

  const onAddProduct1 = async (location, project) => {
    setModalLoader(true);
    let params;
    try {
      if (isProductId) {
        params = {
          products: [
            { product: ProductId, location: location, color: colorID },
          ],
        };
      } else {
        params = {
          products: [{ product: param.id, location: location, color: colorID }],
        };
      }
      let response = await API.addproducttoproject(params, token, project);
      if (response.success) {
        // getProjectData()
        setModal(false);
        setColorID("");
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setModalLoader(false);
      setIsProductId(false);
    }
  };

  const onAddProduct2 = async (location, name, zipCode) => {
    setModalLoader(true);
    try {
      let params = {
        name: name.toLowerCase(),
        zipCode: zipCode,
      };
      let response = await API.AddProjects(params, token);
      if (response.success) {
        onAddProduct1(location, response.data._id);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleColorSelect = (index, item, id, model) => {
    setImage(item);
    setCurrent(index);
    setColorID(id);
    setCurrentModel(model);
  };

  const getColorId = (id) => {
    setColorID(id);
  };

  return (
    <React.Fragment>
      <section className="productDetail">
        {mainLoader ? (
          <div
            style={{
              display: "flex",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClipLoader />
          </div>
        ) : (
          <div className="container">
            {/* Call to Action */}
            <div className="productDetail__action">
              <ul>
                <li>
                  <Button
                    type="primary"
                    value="Add To Project"
                    click={() => handleAdd()}
                  />
                </li>
                <li>
                  <Button
                    type="secondary"
                    value="Ask Local Rep"
                    click={handleAskLocalRep}
                  />
                </li>
              </ul>
            </div>

            {/* Details of Product */}
            {loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "50px",
                }}
              >
                <ClipLoader />
              </div>
            ) : (
              <div className="productDetail__details">
                <div className="row">
                  <div className="left">
                    <div className="left__cover">
                      <div className="row">
                        <div className="left__img">
                          {MainData?.images?.length === 0 ||
                          MainData?.images === undefined ? (
                            <img src={Images.image.productImg} />
                          ) : (
                            <img
                              src={`${
                                process.env.REACT_APP_IMAGE_BASEURL
                              }${
                                image
                                  ? image
                                  : MainData?.images &&
                                    MainData?.images?.[current]?.fileName
                              }`}
                              alt={MainData.title}
                            />
                          )}
                          <div className="coloredImage">
                            <ul>
                              {MainData?.images?.map((item, index) => (
                                <li
                                  onClick={() =>
                                    handleColorSelect(
                                      index,
                                      item.fileName,
                                      item._id,
                                      item.modelName
                                    )
                                  }
                                >
                                  {item?.code2 ? (
                                    <div className="mainDual">
                                      <div
                                        style={{
                                          backgroundColor: `${
                                            item?.code !== "" ? item.code : ""
                                          }`,
                                        }}
                                        className="colorInner"
                                      ></div>
                                      <div
                                        style={{
                                          backgroundColor: `${
                                            item?.code2 !== "" ? item.code2 : ""
                                          }`,
                                        }}
                                        className="colorInner2"
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className="main">
                                      <div
                                        style={{
                                          border: "1.5px solid gray",

                                          backgroundColor: `${item?.code}`,
                                        }}
                                      ></div>
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="left__text">
                          <div className="row">
                            <div className="left__text__box">
                              <h3>Product Name</h3>
                              <span>
                                {MainData?.name ? MainData?.name : "N/A"}
                              </span>
                            </div>

                            {titleArr?.find((titl) => titl?.id === 1)
                              ?.status === 1 && (
                              <div className="left__text__box">
                                <h3>
                                  {titleArr?.map(
                                    (titl) => titl?.id === 1 && titl?.name
                                  )}
                                </h3>
                                <span>
                                  {titleArr?.find((titl) => titl?.id === 1)
                                    ?.status === 1
                                    ? MainData?.manufacturerId_data?.length > 0
                                      ? MainData?.manufacturerId_data[0]?.title
                                      : "N/A"
                                    : "N/A"}
                                </span>
                              </div>
                            )}

                            <div className="left__text__box">
                              <h3>Model Number</h3>
                              <span>
                                {currentModel ? currentModel : "N/A"}
                                {/* {
                                  titleArr?.find((titl) => titl?.id === 4)?.status === 1 ?
                                  
                                   (MainData?.typeId_data?.length >0 ? 
                                  MainData?.typeId_data[0].title
                                 :"N/A")
                                  : ("N/A")
                                  } */}
                              </span>
                            </div>
                          </div>
                          <div className="description">
                            <h3>Description</h3>
                            <p
                              style={{
                                color: "#202020",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {MainData?.description
                                ? MainData?.description
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="right__cover">
                      <h3>Links</h3>
                      {MainData?.productFiles?.length !== 0 ? (
                        MainData?.productFiles
                          ?.slice(0, 4)
                          ?.map((item, index) => (
                            <div className="download-link">
                              {/* <p>Download</p> */}
                              {
                                <a
                                  href={
                                    item.docFile !== ""
                                      ? process.env.REACT_APP_DOC_BASEURL2+item?.docFile
                                      : item.linkPath
                                  }
                                  download
                                  target={"_blank"}
                                >
                                  {item?.docName}
                                </a>
                              }
                            </div>
                          ))
                      ) : (
                        <p style={{ textAlign: "center" }}>No data found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Related/Information/Resources of Product */}
            <div className="productDetail__tabs">
              <div className="tab">
                <ul>
                  {tabs.map((item) =>
                    item?.id === 2 ? (
                      !MainData?.activationType &&
                      !MainData?.complies &&
                      !MainData?.country &&
                      !MainData?.functionType &&
                      !MainData?.group &&
                      !MainData?.modelGroup &&
                      !MainData?.operation &&
                      !MainData?.productGroup &&
                      !MainData?.productLine ? (
                        ""
                      ) : (
                        <li
                          key={item.id}
                          onClick={() => onTab(item.id)}
                          className={item.id === currentTab ? "active" : ""}
                        >
                          {item.label}
                        </li>
                      )
                    ) : item?.id === 4 ? (
                      MainData?.additional_info && (
                        <li
                          key={item.id}
                          onClick={() => onTab(item.id)}
                          className={item.id === currentTab ? "active" : ""}
                        >
                          {item.label}
                        </li>
                      )
                    ) : (
                      <li
                        key={item.id}
                        onClick={() => onTab(item.id)}
                        className={item.id === currentTab ? "active" : ""}
                      >
                        {item.label}
                      </li>
                    )
                  )}
                </ul>
              </div>
              {console.log("child", MainData?.relatedProducts)}
              <div className="content">
                {currentTab === 1 &&
                  (loader ? (
                    ""
                  ) : (
                    <div className="relatedPro">
                      <div className="row">
                        {MainData?.relatedProducts?.length !== 0 ? (
                          MainData?.relatedProducts?.map((data) => (
                            <div
                              className="productCard"
                              key={data?.manufacturerId}
                            >
                              <ProductCard
                                image={
                                  data?.image
                                    ? `https://spaceplanapi.oursitedemo.com${data?.image}`
                                    : Images.image.productImg
                                }
                                title={data?.name}
                                text={data?.description}
                                titleArr={titleArr}
                                // price={item.price}
                                manuName={data?.manufacturerId_data}
                                modelGroup={data?.modelGroup}
                                status={data?.createdAt}
                                onSelect={() => onSelect(data._id)}
                                goLink={() => onLink(data._id)}
                                color={data.images}
                                getColorId={(id) => getColorId(id)}
                              />
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Data Found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                {currentTab === 2 && (
                  <div className="info">
                    <ul>
                      {/* {MainData?.map((item) => (  */}
                      <>
                        {MainData?.activationType && (
                          <li>
                            <span className="title">{"Activation Type"}:</span>
                            <span className="value">
                              {MainData?.activationType
                                ? MainData?.activationType
                                : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.complies && (
                          <li>
                            <span className="title">{"Complies with"}:</span>
                            <span className="value">
                              {MainData?.complies ? MainData?.complies : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.country && (
                          <li>
                            <span className="title">{"Country"}:</span>
                            <span className="value">
                              {MainData?.country ? MainData?.country : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.functionType && (
                          <li>
                            <span className="title">{"Function Type"}:</span>
                            <span className="value">
                              {MainData?.functionType
                                ? MainData?.functionType
                                : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.group && (
                          <li>
                            <span className="title">{"Group"}:</span>
                            <span className="value">
                              {MainData?.group ? MainData?.group : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.modelGroup && (
                          <li>
                            <span className="title">
                              {"Model Group(Series)"}:
                            </span>
                            <span className="value">
                              {MainData?.modelGroup
                                ? MainData?.modelGroup
                                : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.operation && (
                          <li>
                            <span className="title">{"Operation"}:</span>
                            <span className="value">
                              {MainData?.operation
                                ? MainData?.operation
                                : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.productGroup && (
                          <li>
                            <span className="title">{"Product Group"}:</span>
                            <span className="value">
                              {MainData?.productGroup
                                ? MainData?.productGroup
                                : "N/A"}
                            </span>
                          </li>
                        )}
                        {MainData?.productLine && (
                          <li>
                            <span className="title">{"Product Line"}:</span>
                            <span className="value">
                              {MainData?.productLine
                                ? MainData?.productLine
                                : "N/A"}
                            </span>
                          </li>
                        )}
                      </>
                      {/* ))}  */}
                    </ul>
                  </div>
                )}
                {currentTab === 3 && (
                  <div className="resources">
                    <ul>
                      {MainData?.productFiles?.length !== 0 ? (
                        MainData?.productFiles?.map((item, index) => (
                          <li key={item.id}>
                            <FaFileDownload />{" "}
                            <span>
                              <a
                                href={
                                  item.docFile !== ""
                                    ? process.env.REACT_APP_DOC_BASEURL2+item?.docFile
                                    : item.linkPath
                                }
                                download
                                target={"_blank"}
                              >
                                {item?.docName}
                              </a>
                            </span>
                          </li>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p>No Data Found</p>
                        </div>
                      )}
                    </ul>
                  </div>
                )}

                {currentTab === 4 && (
                  <div className="addtionalInfo">
                    <ul>
                      {MainData?.additional_info &&
                        parse(MainData?.additional_info)}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
      <ContactSaleModal
        click={() => setShowModal(!showModal)}
        show={showModal}
        contact={contactSalesRep}
        loader={loader}
      />
      <ProductToProjectModal
        list={locationList}
        clickProject={handleClose}
        show={modal}
        list1={projectList}
        loader={modalLoader}
        onAddProduct1={onAddProduct1}
        onAddProduct2={onAddProduct2}
      />
    </React.Fragment>
  );
};
export default ProductDetailPage;
