import React, { useEffect, useState } from "react";
import "stylesheets/product.scss";
import { Images } from "constants/Images";
import { productData } from "constants/ProductData";
import ProductCard from "components/ProductCard";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import Button from "components/comman/button";
import { useNavigate } from "react-router-dom";
import { API } from "API/API";
import ClipLoader from "react-spinners/ClipLoader";
import { useSearchContext } from "context/searchContext/searchContext";
import { useAuthContext } from "context/auth/AuthContext";
import { toast } from "react-toastify";
import ButtonSecond from "components/comman/buttonSecond";
import ProductToProjectModal from "components/modal/ProductToProjectModal";
import PlusIcon from "../assets/icons/plusIcon.png";
import productBannerImg from "assets/images/Group 295 (4).svg";
import rectIcon from "assets/images/Rectangle 80.svg";
import { keyframes } from "styled-components";

const filterList = [
  {
    id: 1,
    type: "manufacturer",
    title: "Manufacturer",
    level: 3,
    showChild: false,
  },
  {
    id: 6,
    type: "buildingtype",
    title: "Building Type",
    level: 1,
    showChild: false,
  },

  {
    id: 4,
    type: "producttype",
    title: "Product Type",
    level: 1,
    showChild: false,
  },
  {
    id: 3,
    type: "productcategory",
    title: "Product Category",
    level: 1,
    showChild: false,
  },
  {
    id: 2,
    type: "projecttemplates",
    title: "Segment",
    level: 1,
    showChild: false,
  },
  {
    id: 5,
    type: "accessiblecode",
    title: "Accessible & Code",
    level: 1,
    showChild: false,
  },

  {
    id: 7,
    type: "scheduletype",
    title: "Schedule Type",
    level: 1,
    showChild: false,
  },
];

const ProductPage = () => {
  const [loadMore, setLoadMore] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [maxProduct, setMaxProduct] = useState(0);
  const [totalProductList, setTotalProductList] = useState([]);
  const [sampleProductList, setSampleProductList] = useState([]);
  const [multipleProduct, setMultipleProduct] = useState(false);
  const [noOfRecord, setNoOfRecord] = useState(24);
  const [page, setPage] = useState(1);
  const [UniqueLocation, setUniqueLocation] = useState("");
  const [isSelected, setIsSelected] = useState();
  const [CcheckBox, setCcheckBox] = useState(0);
  const { token, login } = useAuthContext();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(filterList);
  const [ArrayOfFilter, setArrayOfFilter] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [selectSort, setSelectSort] = useState("");
  const [currentChild, setCurrentChild] = useState(null);
  const [currentSubChild, setCurrentSubChild] = useState(null);
  const [currentSubCatChild, setCurrentSubCatChild] = useState(null);
  const { seachField } = useSearchContext();
  const [modifiedFilterList, setModifiedFilterList] = useState([...filterList]);
  const [data, setData] = useState(productData);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [projectList, setProjectList] = useState();
  const [titleLoader, setTitleLoader] = useState();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("black");
  const [datas, setDatas] = useState([]);
  const [datasForSearch, setDatasForSearch] = useState([]);
  const [listing, setListing] = useState([]);
  const [locationList, setLocationList] = useState();
  const [productId, setProductId] = useState();
  const [modiFiedList, setmodiFiedList] = useState([]);
  const [manufaturerTable, setManufacturerTable] = useState({
    data: {
      type: "manufacture",
      title: "Manufacture",
      showChild: false,
    },
    list: [],
  });
  const [FilterArr, setFilterArr] = useState([]);
  const [colorID, setColorID] = useState("");

  const [titleArr, setTitleArr] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);

  const [filterParams, setFilterParams] = useState({
    manufacturer: {
      manufacturerId: [],
      manufacturer2Id: [],
      manufacturer3Id: [],
      manufacturer4Id: [],
    },
    building: {
      buildingTypeId: [],
    },
    type: {
      typeId: [],
      type2Id: [],
    },
    category: {
      categoryId: [],
      category2Id: [],
      category3Id: [],
      category4Id: [],
    },
    accessible: {
      accessibleComplaintId: [],
    },
    schedule: {
      scheduleTypeId: [],
    },
    curated: {
      curatedSelectionId: [],
      curatedSelection2Id: [],
      curatedSelection3Id: [],
      curatedSelection4Id: [],
    },
  });

  const onSelect = (id) => {
    if (!login && token === null) {
      toast.error("You must login first.");
    } else {
      setProductId(id);
      setModal(true);
    }
  };

  const onCategoryClick = (id, index) => {
    let result = modifiedFilterList.map((me) => {
      if (me.id === id) {
        return {
          ...me,
          showChild: !me.showChild,
        };
      } else {
        return me;
      }
    });
    console.log("res", result);
    setModifiedFilterList([...result]);
  };
  const onCreareProject = () => {
    // setModalRowData({})
    setModal(!modal);
  };
  const onToggleFilter = () => {
    setMobileFilter(!mobileFilter);
  };
  const onLink = (item) => {
    navigate(`/product-detail/${item._id}`);
  };

  useEffect(() => {
    getListing();
    productFliter(filterParams, selectSort, 24, 1);
  }, []);

  useEffect(() => {
    if (isDataLoaded) setPage(1);
    productFliter(filterParams, selectSort, 24, 1);
  }, [seachField]);

  useEffect(() => {
    if (titleArr.length > 0) {
      getHierarchyListing();
    }
  }, [titleArr]);

  useEffect(() => {
    if (token && token !== null) getLocationList();

    if (token) GetTableData();
  }, [token]);

  const findProducts = async (sortBy) => {
    try {
      let response = await API.fetchSearchProducts(token);
      console.log({ response });
      if (response?.success) {
        setDatas([...response.data]);
      } else {
        toast.error(response?.error);
      }
    } catch (error) {
      return error;
    }
  };

  const productFliter = async (params, sortdsc, record, pages) => {
    // if(!isDataLoaded)
    setLoading(true);

    setIsDataLoaded(true);
    let dataMain = {
      noOfRecord: 24,
      sort: sortdsc || selectSort,
      // search: '',
      search: seachField,
      prm: params || filterParams,
      pages: pages,
      key: "createdAt",
    };

    try {
      let response = await API.productFilter(dataMain, token);
      if (pages === 1) {
        setDatas(() => {
          setLoading(false);
          return [...response.data];
        });
      } else {
        setDatas(() => {
          setLoading(false);
          return [...datas, ...response.data];
        });
      }

      setMaxProduct(response?.totalCount);
    } catch (error) {
      return error;
    } finally {
      setLoadMore(false);
      setLoading(false);
    }
  };

  const selectSortFilter = (e) => {
    setSelectSort(e.target.value);
    productFliter(filterParams, e.target.value, 24, page);
  };

  const mapListingData = (data) => {
    const keys = Object.keys(data);
    const filterList = [];
    keys.forEach((key) => {
      const found = modifiedFilterList.find((mf) => mf.id === Number(key));
      if (found) filterList.push({ ...found, parentList: data[key] });
    });

    // function customSort(a, b) {
    //     console.log('bkc', a, b);
    //     if (a?.level === b?.level) {
    //         return a.id - b.id;
    //     }
    //     return a.level - b.level;
    // }

    // filterList.sort((a, b) => {
    //     const indexA = titleArr.findIndex(item => item.id === a.id);
    //     const indexB = titleArr.findIndex(item => item.id === b.id);
    //     return customSort(titleArr[indexA], titleArr[indexB]);
    // });

    const sortedFilterListById = [];
    console.log("third", titleArr);
    titleArr?.forEach((item) => {
      const found = filterList.find((obj) => obj.id === item.id);
      if (found) sortedFilterListById.push(found);
    });

    console.log("Sorted Filter List:", sortedFilterListById);

    console.log("first", filterList);
    console.log("second", titleArr);
    setModifiedFilterList([...sortedFilterListById]);
  };

  const getHierarchyListing = async () => {
    setLoading(true);
    try {
      let response1 = await API.ManufacturerListing();
      let response2 = await API.curatedSectionListing();
      let response3 = await API.ProductCategoryListing();
      let response4 = await API.ProductTypeListing();
      let response5 = await API.complaintsListing();
      let response6 = await API.BuildingTypeListing();
      let response7 = await API.scheduleTypeListing();
      const data = {
        1: response1.data,
        5: response5.data,
        3: response3.data,
        4: response4.data,
        2: response2.data,
        6: response6.data,
        7: response7.data,
      };
      mapListingData(data);
    } catch (error) {
      console.log("err", error.message);
      toast.error("Network Error");
    } finally {
      setTitleLoader(false);
    }
  };

  const getListing = async () => {
    console.log("from function");

    setTitleLoader(true);
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        setTitleArr(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    } finally {
      // setTitleLoader(false)
    }
  };

  console.log({ filterParams });

  const checkedFilterList = (e, item, type, level) => {
    var params1 = {};
    let Ids = {};
    switch (true) {
      case type === "manufacturer" && level === "first":
        params1 = filterParams;
        Ids = params1.manufacturer.manufacturerId;

        if (Ids.includes(item._id)) {
          params1.manufacturer.manufacturerId = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }

        setFilterParams(params1);

        break;
      case type === "manufacturer" && level === "second":
        params1 = filterParams;

        Ids = params1.manufacturer.manufacturer2Id;
        if (Ids.includes(item._id)) {
          params1.manufacturer.manufacturer2Id = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;
      case type === "manufacturer" && level === "third":
        params1 = filterParams;

        Ids = params1.manufacturer.manufacturer3Id;
        if (Ids.includes(item._id)) {
          params1.manufacturer.manufacturer3Id = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "manufacturer" && level === "four":
        params1 = filterParams;

        Ids = params1.manufacturer.manufacturer4Id;
        if (Ids.includes(item._id)) {
          params1.manufacturer.manufacturer4Id = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "accessiblecode" && level === "first":
        params1 = filterParams;
        Ids = params1.accessible.accessibleComplaintId;
        if (Ids.includes(item._id)) {
          params1.accessible.accessibleComplaintId = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "buildingtype" && level === "first":
        params1 = filterParams;
        Ids = params1.building.buildingTypeId;
        if (Ids.includes(item._id)) {
          params1.building.buildingTypeId = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "producttype" && level === "first":
        params1 = filterParams;
        Ids = params1.type.typeId;
        if (Ids.includes(item._id)) {
          params1.type.typeId = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;
      case type === "producttype" && level === "second":
        params1 = filterParams;
        Ids = params1.type.type2Id;

        if (Ids.includes(item._id)) {
          params1.type.type2Id = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "productcategory" && level === "first":
        params1 = filterParams;
        Ids = params1.category.categoryId;
        if (Ids.includes(item._id)) {
          params1.category.categoryId = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;
      case type === "productcategory" && level === "second":
        params1 = filterParams;
        Ids = params1.category.category2Id;

        if (Ids.includes(item._id)) {
          params1.category.category2Id = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "productcategory" && level === "third":
        params1 = filterParams;
        Ids = params1.category.category3Id;

        if (Ids.includes(item._id)) {
          params1.category.category3Id = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "productcategory" && level === "four":
        params1 = filterParams;
        Ids = params1.category.category4Id;

        if (Ids.includes(item._id)) {
          params1.category.category4Id = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "scheduletype" && level === "first":
        params1 = filterParams;
        Ids = params1.schedule.scheduleTypeId;
        if (Ids.includes(item._id)) {
          params1.schedule.scheduleTypeId = Ids.filter((me) => me !== item._id);
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "accessiblecode" && level === "first":
        params1 = filterParams;

        if (params1.accessibleComplaintId.includes(item._id)) {
          params1.accessibleComplaintId = params1.accessibleComplaintId.filter(
            (me) => me !== item._id
          );
        } else {
          params1.accessibleComplaintId.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "projecttemplates" && level === "first":
        params1 = filterParams;
        Ids = params1.curated.curatedSelectionId;
        if (Ids.includes(item._id)) {
          params1.curated.curatedSelectionId = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);

        break;
      case type === "projecttemplates" && level === "second":
        params1 = filterParams;
        Ids = params1.curated.curatedSelection2Id;

        if (Ids.includes(item._id)) {
          params1.curated.curatedSelection2Id = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;
      case type === "projecttemplates" && level === "third":
        params1 = filterParams;
        Ids = params1.curated.curatedSelection3Id;

        if (Ids.includes(item._id)) {
          params1.curated.curatedSelection3Id = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;

      case type === "projecttemplates" && level === "four":
        params1 = filterParams;
        Ids = params1.curated.curatedSelection4Id;

        if (Ids.includes(item._id)) {
          params1.curated.curatedSelection4Id = Ids.filter(
            (me) => me !== item._id
          );
        } else {
          Ids.push(item._id);
        }
        setFilterParams(params1);
        break;
    }
    let keyResult = {
      type: type,
      level: level,
      id: item._id,
      name: item.title,
    };
    if (e.target.checked === true && !FilterArr.includes(item)) {
      setFilterArr([...FilterArr, keyResult]);
    } else {
      let result = FilterArr.filter((items) => {
        return items.id !== item._id;
      });
      setFilterArr(result);
    }
    productFliter(params1, selectSort, 24, page);
  };

  const GetTableData = async (value) => {
    try {
      let response = await API.GetProjects(value, token);
      setProjectList([...response.data]);
    } catch (error) {
      return error;
    }
  };

  const deleteFilterList = (data) => {
    let Ids = {};
    var params1 = filterParams;
    switch (true) {
      case data.type === "manufacturer" && data.level === "first":
        params1 = filterParams;
        Ids = params1.manufacturer.manufacturerId;
        if (Ids.includes(data.id)) {
          params1.manufacturer.manufacturerId =
            params1.manufacturer.manufacturerId.filter((me) => me !== data.id);
        }
        setFilterParams(params1);

        break;
      case data.type === "manufacturer" && data.level === "second":
        params1 = filterParams;
        Ids = params1.manufacturer.manufacturer2Id;
        if (Ids.includes(data.id)) {
          params1.manufacturer.manufacturer2Id =
            params1.manufacturer.manufacturer2Id.filter((me) => me !== data.id);
        }

        setFilterParams(params1);
        break;
      case data.type === "manufacturer" && data.level === "third":
        params1 = filterParams;
        Ids = params1.manufacturer.manufacturer3Id;

        if (Ids.includes(data.id)) {
          params1.manufacturer.manufacturer3Id =
            params1.manufacturer.manufacturer3Id.filter((me) => me !== data.id);
        }

        setFilterParams(params1);
        break;
      case data.type === "manufacturer" && data.level === "four":
        params1 = filterParams;
        Ids = params1.manufacturer.manufacturer4Id;

        if (Ids.includes(data.id)) {
          params1.manufacturer.manufacturer4Id =
            params1.manufacturer.manufacturer4Id.filter((me) => me !== data.id);
        }

        setFilterParams(params1);
        break;
      case data.type === "accessiblecode" && data.level === "first":
        params1 = filterParams;
        Ids = params1.accessible.accessibleComplaintId;
        if (Ids.includes(data.id)) {
          params1.accessible.accessibleComplaintId =
            params1.accessible.accessibleComplaintId.filter(
              (me) => me !== data.id
            );
        }

        setFilterParams(params1);
        break;
      case data.type === "buildingtype" && data.level === "first":
        params1 = filterParams;
        Ids = params1.building.buildingTypeId;
        if (Ids.includes(data.id)) {
          params1.building.buildingTypeId =
            params1.building.buildingTypeId.filter((me) => me !== data.id);
        }

        setFilterParams(params1);
        break;
      case data.type === "producttype" && data.level === "first":
        params1 = filterParams;
        Ids = params1.type.typeId;
        if (Ids.includes(data.id)) {
          params1.type.typeId = params1.type.typeId.filter(
            (me) => me !== data.id
          );
        }

        setFilterParams(params1);
        break;
      case data.type === "producttype" && data.level === "second":
        params1 = filterParams;
        Ids = params1.type.type2Id;

        if (Ids.includes(data.id)) {
          params1.type.type2Id = params1.type.type2Id.filter(
            (me) => me !== data.id
          );
        }

        setFilterParams(params1);
        break;
      case data.type === "productcategory" && data.level === "first":
        params1 = filterParams;
        Ids = params1.category.categoryId;
        if (Ids.includes(data.id)) {
          params1.category.categoryId = params1.category.categoryId.filter(
            (me) => me !== data.id
          );
        }

        setFilterParams(params1);
        break;
      case data.type === "productcategory" && data.level === "second":
        params1 = filterParams;
        Ids = params1.category.category2Id;
        if (Ids.includes(data.id)) {
          params1.category.category2Id = params1.category.category2Id.filter(
            (me) => me !== data.id
          );
        }

        setFilterParams(params1);

        break;
      case data.type === "productcategory" && data.level === "third":
        params1 = filterParams;
        Ids = params1.category.category3Id;
        if (Ids.includes(data.id)) {
          params1.category.category3Id = params1.category.category3Id.filter(
            (me) => me !== data.id
          );
        }

        setFilterParams(params1);

        break;
      case data.type === "productcategory" && data.level === "four":
        params1 = filterParams;
        Ids = params1.category.category4Id;
        if (Ids.includes(data.id)) {
          params1.category.category4Id = params1.category.category4Id.filter(
            (me) => me !== data.id
          );
        }

        setFilterParams(params1);

        break;
      case data.type === "scheduletype" && data.level === "first":
        params1 = filterParams;
        Ids = params1.schedule.scheduleTypeId;
        if (Ids.includes(data.id)) {
          params1.schedule.scheduleTypeId =
            params1.schedule.scheduleTypeId.filter((me) => me !== data.id);
        }

        setFilterParams(params1);
        break;

      case data.type === "accessiblecode" && data.level === "first":
        params1 = filterParams;
        Ids = params1.accessible.accessibleComplaintId;
        if (Ids.includes(data.id)) {
          params1.accessible.accessibleComplaintId =
            params1.accessible.accessibleComplaintId.filter(
              (me) => me !== data.id
            );
        }

        setFilterParams(params1);
        break;

      case data.type === "projecttemplates" && data.level === "first":
        params1 = filterParams;
        Ids = params1.curated.curatedSelectionId;
        if (Ids.includes(data.id)) {
          params1.curated.curatedSelectionId =
            params1.curated.curatedSelectionId.filter((me) => me !== data.id);
        }

        setFilterParams(params1);

        break;
      case data.type === "projecttemplates" && data.level === "second":
        params1 = filterParams;
        Ids = params1.curated.curatedSelection2Id;
        if (Ids.includes(data.id)) {
          params1.curated.curatedSelection2Id =
            params1.curated.curatedSelection2Id.filter((me) => me !== data.id);
        }

        setFilterParams(params1);
        break;
      case data.type === "projecttemplates" && data.level === "third":
        params1 = filterParams;
        Ids = params1.curated.curatedSelection3Id;

        if (Ids.includes(data.id)) {
          params1.curated.curatedSelection3Id =
            params1.curated.curatedSelection3Id.filter((me) => me !== data.id);
        }
        setFilterParams(params1);
        break;
      case data.type === "projecttemplates" && data.level === "four":
        params1 = filterParams;
        Ids = params1.curated.curatedSelection4Id;

        if (Ids.includes(data.id)) {
          params1.curated.curatedSelection4Id =
            params1.curated.curatedSelection4Id.filter((me) => me !== data.id);
        }
        setFilterParams(params1);
        break;
    }
    let result = FilterArr.filter((item) => {
      return item.id !== data.id;
    });
    setFilterArr(result);
    productFliter(params1, selectSort, 24, page);
  };
  const onChildClick = (id) => {
    if (id !== currentChild) setCurrentChild(id);
    else setCurrentChild(null);
  };

  const getLocationList = async () => {
    try {
      let response = await API.getLocationList(token);
      setLocationList(response.data);
    } catch (error) {
      return error;
    }
  };

  const onSubChildClick = (id) => {
    if (id !== currentSubChild) setCurrentSubChild(id);
    else setCurrentSubChild(null);
  };

  const onSubCatChildClick = (id) => {
    if (id !== currentSubCatChild) setCurrentSubCatChild(id);
    else setCurrentSubCatChild(null);
  };

  const handleOnSelect = (id, clrId) => {
    if (!login && token === null) {
      toast.error("You must login first");
    } else {
      let param = { product: id, location: UniqueLocation, color: clrId };
      if (totalProductList.find((x) => x.product === id) !== undefined) {
        let result = totalProductList.filter((uId) => {
          return id !== uId.product;
        });
        setTotalProductList([...result]);

        let result2 = sampleProductList.filter((uId) => {
          return id !== uId;
        });
        setSampleProductList([...result2]);
      } else {
        setTotalProductList([...totalProductList, param]);
        setSampleProductList([...sampleProductList, id]);
      }
    }
  };

  const onAddProduct1 = async (location, project) => {
    setModalLoader(true);
    let params;
    try {
      if (multipleProduct) {
        setUniqueLocation(location);
        let result = totalProductList.map((prod) => {
          prod.location = location;
          return prod;
        });
        params = { products: result };
      } else {
        params = {
          products: [
            { product: productId, location: location, color: colorID },
          ],
        };
      }
      let response = await API.addproducttoproject(params, token, project);
      if (response.success) {
        // getProjectData()
        setModal(false);
        toast.success(response.message);
        setTotalProductList([]);
        setSampleProductList([]);
        setIsSelected(false);
        // productFliter()
        setMultipleProduct(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setModalLoader(false);
    }
  };

  const onAddProduct2 = async (location, name, zipCode) => {
    setModalLoader(true);
    try {
      let params = {
        name: name.toLowerCase(),
        // zipCode: zipCode
      };
      let response = await API.AddProjects(params, token);
      if (response.success) {
        onAddProduct1(location, response.data._id);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const clearAll = () => {
    let prm = {
      manufacturer: {
        manufacturerId: [],
        manufacturer2Id: [],
        manufacturer3Id: [],
        manufacturer4Id: [],
      },
      building: {
        buildingTypeId: [],
      },
      type: {
        typeId: [],
        type2Id: [],
      },
      category: {
        categoryId: [],
        category2Id: [],
        category3Id: [],
        category4Id: [],
      },
      accessible: {
        accessibleComplaintId: [],
      },
      schedule: {
        scheduleTypeId: [],
      },
      curated: {
        curatedSelectionId: [],
        curatedSelection2Id: [],
        curatedSelection3Id: [],
        curatedSelection4Id: [],
      },
    };
    setFilterParams(prm);
    productFliter(prm, selectSort, 24, page);
    setSelectSort("");
    setFilterArr([]);
  };

  const handleClose = () => {
    setModal(false);
  };

  const isChecked = (item, type, level) => {
    switch (true) {
      case type === "manufacturer" && level === "first":
        return filterParams.manufacturer.manufacturerId.includes(item._id);
      case type === "manufacturer" && level === "second":
        return filterParams.manufacturer.manufacturer2Id.includes(item._id);
      case type === "manufacturer" && level === "third":
        return filterParams.manufacturer.manufacturer3Id.includes(item._id);
      case type === "manufacturer" && level === "four":
        return filterParams.manufacturer.manufacturer4Id.includes(item._id);

      case type === "buildingtype" && level === "first":
        return filterParams.building.buildingTypeId.includes(item._id);

      case type === "producttype" && level === "first":
        return filterParams.type.typeId.includes(item._id);
      case type === "producttype" && level === "second":
        return filterParams.type.type2Id.includes(item._id);

      case type === "productcategory" && level === "first":
        return filterParams.category.categoryId.includes(item._id);
      case type === "productcategory" && level === "second":
        return filterParams.category.category2Id.includes(item._id);
      case type === "productcategory" && level === "third":
        return filterParams.category.category3Id.includes(item._id);
      case type === "productcategory" && level === "four":
        return filterParams.category.category4Id.includes(item._id);

      case type === "accessiblecode" && level === "first":
        return filterParams.accessible.accessibleComplaintId.includes(item._id);
      case type === "scheduletype" && level === "first":
        return filterParams.schedule.scheduleTypeId.includes(item._id);

      case type === "projecttemplates" && level === "first":
        return filterParams.curated.curatedSelectionId.includes(item._id);
      case type === "projecttemplates" && level === "second":
        return filterParams.curated.curatedSelection2Id.includes(item._id);
      case type === "projecttemplates" && level === "third":
        return filterParams.curated.curatedSelection3Id.includes(item._id);
      case type === "projecttemplates" && level === "four":
        return filterParams.curated.curatedSelection4Id.includes(item._id);
    }
  };

  const multipleProductAdd = () => {
    setMultipleProduct(true);
    setModal(true);
  };

  const getColorId = (id) => {
    setColorID(id);
  };

  const getMoreData = () => {
    setLoadMore(true);

    setTimeout(function () {
      // Your code to be executed after 3 seconds
      setNoOfRecord(noOfRecord + 24);
      setPage(page + 1);
    }, 2500);

    productFliter(filterParams, selectSort, noOfRecord + 24, page + 1);
  };

  // console.log('Modi', modifiedFilterList);
  // console.log('titlearr', titleArr);

  return (
    <React.Fragment>
      {/* Banner */}
      <section className="productbanner">
        <img src={productBannerImg} alt="" />
        {/* <img src={Images.image.mountains} alt='' /> */}
        <div className="overlay">
          <div className="container">
            <img className="rectIcon" src={rectIcon} alt="" />
            <div className="homepageHeading">
              <h1>CREATIVE</h1>
              <h1>PROJECTS</h1>
              {/* <h1>CREATIVE <br /> PROJECTS</h1> */}
            </div>
          </div>
        </div>
      </section>

      {/* Product List */}
      <section className="products">
        <div className="container">
          <div className="row">
            <div className={`products__filter ${mobileFilter ? "show" : ""}`}>
              <div className="mobileTitle">
                <h4>Filter</h4>
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#FF0000",
                      padding: "4px",
                      borderRadius: "5px",
                      lineHeight: "0px",
                    }}
                  >
                    <ButtonSecond
                      type="primaryyy"
                      click={onToggleFilter}
                      value="Apply"
                    />
                  </div>
                  <button
                    type="button"
                    className="sortBy__filter"
                    onClick={onToggleFilter}
                  >
                    <img src={Images.icon.closeCircle} alt="close" />
                  </button>
                </div>
              </div>

              {titleLoader ? (
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <ClipLoader />
                </div>
              ) : (
                <ul style={{ marginBottom: "10px" }}>
                  {modifiedFilterList?.map(
                    (data, index) =>
                      titleArr?.find((titl) => titl?.id === data.id)?.status ===
                        1 && (
                        <li key={data.id}>
                          <button
                            onClick={() => onCategoryClick(data.id, index)}
                          >
                            {
                              titleArr?.find((titl) => titl?.id === data.id)
                                ?.name
                            }
                            <span className="caret">
                              {data._id === data.showChild ? (
                                <AiOutlineCaretUp />
                              ) : (
                                <AiOutlineCaretDown />
                              )}
                            </span>
                          </button>
                          {data.showChild && (
                            <ul>
                              {data &&
                                data?.parentList &&
                                data?.parentList?.length > 0 &&
                                data?.parentList?.map(
                                  (parent, index) =>
                                    parent.status === 1 && (
                                      <li key={parent._id}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <input
                                            disabled={loading}
                                            type={"checkbox"}
                                            checked={isChecked(
                                              parent,
                                              data.type,
                                              "first"
                                            )}
                                            onChange={(e) =>
                                              checkedFilterList(
                                                e,
                                                parent,
                                                data.type,
                                                "first"
                                              )
                                            }
                                          />

                                          <button
                                            onClick={() =>
                                              onChildClick(parent?._id)
                                            }
                                          >
                                            {parent.title}
                                            {parent.child.length !== 0 && (
                                              <span className="caret">
                                                {parent._id === currentChild ? (
                                                  <AiOutlineCaretUp />
                                                ) : (
                                                  <AiOutlineCaretDown />
                                                )}
                                              </span>
                                            )}
                                          </button>
                                        </div>
                                        {parent._id === currentChild && (
                                          <ul>
                                            {parent.child.map(
                                              (subChild, index) =>
                                                subChild.status === 1 && (
                                                  <li key={subChild._id}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                      }}
                                                    >
                                                      <input
                                                        disabled={loading}
                                                        type={"checkbox"}
                                                        checked={isChecked(
                                                          subChild,
                                                          data.type,
                                                          "second"
                                                        )}
                                                        onChange={(e) =>
                                                          checkedFilterList(
                                                            e,
                                                            subChild,
                                                            data.type,
                                                            "second"
                                                          )
                                                        }
                                                      />
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          onSubChildClick(
                                                            subChild._id
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          {subChild.title}
                                                        </span>
                                                        {subChild?.child
                                                          ?.length !== 0 && (
                                                          <span className="caret">
                                                            {subChild._id ===
                                                            currentSubChild ? (
                                                              <AiOutlineCaretUp />
                                                            ) : (
                                                              <AiOutlineCaretDown />
                                                            )}
                                                          </span>
                                                        )}
                                                      </button>
                                                    </div>
                                                    {subChild._id ===
                                                      currentSubChild && (
                                                      <ul>
                                                        {subChild.child.map(
                                                          (x, index) =>
                                                            x.status === 1 && (
                                                              <li key={x._id}>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    flexDirection:
                                                                      "row",
                                                                  }}
                                                                >
                                                                  <input
                                                                    disabled={
                                                                      loading
                                                                    }
                                                                    type={
                                                                      "checkbox"
                                                                    }
                                                                    checked={isChecked(
                                                                      x,
                                                                      data.type,
                                                                      "third"
                                                                    )}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      checkedFilterList(
                                                                        e,
                                                                        x,
                                                                        data.type,
                                                                        "third"
                                                                      )
                                                                    }
                                                                  />
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      onSubCatChildClick(
                                                                        x?._id
                                                                      )
                                                                    }
                                                                  >
                                                                    <span>
                                                                      {x.title}
                                                                    </span>
                                                                    {x?.child
                                                                      ?.length !==
                                                                      0 && (
                                                                      <span className="caret">
                                                                        {x._id ===
                                                                        currentSubChild ? (
                                                                          <AiOutlineCaretUp />
                                                                        ) : (
                                                                          <AiOutlineCaretDown />
                                                                        )}
                                                                      </span>
                                                                    )}
                                                                  </button>
                                                                </div>
                                                                {x._id ===
                                                                  currentSubCatChild && (
                                                                  <ul>
                                                                    {x?.child?.map(
                                                                      (
                                                                        is,
                                                                        index
                                                                      ) => (
                                                                        <li
                                                                          key={
                                                                            is._id
                                                                          }
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              flexDirection:
                                                                                "row",
                                                                            }}
                                                                          >
                                                                            <input
                                                                              disabled={
                                                                                loading
                                                                              }
                                                                              type={
                                                                                "checkbox"
                                                                              }
                                                                              checked={isChecked(
                                                                                is,
                                                                                data.type,
                                                                                "four"
                                                                              )}
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                checkedFilterList(
                                                                                  e,
                                                                                  is,
                                                                                  data.type,
                                                                                  "four"
                                                                                )
                                                                              }
                                                                            />
                                                                            <button type="button">
                                                                              {
                                                                                is.title
                                                                              }
                                                                            </button>
                                                                          </div>
                                                                        </li>
                                                                      )
                                                                    )}
                                                                  </ul>
                                                                )}
                                                              </li>
                                                            )
                                                        )}
                                                      </ul>
                                                    )}
                                                  </li>
                                                )
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    )
                                )}
                            </ul>
                          )}
                        </li>
                      )
                  )}
                </ul>
              )}
            </div>

            <div className="products__list">
              <div className="sortBy">
                <Button
                  value="Filter"
                  type="primary sortBy__filter"
                  click={onToggleFilter}
                />

                <div className="selectCus">
                  <div className="form-control">
                    <span>Sort By:</span>
                    <select
                      value={selectSort}
                      onChange={(e) => selectSortFilter(e)}
                    >
                      <option value={""}>Oldest First</option>
                      <option value={"-"}>Newest First</option>
                    </select>
                  </div>
                </div>
              </div>
              {totalProductList.length !== 0 && (
                <div className="noOfProduct">
                  <p>{totalProductList.length + " " + "Selected"}</p>
                  <Button
                    type="Products"
                    click={() => multipleProductAdd()}
                    value={"Add to project"}
                  />
                </div>
              )}
              <div className="sortBy__tags">
                <ul>
                  {FilterArr.map((data) => (
                    <li>
                      <span className="tag">
                        {data.name}
                        <img
                          src={Images.icon.closeCircle}
                          alt="close"
                          onClick={() => deleteFilterList(data)}
                        />
                      </span>
                    </li>
                  ))}
                  <li>
                    {FilterArr.length !== 0 && (
                      <span onClick={() => clearAll()} className="clear">
                        Clear All
                      </span>
                    )}
                  </li>
                </ul>
              </div>

              {!loading ? (
                <div className="row">
                  {datas && datas?.length > 0 ? (
                    datas?.map((item, index) => (
                      <div className="productCard" key={item._id}>
                        <ProductCard
                          // image={item.image === "" || item.image === undefined
                          //     ? Images.image.productImg :
                          //     `https://spaceplanapi.oursitedemo.com/${item.image}`}
                          title={item.name}
                          text={item.description}
                          price={item.createdAt}
                          titleArr={titleArr}
                          status={item.status}
                          manuName={item?.manufacturerId_data}
                          modelGroup={item?.modelGroup}
                          id={item._id}
                          onSelect={() => onSelect(item._id)}
                          goLink={() => onLink(item)}
                          onSelectClick={(clrId) =>
                            handleOnSelect(item._id, clrId)
                          }
                          item={item}
                          color={item?.images}
                          isChecked={
                            sampleProductList.includes(item._id) ? true : false
                          }
                          btn={
                            sampleProductList.includes(item._id) ? true : false
                          }
                          getColorId={(id) => getColorId(id)}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <p> No data found</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="loaderClip">
                  <ClipLoader color={color} loading={loading} size={40} />
                </div>
              )}
              {datas?.length > 0 && maxProduct > noOfRecord && (
                <div className="addMoreButtonDiv">
                  {
                    <button
                      disabled={loadMore}
                      onClick={() => getMoreData()}
                      className="addMoreButton"
                    >
                      {loadMore ? (
                        <div className="loader">
                          <ClipLoader
                            color={color}
                            loading={loadMore}
                            size={25}
                          />
                        </div>
                      ) : (
                        <div className="viewMoreLus">
                          VIEW 24 MORE
                          <img src={PlusIcon} />
                        </div>
                      )}
                    </button>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ProductToProjectModal
        list={locationList}
        clickProject={handleClose}
        show={modal}
        list1={projectList}
        loader={modalLoader}
        checked={CcheckBox}
        onAddProduct1={onAddProduct1}
        onAddProduct2={onAddProduct2}
      />
    </React.Fragment>
  );
};
export default ProductPage;
