export const Images = {
    icon: {
        closeCircle: require('assets/icons/close-circle.png'),
        closeCircle1: require('assets/icons/close-circle1.png'),
        user: require('assets/icons/user.png'),
        edit: require('assets/icons/edit.png'),
        files: require('assets/icons/files.png'),
        delete: require('assets/icons/delete.png'),
        calendar: require('assets/icons/calendar.svg'),
        search: require('assets/icons/search.png'),
        cross: require('assets/icons/cross.svg')
    },
    image: {
        logo: require('assets/images/logo.png'),
        bannerImg1: require('assets/images/banner-img1.png'),
        bannerImg2: require('assets/images/banner-img2.png'),
        serviceImg1: require('assets/images/service-img1.png'),
        serviceImg2: require('assets/images/service-img2.png'),
        serviceImg3: require('assets/images/service-img3.png'),
        productBanner: require('assets/images/product-banner.jpg'),
        productImg: require('assets/images/product-img.jpg'),
        loginImg: require('assets/images/login-img.jpg'),
        mountains: require('assets/images/mountain.jpg'),
        productBannerImg: require('assets/images/Group 295 (3).svg'),
        homePageBanner: require('assets/images/Group 295.svg')
    }
}