import React from "react";
import one from '../constants/imagesC/1.png';
import two from '../constants/imagesC/2.png';
import three from '../constants/imagesC/3.png';
import four from '../constants/imagesC/4.png';
import five from '../constants/imagesC/5.png';
import six from '../constants/imagesC/6.png';
import seven from '../constants/imagesC/7.png';
import eight from '../constants/imagesC/8.png';
import nine from '../constants/imagesC/9.png';
import ten from '../constants/imagesC/10.png';
import eleven from '../constants/imagesC/11.png';
import twelve from '../constants/imagesC/12.png';
import thirtn from '../constants/imagesC/13.png';
import frtn from '../constants/imagesC/14.png';
import fftn from '../constants/imagesC/15.png';
import  '../stylesheets/comman/privacypolicy.scss';

const img = [one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirtn, frtn, fftn]

const PrivacyPolicy = () => {
    return (
        <section>
            <div className="container" style={{ paddingTop: '20px', paddingBottom: '100px' }}>
                <div style={{ 'paddingTop': '10px' }}>
                    <h1 style={{ 'fontWeight': '600', 'fontSize': '36px',paddingLeft:'20px' }}>Privacy Policy</h1>
                </div>
                
                <div className="textarea">
                     <p className="textarea__text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an an  anunknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of an Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum an an an
                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum an an an passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum an an an an an an
                     </p>
                </div>

            </div>
        </section>
    )
}

export default PrivacyPolicy;