import Button from "components/comman/button";
import React, { useEffect, useState } from "react";
import '../../stylesheets/brochureProduct.scss'
import { Images } from "constants/Images";
import { GiHamburgerMenu } from "react-icons/gi";
import { API } from "API/API";
import { useParams } from "react-router-dom";
import { useAuthContext } from "context/auth/AuthContext";
import ModalC from "components/comman/ModalC";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ClipLoader } from "react-spinners";
import { useSearchContext } from "context/searchContext/searchContext";



const SetBrochureProduct = (props) => {

     const [ProjectData, setProjectData] = useState()
     const { token } = useAuthContext();
     const [loader, setLoader] = useState(false)
     const [Nodata, setNodata] = useState()
     const [show, setShow] = useState(false)
     const [deleteLoader, setDeleteLoader] = useState(false)
     const [modalId, setModalId] = useState("")
     const [Items, setItems] = useState([])
     const [orderList, setOrderList] = useState([])
     const [IndexLoader, setIndexLoader] = useState(false)
     const { mapBrochureOrderList } = useSearchContext()





     let arr = [
          { n: 1, image: Images.image.productImg, title: '1213' },
          { n: 2, image: Images.image.productImg, title: '1213' },
          { n: 3, image: Images.image.productImg, title: '1213' },
          { n: 4, image: Images.image.productImg, title: '1213' },
     ]

     useEffect(() => {
          GetProjectDetails()
     }, [token])

     const params = useParams()

     const GetProjectDetails = async () => {
          setLoader(true)
          try {
               let response = await API.GetProjectsById(params.id, token)
               if (response.success) {
                    if (response.data.products[0]?.location === null && response.data.products.length === 1) {
                         setNodata(true)
                    }
                    setProjectData(response?.data?.products)

               }
          }
          catch (error) {
               return error
          }
          finally {
               setLoader(false)

          }
     }

     const handleDelete = (id) => {
          setShow(true)
          setModalId(id)
     }
     const handleClose = () => {
          setShow(false)
     }

     const ProjectdeleteData = async () => {
          setDeleteLoader(true)
          let param = { productId: [modalId] }
          try {
               let response = await API.productsdelete(token, param, params.id)
               if (response.success) {
                    setShow(false)
                    toast.success(response.message)
                    GetProjectDetails()
               }
               else {
                    toast.error(response.error)
               }
          }
          catch (error) {
               return error
          }
          finally {
               setDeleteLoader(false)
          }
     }


     const onDragEnd = (e, id) => {
          let sourceIndex = e.source.index;
          let destIndex = e.destination.index;

          let result = []


          let finalResult = ProjectData?.map((data) => {

               if (data?.location?._id === id) {
                    result = Array.from(data.product);
                    
                    const [removed] = result.splice(sourceIndex, 1);
                    result.splice(destIndex, 0, removed);

                    return { ...data, product: result }
               }
               else {
                    return data
               }
          })

          setProjectData([...finalResult])

          finalResult.forEach((data, index) => {
               let arr = []
               {
                    data.product.forEach((item, secIndex) => {

                         let param = {
                              productInProjectId: item.productInProjectId,
                              productIndex: secIndex + 5
                         }
                         
                         orderList.push(param)
                    })
               }

          })
          mapBrochureOrderList(orderList)
     }

     const moveUp = (id, index, sourceId) => {
          let sourceIndex = index;
          let destIndex = index - 1;

          let result = []


          let finalResult = ProjectData?.map((data) => {

               if (data?.location?._id === id) {
                    result = Array.from(data.product);
                    const [removed] = result.splice(sourceIndex, 1);
                    result.splice(destIndex, 0, removed);

                    return { ...data, product: result }
               }
               else {
                    return data
               }
          })

          setProjectData([...finalResult])
          finalResult.forEach((data, index) => {
               let arr = []
               {
                    data.product.forEach((item, secIndex) => {

                         let param = {
                              productInProjectId: item.productInProjectId,
                              productIndex: secIndex + 5
                         }
                         
                         orderList.push(param)
                    })
               }

          })
          mapBrochureOrderList(orderList)

     }

     const moveDown = (id, index) => {
          let sourceIndex = index;
          let destIndex = index + 1;

          let result = []


          let finalResult = ProjectData?.map((data) => {

               if (data?.location?._id === id) {
                    result = Array.from(data.product);
                    const [removed] = result.splice(sourceIndex, 1);
                    result.splice(destIndex, 0, removed);

                    return { ...data, product: result }
               }
               else {
                    return data
               }
          })

          setProjectData([...finalResult])
          finalResult.forEach((data, index) => {
               let arr = []
               {
                    data.product.forEach((item, secIndex) => {

                         let param = {
                              productInProjectId: item.productInProjectId,
                              productIndex: secIndex + 5
                         }
                         
                         orderList.push(param)
                    })
               }

          })
          mapBrochureOrderList(orderList)


     }



     
     return (
          <section>
               <div className="container">
                    <div className="selectDocument">
                         <div className="selectDocument__bottom">
                              <div className="bottomLeft">
                                   <h1>Set Product Order</h1>
                                   <p>Define the order of your product within your Owner Brochure document. Drag & Drop the products in this view to set the order. </p>
                                   <br />
                                   <div className="dual-btn-row">

                                        <div className="fBtn">
                                             <Button type={"secondary"} value={"Add More Products"}
                                                  click={props.onClick} />
                                        </div>

                                        <Button type={"secondary"} value={"Reset Product List"}
                                             click={() => GetProjectDetails()}
                                        />

                                   </div>


                              </div>
                              <div className="bottomRight">

                                   {/* <div className="btn-bottomRight">
                                        {IndexLoader ? <ClipLoader/> :
                                        <Button type="primary" value={"Set Product Order"}
                                        click={()=>setProductOrder()} />}

                                   </div> */}
                                   {loader ?
                                        <div className="loaderD">
                                             <ClipLoader />
                                        </div> :

                                        <div className="main-box">
                                             {(!Nodata) ? ProjectData?.map((data, index) => (
                                                  data.location !== null &&

                                                  <DragDropContext
                                                       onDragEnd={(e) => onDragEnd(e, data?.location?._id)}
                                                  >
                                                       <b>{data?.location?.name}</b>

                                                       <Droppable droppableId={data?.location?._id} index={index}>

                                                            {(provided, snapshot) => (

                                                                 < div {...provided.droppableProps}

                                                                      ref={provided.innerRef}>

                                                                      { data?.product?.map((item, indexx) => (

                                                                           <Draggable key={item?.productInProjectId}
                                                                                draggableId={item?.productInProjectId}
                                                                                index={indexx}
                                                                           >

                                                                                {(provided, snapshot) => (

                                                                                     <div className="cols" ref={provided.innerRef}
                                                                                          {...provided.draggableProps}
                                                                                          {...provided.dragHandleProps}>


                                                                                          <div className="card">

                                                                                               <span className="no">{indexx + 1}</span>
                                                                                               <div className="btn-t">
                                                                                                    <GiHamburgerMenu />
                                                                                               </div>
                                                                                               {
                                                                                                    item?.detail?.images?.map((img)=>(
                                                                                                         (item?.colorId === img?._id && <img className="imgg" src={
                                                                                                         (img?.fileName === undefined || img?.fileName === null
                                                                                                         || img?.fileName === "") ?
                                                                                                         Images.image.productImg : `${process.env.REACT_APP_IMAGE_BASEURL}${img?.fileName}`
                                                                                                          } alt="text" />)
                                                                                                    ))
                                                                                               }
                                                                                               
                                                                                               <div className="name-h">
                                                                                                    <h7>{item?.detail?.name}</h7>
                                                                                               </div>




                                                                                               <ul>
                                                                                                    {(indexx !== 0 && item.length !== 1) ? <li
                                                                                                         onClick={() => moveUp(data?.location?._id, indexx, item?.productInProjectId)}
                                                                                                    >Move Up</li> : " "}

                                                                                                    {(indexx !== (data?.product?.length - 1)) ? <li
                                                                                                         onClick={() => moveDown(data?.location?._id, indexx, item?.productInProjectId)}
                                                                                                    >Move Down</li> : " "}

                                                                                                    <li style={{ color: 'red' }} className="remove"
                                                                                                         onClick={() => handleDelete(item?.productInProjectId)}
                                                                                                    >Remove</li>
                                                                                               </ul>

                                                                                          </div>


                                                                                     </div>

                                                                                )}

                                                                           </Draggable>

                                                                      ))}
                                                                      {provided.placeholder}

                                                                 </div>


                                                            )}



                                                       </Droppable>


                                                  </DragDropContext>

                                             )) :
                                                  <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px' }}
                                                  ><p>No data found</p>
                                                  </div>
                                             }

                                        </div>

                                   }
                              </div>

                         </div>
                    </div>

               </div>
               {show && <ModalC message="Do you want to delete this product?" title="Delete Product"
                    showmodal={show} handleClose={handleClose}
                    deleteLoader={deleteLoader} handleYes={ProjectdeleteData}
               />
               }
          </section>
     )
}

export default SetBrochureProduct