import React, { useEffect, useState } from 'react';
import { Images } from 'constants/Images';
import InputBox from 'components/comman/InputBox';
import 'stylesheets/modal.scss';
import Button from 'components/comman/button';
import { templateList } from 'constants/ProjectData';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { AiOutlineCheck } from 'react-icons/ai';
import { useForm } from "react-hook-form";
import { API } from 'API/API';
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from 'context/auth/AuthContext';

const createType = [
    {
        id: 1,
        label: 'Start New Project From Scratch',
    }
];
var Candaregex = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
const mess = "This field is required."
const schema =yup.object({
    name: yup.string().required(mess),
    // zipCode: yup.string().required(mess).matches(
    //     /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
    //     "Postal code is not valid"
    //   )
})

const CreateProjectModal = (props) => {
    const { handleSubmit, control, setValue, getValues } = useForm({
        resolver:yupResolver(schema)
    });
    const [selected, setSelected] = useState(1);
    const [selectItem, setSelectItem] = useState(null);
    const [selectTemp, setSelectTemp] = useState(null);
    const [selectedVal, setSelectedVal] = useState('None');
    const [loader, setLoader] = useState(false)
    const [mainModalData,setMainModalData] = useState({})
    // const [projectName,setProjectName]= useState('')
    // const [location,setLocation] = useState('')
    const navigate = useNavigate()
    const { token } = useAuthContext()
    const onChoose = (id) => {
        setSelected((prevID) => {
            if (prevID !== id) return id;
            return id;
        })
    }

    const onTemplate = (id) => {
        setSelectItem((prevID) => {
            if (prevID !== id) return id;
            return null;
        })
    }
    const onSelectTemp = (id, perent, label) => {
        setSelectTemp((prevID) => {
            if (prevID !== id) return id;
            return id;
        })
        setSelectedVal(perent + ": " + label)
    }
    const onSubmitform = (data) => {
        if(props.rowID._id){
            const data ={
                name:getValues('name').toLowerCase(),
                zipCode:getValues('zipCode')
            }
            UpdateEditProject(data,props.rowID._id)
        }
        else{
            addNewProject(data)
        }
        
    }

    const addNewProject = async (data) => {

        setLoader(true)
        try {
            data.name=data.name.toLowerCase()
            let response = await API.AddProjects(data, token)
            if (response.success) {
                toast.success(response.message)
                props.clickTable()
                // window.location.href = `/create-project-tools/${response.data._id}`;
                navigate(`/create-project-tools/${response.data._id}`);
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            toast.error('Network Error')
        }
        finally {
            setLoader(false)
        }
    }




    useEffect(() => {
        getModalDetails()
    }, [props.clickProject]);

    const getModalDetails = () =>{
        if(props.rowID._id){
            setValue('name', props.rowID.name)
            setValue('zipCode', props.rowID.zipCode)   
        }
        else{
            setValue('name', '')
            setValue('zipCode', '')   
        }
    }
   
    const UpdateEditProject =  async (data,id) =>{
        setLoader(true)
        try{
            let response = await API.UpdateSubmittal(data,id ,token)
            if (response.success) {
                toast.success(response.message)
                props.clickTable()
                // window.location.href = `/create-project-tools/${id}`;
                navigate(`/create-project-tools/${id}`);
            }
            else {
                toast.error(response.message)
            }
        }
        catch(error){
            return error
        }
        finally{
            setLoader(false)
        }
    }


    // const getSubmittalDetails = async () => {'Update Project'
    //         }
    //     }
    //     catch (error) {
    //         return error
    //     }
    // }

    return (
        <div className={`modal createProject ${props.show  ? 'show' : ''}`}>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2>Create a Project</h2>
                        <button type='button' onClick={props.clickProject} className='btn close'>
                            <img src={Images.icon.closeCircle1} alt="close" />
                        </button>
                    </div>
                    <div className='modal-body'>
                        <ul>
                            {createType.map((item) => (
                                <li key={item.id}>
                                    <label>
                                        <input type={'radio'} name="createProject" checked={item.id === selected} className={item.id === selected ? 'active' : ''} onClick={() => onChoose(item.id)} />
                                        <span>{item.label}</span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        {selected === 2 ? <p><b>CHOOSE A TEMPLATE</b> that matches your project needs to create a project with our most popular products from that segment. You are free to customize the project once its created.</p> : null}
                        <form onSubmit={handleSubmit(onSubmitform)} className='row'>

                            <InputBox name="name" control={control} label='Project Name' type='text' />

                            <InputBox name="zipCode" type="text" control={control} label='Location - Postal Code'  />
                            {
                                loader ?
                                    <div style={{ marginLeft: '50px', marginTop: '10px' }} >
                                        <ClipLoader />
                                    </div>
                                    :
                                    <div style={{ marginTop: '10px' }} className='submit'>
                                        <Button type='primary' value={props.rowID._id ? 'Update Project' : 'Create Project'} />
                                    </div>
                            }

                        </form>
                        {selected === 2 ?
                            <div className='template'>
                                <h4><b>Template Selected:</b> {selectedVal}</h4>
                                <ul>
                                    {templateList.map((item, index) => (
                                        <li key={item.id}>
                                            <button type='button' className='btn' onClick={() => onTemplate(item.id)}>
                                                {item.id === selectItem ? <FaCaretDown /> : <FaCaretRight />}
                                                {item.label}
                                            </button>
                                            {item.id === selectItem &&
                                                <ol>
                                                    {item.child.map((i) => (
                                                        <li key={i.id} onClick={() => onSelectTemp(i.id, item.label, i.label)} className={i.id === selectTemp ? 'active' : ''}>
                                                            <span><AiOutlineCheck /></span>
                                                            {i.label}
                                                        </li>
                                                    ))}
                                                </ol>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </div> : null}

                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateProjectModal;