import React, { useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import Button from "./comman/button";
import InputBox from "./comman/InputBox";
import { API } from "API/API";
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context/auth/AuthContext";
import { useSearchContext } from "context/searchContext/searchContext";


const mess = "This field is required."
let email = "Email must be in valid format."


const schema = yup.object({
    email: yup.string().required(mess).email(email),
    password: yup.string().required(mess),
})

const LoginForm = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loader, setLoader] = useState(false)
    const { doLogin, assignToken, login, token } = useAuthContext()
    const { mapUserData } = useSearchContext()

    const navigate = useNavigate()
    // useEffect(() => {
    //     toast("abcd")
    // },[]);

    const { handleSubmit, control, setValue, getValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitform = (data) => {
        data['userType'] = "user"
        userLogin(data)
    }


    const userLogin = async (data) => {
        setLoader(true)
        try {
            let response = await API.userAuthentication(data)
             const status=response.status
             const resData=response.data
            if (status===200) {
                const token=resData.data
                localStorage.setItem('mytoken', token);
                getMainUser(token)
                toast.success(resData.message)
            }
            else{
                toast.error(resData.message)
            }
        }

        catch (err) {
            toast.error("Network Error", err)
        }
        finally {
            setLoader(false)
        }
    }

    const getMainUser = async (token) => {
        try {
            let response = await API.UserProfile(token)
            if (response.data) {
                const data = response.data
                mapUserData(data)
                doLogin(true)
                assignToken(token)
                // toast.success('Login successfully')
                navigate("/", { replace: true })

            }
            else {
                toast.error('Login successfully')

            }
        }
        catch (error) {
            return error
        }
    }

    // useEffect(()=>{
    //     getMainUser()

    // },[call, !call, token])


    return (<>
        <form onSubmit={handleSubmit(onSubmitform)}>
            <InputBox placeholder={"Enter email"} control={control} label="Email Address" type={'text'}

                name="email"
            />
            <InputBox placeholder={"Enter password"} control={control} label="Password" type={'password'}

                name="password"
            />
            <div className="form-group text-center">
                {loader ? <ClipLoader /> : <Button value="Login" type="primary" />}
            </div>
            <div className="form-group text-center">
                <span className="forgot" onClick={props.forgot}>Forgot Password?</span>
            </div>
        </form>

    </>
    )
}
export default LoginForm;