import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiCheck, BiMinus } from "react-icons/bi";
import Button from "./comman/button";
import SelectBox from "./comman/SelectBox";
import { useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Loader } from "react-feather";
import { toast } from "react-toastify";
import { API } from "API/API";

const SubmittalProductCard = (props) => {
  let location = useLocation();
  let param = useParams();
  const { handleSubmit, control, setValue, getValues } = useForm({
    yupResolver: yupResolver(),
  });

  const [current, setCurrent] = useState(0);
  const [active, setActive] = useState();
  const [check, setCheck] = useState(false);
  const [image, setImage] = useState();
  const [colorID, setColorID] = useState("");
  const [titleArr, setTitleArr] = useState([]);
  const [titleLoader, setTitleLoader] = useState();
  const [selectedColor, setSelectedColor] = useState(false);
  const [deleteColor, setDeleteColor] = useState("");

  useEffect(() => {
    props?.color?.map((data, index) => {
      if (index === 0) {
        setCurrent(data._id);


        for (var i = 0; i < props?.isCheckClr?.length; i++) {
          if (props.isCheckClr[i].color === data._id) {
            setSelectedColor(true);
            setDeleteColor(props.isCheckClr[i].pInpId);
            break;
          } else {
            setSelectedColor(false);
          }
        }
      }
    });
  }, [props.isCheckClr]);

  useEffect(() => {
    // getListing();
  }, []);

  const getListing = async () => {
    setTitleLoader(true);
    try {
      let response = await API.getTitleListing();
      if (response?.success) {
        setTitleArr(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    } finally {
      setTitleLoader(false);
    }
  };

  const handleColorSelect = (index, item, id) => {

    for (var i = 0; i < props.isCheckClr.length; i++) {
      if (props.isCheckClr[i].color === id) {
        setSelectedColor(true);
        setDeleteColor(props.isCheckClr[i].pInpId);
        break;
      } else {
        setSelectedColor(false);
      }
    }
    setImage(item);
    setActive(true);
    setCurrent(id);
    setColorID(id);
    passColorId(id);
  };

  const passColorId = (id) => { 
    console.log('fgfg', id)
    props.getColorId(id);
  };

  const onSelect = () => {
    let colorIdToSend = ""
    if (colorID === "") {
      props.color.map((data, index) => {
        if (index === 0) {
          setColorID(data._id);
          passColorId(data._id);
          colorIdToSend = data._id
        }
      });
    } 
    props.onSelect(colorID || colorIdToSend);

    setColorID("");
  };

  const remove = () => {
    props.remove(deleteColor);
  };
  console.log('ttt', props.selectedColor);

  return (
    <div className="productCard__over">
      <div
        style={{
          padding: "20px",
          minHeight: "280px",
          display: "flex",
          alignItems: "center",
          maxHeight: "280px",
        }}
        className="productCard__img"
      >
        {props?.color?.map(
          (datas) =>
            datas._id === current && (
              <>
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}${datas?.fileName}`}
                  alt={props.title}
                  onClick={props.goLink}
                />
              </>
            )
        )}
        {/* <img
          src={
            `${process.env.REACT_APP_IMAGE_BASEURL}${
              image ? image : props?.color && props?.color[current]?.fileName
            }` || props.image
          }
          alt={props.title}
          onClick={props.goLink}
        /> */}
        {location.pathname === `/project-detail/${param.id}` ||
        location.pathname === `/product-detail/${param.id}` ||
        selectedColor ? (
          ""
        ) : (

            props.isFilter === false ? (<input
              type="checkbox"
              className="inputt"
              id="select"
              name="select"
              onClick={() => props.onSelectClick(current)}
              checked={props.isSelected}
            />) : null

          // <input
          //   type="checkbox"
          //   className="inputt"
          //   id="select"
          //   name="select"
          //   onClick={() => props.onSelectClick(current)}
          //   checked={props.isSelected}
          // />
        )}
      </div>

      {props.btn !== true && (
        <div
          className="productCard__btn"
          style={{
            paddingLeft: "15px",
            paddingBottom: "5px",
            paddingRight: "15px",
          }}
        >
          <div className="productCard__textDetails" onClick={props.goLink}>
            <h4 style={{cursor:'default'}}>{props.title}</h4>
            <div className="productCard__color">
              <ul>
                {props?.isSelected
                  ? props?.color?.map(
                      (item, index) =>
                        item._id === current && (
                          <li>
                          {item?.code2 ? (
                            <div className="mainDual">
                              <div
                                style={{
                                  backgroundColor: `${
                                    item?.code !== "" ? item.code : ""
                                  }`,
                                }}
                                className="colorInner"
                              ></div>
                              <div
                                style={{
                                  backgroundColor: `${
                                    item?.code2 !== "" ? item.code2 : ""
                                  }`,
                                }}
                                className="colorInner2"
                              ></div>
                            </div>
                          ) : (
                            <div className="main">
                              <div
                                style={{
                                  border: "1.5px solid gray",

                                  backgroundColor: `${item?.code}`,
                                }}
                              ></div>
                            </div>
                          )}
                          </li>
                        )
                    )
                  : props?.color?.map((item, index) => (
                      <li
                        onClick={() =>
                          handleColorSelect(index, item.fileName, item._id)
                        }
                      >
                        {item?.code2 ? (
                            <div className="mainDual">
                              <div
                                style={{
                                  backgroundColor: `${
                                    item?.code !== "" ? item.code : ""
                                  }`,
                                }}
                                className="colorInner"
                              ></div>
                              <div
                                style={{
                                  backgroundColor: `${
                                    item?.code2 !== "" ? item.code2 : ""
                                  }`,
                                }}
                                className="colorInner2"
                              ></div>
                            </div>
                          ) : (
                            <div className="main">
                              <div
                                style={{
                                  border: "1.5px solid gray",

                                  backgroundColor: `${item?.code}`,
                                }}
                              ></div>
                            </div>
                          )}
                      </li>
                    ))}
              </ul>
            </div>
            <h5>
              <b>
                {" "}
                {`${props?.titleArr?.find((titl) => titl?.id === 1)?.name} : `}
              </b>
              {
              props?.titleArr?.find((titl) => titl?.id === 1)?.status === 1 ?
              <span className="spanOfSubmittal">
              {props?.manuName?.length >0 ? props?.manuName[0].title:
               "N/A"}</span>
               : ("N/A")
            } 
            </h5>
            {
              <span className="pp">
                <b>Model :</b> {`${props.color?.find((clr) => clr?._id === current)?.modelName}`}
              </span>
            }
            <p>{props.text}</p>
          </div>
          {!props?.isSelected && (
            <>
              {!selectedColor ? (
                <Button
                  type="primaryyProject"
                  value={props.isFilter ? "Replace Product" : "Add to Project"}
                  // value="Add to Project"
                  click={() => onSelect()}
                />
              ) : props.loader && props.loaderId === props.id ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ClipLoader />
                </div>
              ) : (
                <Button
                  type="secondaryProject"
                  value="Remove From Project"
                  click={remove}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default SubmittalProductCard;
