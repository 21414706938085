import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputBox from "./InputBox";
import SelectBox from "./SelectBox";
import * as yup from "yup"
import Button from "./button";
import { API } from "API/API";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { propTypes } from "react-bootstrap/esm/Image";
import ButtonSecond from "./buttonSecond";
import { useAuthContext } from "context/auth/AuthContext";
import { useSearchContext } from "context/searchContext/searchContext";



const ProjecList = [{ id: 1, value: 'Education' }, { id: 2, value: 'Government' },
{ id: 3, value: 'Hospitality' }, { id: 4, value: 'Multi-family' }, { id: 5, value: 'Non-profit' }
     , { id: 6, value: 'Retail' }, { id: 7, value: 'Workplace/Office' }, { id: 8, value: 'Other' },
]
const OwnerList = [{ id: 1, value: 'oType1' }, { id: 2, value: 'oType2' }]
const PhaseList = [{ id: 1, value: 'Construction' }, { id: 2, value: 'Contact negotiation' },
{ id: 3, value: 'Design' }, { id: 4, value: 'Document development' }, { id: 5, value: 'Occupation' }]
const mess = "This field is required."



const AdditionalInfoForm = (props) => {

     const [ProjectType, setProjectType] = useState(0)
     const [OwnerType, setOwnerType] = useState(0)
     const [PhaseType, setPhaseType] = useState(0)
     const { AdditionalDetailBar } = useSearchContext()

     const { handleSubmit, control, setValue, getValue, reset } = useForm({

     });
     const [ownerOccupied, setOwnerOccupied] = useState(false)

     const handleForm2 = (data) => {
          data['ownerOccupied'] = ownerOccupied
          UpdateProjectDetails(data)
     }

     const params = useParams()
     const [loader, setLoader] = useState(false)
     const { token } = useAuthContext();
     const {OnAdditionInformationStep} = useSearchContext()

     const UpdateProjectDetails = async (data) => {
          setLoader(true)
          try {
               let response = await API.UpdateSubmittal(data, params.id, token)
               if (response.success) {
                    toast.success(response.message)
                    props.click()
                    if(response?.data?.projectSize!==undefined && response?.data?.budget!==undefined
                         && response?.data?.projectSize!==null && response?.data?.budget!==null)
                    {
                    AdditionalDetailBar(100)
                    }
                  
                    else{
                    AdditionalDetailBar(0)
                    }
               }
               else {
                    toast.error(response.message)
               }
          }
          catch (error) {
               return error
          }
          finally {
               // AdditionalDetailBar(100)
               setLoader(false)
          }
     }
     const ownerOccupiedChecked = (e) => {
          setOwnerOccupied
               (e.target.checked)
     }
     useEffect(() => {
          if (token)
               getSubmittalDetails()
     }, [token]);
     
     const getSubmittalDetails = async () => {
          try {
               let response = await API.getSubmittalDetails(params.id, token)
               if (response.success) {
                    setValue('budget', response?.data?.budget)
                    setValue('projectSize', response?.data?.projectSize)
                    setValue('projectType', response?.data?.projectType)
                    setValue('ownerType', response?.data?.ownerType)
                    setValue('projectPhase', response?.data?.projectPhase)
                    setOwnerOccupied(response?.data?.ownerOccupied ? true : false)
                    if(response?.data?.projectSize!==undefined && response?.data?.budget!==undefined
                         && response?.data?.projectSize!==null && response?.data?.budget!==null)
                    {
                    AdditionalDetailBar(100)
                    }
                   
                    else{
                    AdditionalDetailBar(0)
                    }
               }
          }
          catch (error) {
               return error
          }
     }

     const DiscardChange = () => {
          setOwnerOccupied(false)
          reset({
               budget: '',
               projectSize: '',
               projectType: 0,
               ownerType: 0,
               projectPhase: 0,
          })
     }

     return (
          <form onSubmit={handleSubmit(handleForm2)}>
               <InputBox type="text"  name="budget" control={control} className={'inputCustom '} grouClass={'dollarInput'}
                    label={"Project Budget"} />
               <InputBox type="number" name="projectSize" className={'inputCustom '} control={control} grouClass={'sqftInput'}
                    label={"Project Size"} />
               <SelectBox name="projectType" grouClass="Selectcountry" control={control}
                    label={"Project Type"} selectList={ProjecList} />
               {/* <SelectBox  name="ownerType" grouClass="Selectcountry" selected={"Select a Owner Type"} control={control}
                    label={"Owner Type"} selectList={OwnerList} /> */}
               <InputBox type="text" name="ownerType" className={'inputCustom '} control={control}
                    label={"Owner Type"} />
               <SelectBox name="projectPhase" grouClass="Selectcountry" control={control}
                    label={"Project Phase"} selectList={PhaseList} />
               <label style={{ fontSize: '14px' }} >Owner Occupied</label>

               <div className="icheckBox">
                    <div>
                         <input style={{ height: '20px', width: '20px' }}
                              type="checkbox" checked={ownerOccupied} onChange={(e) => ownerOccupiedChecked(e)} />
                    </div>
                    <div className="checkLabelBox">
                         <label for="vehicle1">No</label>
                    </div>
               </div>
               <div className="doubleButton">
                    {loader ?
                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '30px' }}>
                              <ClipLoader />
                         </div> :
                         <Button type='primary' value={"Save Change"} />
                    }
                    <ButtonSecond click={() => DiscardChange()} type='secondary' value={"Discard Change"} />
               </div>
          </form>
     )
}

export default AdditionalInfoForm