import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "API/API";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import Button from "./comman/button";
import '../stylesheets/resetPassword.scss'
import InputBox from "./comman/InputBox";
import { ClipLoader } from "react-spinners";
import * as yup from 'yup'
import { toast } from "react-toastify";


const mess = "This field is required."
const schema = yup.object({
     password: yup.string().required(mess)
         .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
             "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"),
          confirmPassword: yup.string().required(mess)
         .oneOf([yup.ref('password'), null], 'Passwords must match.'),
 
 })
const ResetPassword = () => {
     const [loader,setLoader]=useState(false)
      const navigate = useNavigate()
     const { handleSubmit, control, setValue, getValues } = useForm({
          resolver:yupResolver(schema)
     });
     const params = useParams()
     const onSubmit = (data) =>{
          ChangePasword()
     }
     const ChangePasword = async  ()=>{
          setLoader(true)
          let param = {
               password : getValues('password'),
               confirmPassword : getValues('confirmPassword'),
               token : params.id
          }
          try{
               let response = await API.ChangePassword(param)
               if(response.success){
                    toast.success(response.message)
                    navigate('/login-signup')
               }
               else{
                    toast.error(response.message)
               }
          }
          catch(error){
               return error
          }
          finally{
               setLoader(false)
          }
     }

     return (
          <section className="resetPass" >
               <div className="container">
                    <div className="row">
                         <form onSubmit={handleSubmit(onSubmit)} className="inner-Row">
                              <div className="heading">
                                   <h1>{"RESET PASSWORD"}</h1>
                              </div>
                              <div className="inputs">
                                   <InputBox control={control} placeholder={"Enter password"}
                                        label="Password" type={'password'} name="password" />
                                   <InputBox control={control} placeholder={"Confirm password"}
                                        label="Confirm password" type={'password'} name="confirmPassword" />

                                   <div className="inputsbutton" > { loader? <ClipLoader/> :
                                   <Button  value="Change-Password"  type="primary"  />
                                   }
                                   </div>     
                              </div>
                         </form>
                    </div>
               </div>
          </section>
     )
}

export default ResetPassword