import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import ProjectDetailTab from "components/tabs/ProjectDetailTab";
import { useSearchContext } from "context/searchContext/searchContext";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillExclamationCircle } from "react-icons/ai";


const GenerateBrochure = (props) =>{
     const { handleSubmit, control, setValue, getValue } = useForm({
     });
    const{brochureEmail,setBrochureMail ,brochureImage } = useSearchContext()

    const [selectedImagePreview, setSelectedImagePreview] = useState(null);

    const [mail, setMail] = useState("")
    const [selectedImage, setSelectedImage] = useState(null);


     const handleChane = (e) => {
        setMail(e.target.value)

        setBrochureMail(e.target.value , brochureImage)
    }

    useEffect(()=>{
        if (brochureImage) {
            // Set the selected image file
            setSelectedImage(brochureImage);
      
            // Optionally, you can use FileReader to show a preview of the image
            const reader = new FileReader();
            reader.onload = (e) => {
              // Set the preview image URL
              setSelectedImagePreview(e.target.result);
            };
            reader.readAsDataURL(brochureImage);
          }
    },[brochureImage]) 

    useEffect(()=>{
        if(brochureEmail){
           setMail(brochureEmail)
        }  
    },[brochureEmail])


    const handleImageChange = (event) => {

      const file = event.target.files[0];
  
      if (file) {
        // Set the selected image file
        setSelectedImage(file);
  
        // Optionally, you can use FileReader to show a preview of the image
        const reader = new FileReader();
        reader.onload = (e) => {
          // Set the preview image URL
          setSelectedImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
      }

      setBrochureMail(mail, file)
    };

     return(
          <section>
               <div className="container">

               <div className="editProject">
                    
                    <div className="editProject__bottom">
                        <div className="bottomLeft">
                            <h1>Project Review</h1>
                            <p>Project details appear on the final Submittal Prep and are mostly optional.
                                We recommend filling them all out to provide a comprehensive submittal package.</p>
                            <Button type={"secondary"} value={"Edit Project Details"}
                             click={props.onClick}
                              />
                            <br />
                            <div>
                            <div className="generation-method">
                                    <h2>Append logo</h2>
                                    <span className="generation-icon">
                                        <AiFillExclamationCircle />
                                    </span>
                                </div>

                                <div className="generation">
                                    <input style={{marginBottom:'10px'}}  type="file" accept="image/*" onChange={handleImageChange} />

                                    {selectedImage && (
                                        <div>
                                        <p>Selected Image:</p>
                                      
                                        <img style={{width:"100px"}}src={selectedImagePreview} alt="Preview" />
                                        </div>
                                    )}
                                  
                                </div>
                                <br/>
                                <div className="generation-method">
                                    <h2>Generation Method</h2>
                                    <span className="generation-icon">
                                        <AiFillExclamationCircle />
                                    </span>
                                </div>

                                {/* <div style={{marginBottom:'5px'}}>
                                    <input checked type="radio" id="emailRadio" value="emailRadio" />
                                    <label for="emailRadio">Download Brochure</label>
                                </div> */}
                                {/* <br /> */}
                                {/* <InputBox control={control} placeholder={"dummymail@gmail.com"} /> */}
                               
                                <div className="generation">
                                    <div className="input-gen">
                                        <input  checked type="radio"
                                            id="emailRadio" name="generation" value="email" />
                                        <label for="emailRadio">Email Brochure</label>
                                    </div>
                                    
                                    <div className="input-main">
                                    <input type="email"
                                     onChange={(e)=>handleChane(e)}
                                      value={brochureEmail?brochureEmail:""}
                                     placeholder="dummymail@gmail.com" />
                                    </div>
                                </div>
                                
                                <br />
                                <div className="docandfile">
                                    <h3>Document and File Review</h3>
                                    <p>Are you still missing a few products?</p>
                                    <Button type={"secondary"} value={"Add More Products"}
                                     click={props.onClick2}
                                      />
                                </div>
                            </div>
                        </div>
                        <div className="projectDetailTabmargin">
                            <ProjectDetailTab />
                        </div>

                    </div>
                </div>

               </div>
          </section>
     )
}

export default GenerateBrochure