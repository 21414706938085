import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import SelectBox from "components/comman/SelectBox";
import TextareaBox from "components/comman/TextareaBox";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import '../../stylesheets/editProject.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateClip from '../../assets/images/calendar.png'
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "API/API";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { propTypes } from "react-bootstrap/esm/Image";
import ButtonSecond from "./buttonSecond";
import moment from 'moment';
import { useAuthContext } from "context/auth/AuthContext";
import { countryList } from "constants/countryList";
import TextareaBoxControl from "./TextBoxWithControl";
import { useSearchContext } from "context/searchContext/searchContext";

const SelectList = [
     {
          id: 'USA',
          value: 'USA'
     },
     {
          id: 'Canada',
          value: 'Canada'
     },

]

const mess = "This field is required."
const schema = yup.object({
     // line1: yup.string().required(mess),
     // line2: yup.string().required(mess),
     // projectNumber: yup.string().required(mess),
     // city: yup.string().required(mess),
     // country: yup.string().required(mess),
     // zip: yup.string().required(mess),
     // state: yup.string().required(mess),
     // date:yup.date().required(mess)
     // textBox: yup.string().required(mess),
})

const  ProjectDetailForm = (props) => {

     const {projectDetailBar} = useSearchContext()
     const [startDate, setStartDate] = useState();
     const [countryPS, setCountryPS] = useState(null)
     const [projectD, setProjectD] = useState()

     const { handleSubmit, control, setValue, getValues, reset } = useForm({
          resolver: yupResolver(schema)
     });
     const { onUpdateProjectStep } = useSearchContext()
     const [ProjectNotes, setProjectNotes] = useState('')
     const handleForm = (data) => {
          data['estimateShipDate'] = startDate
          // if(data!=="")
          // {
          // setProjectD(true)
          // projectData(true)
          // }
          // else{
          // setProjectD(false)
          // projectData(false)
          // }
          let address = {
               line1: getValues('line1'),
               line2: getValues('line2'),
               city: getValues('city'),
               state: getValues('state'),
               zip: getValues('zip'),
               country: getValues('country'),
          }
          let param = {
               address: address,
               notes: getValues('textBox'),
               projectNumber: getValues('projectNumber'),
               estimateShipDate: startDate
          }
          console.log('project details', param);
          UpdateProjectDetails(param);
     }

     const [loader, setLoader] = useState(false)
     const params = useParams()
     const { token } = useAuthContext();

     const UpdateProjectDetails = async (data) => {
          setLoader(true)
      
          try {
               let response = await API.UpdateSubmittal(data, params.id, token)
               if (response.success) {
                    onUpdateProjectStep(true)
                    toast.success(response.message)
                    props.click()

                    if(response?.data?.address!==null && response?.data?.address!==undefined )
                    {
                    projectDetailBar (100)
                    // onUpdateProjectStep(true)
                    }
                    else 
                    {
                    projectDetailBar(0)
                    // onUpdateProjectStep(false)
                    }

               }
               else {
                    toast.error(response.message)
               }
          }
          catch (error) {
               return error
          }
          finally {
               // projectDetailBar(100)
               setLoader(false)
          }
     }
     useEffect(() => {
          if(token)
          getSubmittalDetails()
     }, [token]);

     const getSubmittalDetails = async () => {
          try {
               let response = await API.getSubmittalDetails(params.id, token)

               if (response.success) {
               projectDetailBar(100)

                    setValue('line1', response?.data?.address?.line1)
                    setValue('line2', response?.data?.address?.line2)
                    setValue('projectNumber', response?.data?.projectNumber)
                    setValue('city', response?.data?.address?.city)
                    setValue('country', response?.data?.address?.country)
                    setValue('zip', response?.data?.address?.zip)
                    setValue('state', response?.data?.address?.state) 

                    setValue('textBox', response?.data?.notes)
                    setStartDate(response?.data?.estimateShipDate && new Date(response?.data?.estimateShipDate))
                    // setProjectNotes(response?.data?.notes)
                    if(response.data.address!==undefined && response.data.address!==null)
                    {
                    projectDetailBar (100)
                    onUpdateProjectStep(true)
                    }
                    else 
                    {
                    projectDetailBar(0)
                    onUpdateProjectStep(false)
                    }


               }
          }
          catch (error) {
               return error
          }
     }
     const DiscardChange = () => {
          // setProjectNotes("")
          setStartDate(null)
          reset({
               line1: '',
               line2: '',
               projectNumber: '',
               city: '',
               country: 0,
               zip: '',
               state: '',
               textBox: ''
          })
     }


     // const [ProjectTextValid, setProjectTextValid] = useState('')
     // const handleTextBox = (e) => {
     //      setProjectNotes(e.target.value)
     //      if (ProjectNotes === "") {
     //           setProjectTextValid("This field is required.")
     //           return false
     //      }
     //      else {
     //           setProjectTextValid("")
     //           return true
     //      }
     // }

     return (
          <form onSubmit={handleSubmit(handleForm)} >
               <InputBox name="line1" control={control} className={'inputCustom '} label={"Address Line 1"} />
               <InputBox name="line2" control={control} className={'inputCustom'} label={"Address Line 2"} />
               <InputBox name="projectNumber" control={control} className={'inputCustom'} label={"Project Number"} />
               <div className="doubleInput">
                    <div className="inputCustom2">
                         <InputBox name="city" control={control} className={'inputCustom'} label={"City"} />
                    </div>
                    {/* <InputBox control={control} className={'inputCustom'} label={"Country"} /> */}
                    <SelectBox name="country" grouClass="Selectcountry" selectList={countryList}
                         label={"Country"} control={control} />
               </div>
               <div className="doubleInput">
                    <div className="inputCustom2">
                         <InputBox  name="zip" control={control} className={'inputCustom'} label={"Postal Code"} />
                    </div>
                    <InputBox name="state" control={control} className={'inputCustom'}
                         label={"State/Province/Regions"} />
               </div>
               <div className="customDatePickerWidth">
                    <label style={{ marginTop: "1px" }}>{"Estimated Ship Date"}</label>
                    <div className="date-input-box">
                         <div>  <label> 
                              <DatePicker className="datepickerContact" required minDate={new Date} height="100px" placeholderText="dd/mm/yyy"
                                   selected={startDate} onChange={(date) => setStartDate(date)} />
                            
                                   <img className="date-input-image" src={DateClip} />
                              </label>
                         </div>
                    </div>

                    {/* <div style={{ color: 'red' }}>
                                <p>{projectdValid}</p>
                            </div> */}

               </div>
               <TextareaBoxControl label={"Project Notes"} name="textBox" control={control}
               // onChange={(e) => handleTextBox(e) } 
               />
               <div className="doubleButton">
                    {loader ?
                         <div style={{ paddingLeft: '50px' }}> <ClipLoader /></div>
                         :
                         <Button type='primary' value={"Save Change"} />
                    }
                    <ButtonSecond click={() => DiscardChange()} type='secondary' value={"Discard Change"} />
               </div>
          </form>
     )
}

export default ProjectDetailForm