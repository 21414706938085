import React from "react"

const initialState = {
  isLoggedIn: false,
  tokenInint: null,
  doLogin: () => { },
  doLogout: () => { },
  assignToken: () => { }
}

const AuthContext = React.createContext({ initialState })

export const useAuthContext = () => React.useContext(AuthContext)

export const AuthProvider = ({ children }) => {

  const [login, setLogin] = React.useState(false)

  const [token, setToken] = React.useState(null)

  const doLogin = (value) => {
    setLogin(value)
  }

  const assignToken = (value) => {
    setToken(value)
  }

  const data = {
    login,
    token,
    doLogin,
    assignToken,
    useAuthContext
  }

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>

}

