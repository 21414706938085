import React from "react";


import ProgressBar from "@ramonak/react-progress-bar";
;

const CustomProgressBar = (props) => {
  return <ProgressBar  visuallyHidden completed={props.value} bgColor={"black"}
   isLabelVisible={props?.isLabelVisible ? true : false} height={props.height ? props.height : '10px'}
 />;
};

export default CustomProgressBar