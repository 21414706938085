import React, { useMemo } from "react";
import { Controller, useFormState } from "react-hook-form";
import { useMatch } from "react-router-dom";
import 'stylesheets/comman/inputBox.scss';

const SelectBox = (props) => {
  const { control, name = "", required } = props
  const state = useFormState({ control, name })
  const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state])
  const errorMessage = useMemo(() => state.errors[name]?.message || undefined, [state])
  return (
    <>
      {control !== undefined ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <div className={`form-group ${props.grouClass}`}>
              {props.label && <label className="title">{props.label}</label>}
              <div className="position-relative ">
                <select className="form-control custom-select" onChange={(event) => {
                  field.onChange(event.target.value);
                  if (props.selectDocuments) props.selectDocuments(event.target.value);
                }}
                  value={field.value}>
                  {props.selected ?
                    <option value={0} selected>{props.selected}</option> : ''
                  }
                  {props?.selectList?.map(item => (
                    <option key={item.id} value={item.value}>{item.name || item.value}</option>
                  ))}
                </select>
              </div>
              {isErrorExist && <span className='error'>{errorMessage}</span>}
            </div>
          )}
        />
      ) : (
        <div className="form-group">
          {props.label && <label className="title">{props.label}</label>}
          <div className="position-relative">
              <select className="form-control custom-select">
              {props.selectList.map(item => (
                <option key={item.id} value={item.value}>{item.value}</option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};
export default SelectBox;
