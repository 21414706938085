import axios, { Axios } from "axios";
import { useAuthContext } from "context/auth/AuthContext";
import { toast } from "react-toastify";

let token = localStorage.getItem("mytoken");
// let navigate = useNavigate()

// process.env.REACT_APP_BASEURL,
const AxiosInstance = axios.create({
  baseURL: "http://ourappdemo.com:4000/api/v1",
  // baseURL: "http://api.planspace.ca/api/v1",
  // baseURL: "http://192.168.1.63:4000/api/v1",
  timeout: 250000000000000000,
  headers: {
    // 'x-access-token': token //the token is a variable which holds the token
  },
});

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("mytoken");

      toast.error("You must login first.");
      window.location.replace("/");
    }
    if (
      error.request.responseURL.includes("forgot-password") ||
      error.request.responseURL.includes("signup")
    ) {
      return error.response;
    } else return error;
  }
);

export const API = {
  async userAuthentication(params) {
    try {
      let response = await AxiosInstance.post("user/login", {
        ...params,
      });
      const res = response.response;
      if (res === undefined) {
        return response;
      } else {
        return res;
      }
      // console.log(res.data);
    } catch (error) {
      return error;
    }
  },

  async userChangePassword(params, mytokenn) {
    try {
      let response = await AxiosInstance.post(
        "user/change-password",
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getLocationList(mytokenn) {
    try {
      let response = await AxiosInstance.get("location-tag/get-list", {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  async ForgetEmail(params) {
    try {
      let response = await AxiosInstance.post("user/login", {
        ...params,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getTitleListing() {
    try {
      let response = await AxiosInstance.get("sidebar-filter");
      return response?.data;
    } catch (e) {
      return e.data;
    }
  },

  async ChangePassword(params) {
    try {
      let response = await AxiosInstance.post("user/reset-password", {
        ...params,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  async userSignUp(params) {
    try {
      let response = await AxiosInstance.post("user/signup", {
        ...params,
      });
      if (response.success) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error.response;
    }
  },
  async contactSalesRep(params) {
    try {
      let response = await AxiosInstance.post("contact/sales", {
        ...params,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async changePassword(params) {
    try {
      let response = await AxiosInstance.post("user/forgot-password", {
        ...params,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async addproducttoproject(params, mytokenn, id) {
    try {
      let response = await AxiosInstance.post(
        `project/add-product/${id}`,
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  // product/list?sort=-description&search=&limit=2&page=1
  async productFilter(data, mytokenn, projectId, isFilter) {
    console.log('dddd', isFilter);
      try {
        let response = await AxiosInstance.post(
          `product/list?sort=${data?.sort || ""}createdAt&search=${data?.search
          }&limit=${data?.noOfRecord}&page=${data?.pages}`,
          { projectId, ...data?.prm },
          {
            headers: {
              "x-access-token": mytokenn,
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
  },
  async productFilter2(data, mytokenn) {
    try {
      let response = await AxiosInstance.post(
        `product/list?sort=${data?.sort || ""}name&search=${
          data?.search
        }&limit=2000&page=1`,
        { ...data?.prm },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async fetchProductList(mytokenn) {
    try {
      let response = await AxiosInstance.get("product?limit=2000", {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async fetchSearchProducts(mytokenn, params = {}) {
    try {
      let response = await AxiosInstance.post(
        "product/list?sort=createdAt&limit=2000&page=1",
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async AddFilesSubmittal(mytokenn, params, id) {
    try {
      let response = await AxiosInstance.post(
        `project/add-files-submittal/${id}`,
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async AddFilesBrochure(mytokenn, params, id) {
    try {
      let response = await AxiosInstance.post(
        `project/add-files-brochure/${id}`,
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async getUserDetails(mytokenn) {
    try {
      let response = await AxiosInstance.get("user/profile/me", {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async ProductDetailById(id, mytokenn) {
    try {
      let response = await AxiosInstance.get(`product/${id}`, {
        headers: {
          "x-access-token": mytokenn,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async generatePdf(id, mytokenn, params) {
    let formData = new FormData();
    Object.keys(params).forEach((k) => {
      if (params[k] === undefined || params[k] === null) {
        delete params[k];
      } else formData.append(k, params[k]);
    });

    try {
      let response = await AxiosInstance.post(
        `project/create-submittal/${id}`,
        formData,
        {
          headers: {
            "x-access-token": mytokenn,
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
          responseType: "arraybuffer",
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async generatePdfSubmittal(id, mytokenn, params) {
    let formData = new FormData();
    Object.keys(params).forEach((k) => {
      if (params[k] === undefined || params[k] === null) {
        delete params[k];
      } else formData.append(k, params[k]);
    });

    try {
      let response = await AxiosInstance.post(
        `project/create-submittal/${id}`,
        formData,
        {
          headers: {
            "x-access-token": mytokenn,
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getProgressValue(mytokenn, id) {
    try {
      let response = await AxiosInstance.get(`project/progress/${id}`, {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async UserProfile(mytokenn) {
    try {
      let response = await AxiosInstance.get("user/profile/me", {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async userUpdateProfile(mytokenn, params) {
    try {
      let response = await AxiosInstance.post(
        "user/update-profile",
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async AddProjects(params, mytokenn) {
    try {
      let response = await AxiosInstance.post(
        "project",
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async cloneProjects(id, mytokenn) {
    try {
      let response = await AxiosInstance.get(`project/clone/${id}`, {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async GetProjects(value, mytokenn) {
    try {
      let response = await AxiosInstance.get(
        `project?sort=${value || "-createdAt"}`,
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async GetProjectsById(id, mytokenn) {
    try {
      let response = await AxiosInstance.get(`project/info/${id}`, {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async projectdelete(id, mytokenn) {
    try {
      let response = await AxiosInstance.delete(`/project/${id}`, {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (e) {
      return e.data;
    }
  },

  async productsdelete(id, mytokenn, params) {
    // console.log("params::", id, mytokenn, params, { productId: [params] });

    try {
      let response = await AxiosInstance.post(
        `project/remove-product/${id}`,
        { productId: [params] },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (e) {
      return e.data;
    }
  },

  async UpdateSubmittal(params, id, mytokenn) {
    try {
      let response = await AxiosInstance.put(
        `project/${id}`,
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async UpdateIndex(params, id, mytokenn) {
    try {
      let response = await AxiosInstance.post(
        `project/product-index-update/${id}`,
        params,
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getSubmittalDetails(id, mytokenn) {
    try {
      let response = await AxiosInstance.get(`project/info/${id}`, {
        headers: {
          "x-access-token": mytokenn,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async updateProjectInfo(params, id, mytokenn) {
    // console.log('paras', { ...params })
    try {
      let response = await AxiosInstance.put(
        `project/update-project-info/${id}`,
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },


  async replaceProductInProject(params, id, mytokenn) {
    // console.log('paras', { ...params })
    try {
      let response = await AxiosInstance.put(
        `project/replace-product/${id}`,
        { ...params },
        {
          headers: {
            "x-access-token": mytokenn,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  //*******************************hierarchy*******************************************

  async ManufacturerListing() {
    try {
      let response = await AxiosInstance.get("data/manufacturer/hierarchy");

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async BuildingTypeListing() {
    try {
      let response = await AxiosInstance.get("data/building_type/hierarchy");

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async ProductTypeListing() {
    try {
      let response = await AxiosInstance.get("data/product_type/hierarchy");

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async scheduleTypeListing() {
    try {
      let response = await AxiosInstance.get("data/schedule_type/hierarchy");

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async ProductCategoryListing() {
    try {
      let response = await AxiosInstance.get("data/product_category/hierarchy");

      return response.data;
    } catch (error) {
      return error;
    }
  },

  async complaintsListing() {
    try {
      let response = await AxiosInstance.get(
        "data/accessible_complaint/hierarchy"
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async curatedSectionListing() {
    try {
      let response = await AxiosInstance.get(
        "data/curated_selection/hierarchy"
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
};
