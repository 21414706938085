import React, { useState } from "react";
import '../stylesheets/ChangePass.scss'
import { ClipLoader } from "react-spinners";
import * as yup from 'yup';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/comman/button";
import InputBox from "components/comman/InputBox";
import { API } from "API/API";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context/auth/AuthContext";

const mess = "This field is required."
const schema = yup.object({
     currentPassword:yup.string().required(mess),
     newPassword: yup.string().required(mess)
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
               "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"),
     confirmNewPassword: yup.string().required(mess)
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match.'),
     })

const ChangePassword = () => {
     const {token,doLogin,setTokenn,assignToken} = useAuthContext()
    
     const navigate = useNavigate();
     const [loader, setLoader] = useState(false)
     const { handleSubmit, control, setValue, getValues } = useForm({
          resolver: yupResolver(schema)
     });

     const onSubmit = (data) => {
          ChangePassword(data)
     }


     const ChangePassword = async (data)=>{
          setLoader(true)
          try{
               let params = { current_password: data.currentPassword,
                    new_password:data.newPassword}
               let response = await API.userChangePassword(params,token)
               if(response.success){
                    const data = response.data;
                    localStorage.setItem('mytoken', response.data);
                    localStorage.setItem('mytoken', null);
                    navigate("/login-signup")
                    toast.success(response.message)
               }
               else {
                    toast.error(response.message)
               }
          }
          catch(error){
              toast.error('Network Error')
          }
          finally{
              setLoader(false)
          }
      }
  
     return (
          <section className="resetPass" >
               <div className="container">
                    <div className="row">
                         <form onSubmit={handleSubmit(onSubmit)} className="inner-Row">
                              <div className="heading">
                                   <h1>{"CHANGE PASSWORD"}</h1>
                              </div>
                              <div className="inputs">
                                   <InputBox control={control} placeholder={"Enter current password"}
                                        label="Current password" type={'password'} name="currentPassword" />
                                   <InputBox control={control} placeholder={"Enter new password"}
                                        label="New password" type={'password'} name="newPassword" />
                                   <InputBox control={control} placeholder={"Confirm new password"}
                                        label="Confirm new password" type={'password'} name="confirmNewPassword" />

                                   <div className="inputsbutton" > 
                                   
                                       {loader ? <ClipLoader /> : <Button value="Change-Password" type="primary" />}
                                 
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
          </section>
     )
}

export default ChangePassword