import LoginForm from "components/LoginForm";
import ResetPasswordForm from "components/ResetPasswordForm";
import SignupForm from "components/SignupForm";
import { Images } from "constants/Images";
import React, { useEffect, useState } from "react";
import "stylesheets/login.scss";

const tabs = [
  {
    id: 1,
    title: "Login",
  },
  {
    id: 2,
    title: "Signup",
  },
];

const LoginSignupPage = (props) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [forgot, setForgot] = useState(false);

  useEffect(()=>{
    setCurrentTab(1)
  },[])

  const onTab = (id) => {
    setCurrentTab((prevID) => {
      if (prevID !== id) return id;
      return id;
    });
  };
  const SetTo1 =()=>{
    setCurrentTab(1)
  }
  const onForgot = () => {
    setForgot(!forgot);
  };

  const toLogin =()=>{
    setForgot(false);

  }

  return (
    <section className="login">
      <div className="container">
        <div className="row">
          <div className="login__img">
            <img src={Images.image.loginImg} alt="" />
          </div>
          <div className="login__form">
            <div className="login__title">
              {forgot ? (
                <>
                  <h2>Reset Password</h2>
                  <p>
                    Enter your Sign In email address so we can send you steps on
                    how to reset your password.
                  </p>
                </>
              ) : (
                <>
                  {currentTab === 1 && (
                    <>
                        <h2>Login</h2>
                    </>
                  )}
                  {currentTab === 2 && (
                    <>
                        <h2>Sign Up</h2>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="tabs">
              {forgot ? null : (
                <div className="tab">
                  <ul>
                    {tabs.map((item) => (
                      <li
                        key={item.id}
                        onClick={() => onTab(item.id)}
                        className={item.id === currentTab ? "active" : ""}
                      >
                        {item.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="content">
                {forgot ? (
                    <div className="loginTab">
                      <ResetPasswordForm toLogin={toLogin} />
                    </div>
                ) : (
                  <>
                    {currentTab === 1 && (
                      <div className="loginTab">
                        <LoginForm forgot={onForgot} click={props.login} />
                      </div>
                    )}
                    {currentTab === 2 && (
                      <div className="signupTab">
                        <SignupForm onTab1={SetTo1} click={props.login} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LoginSignupPage;
