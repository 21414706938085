import React, { useState } from 'react';
import { Route, Routes as BrowserRoute, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HomePage from '../pages/HomePage';
import ProductPage from 'pages/ProductPage';
import ProductDetailPage from 'pages/ProductDetailPage';
import LoginSignupPage from 'pages/LoginSignupPage';
import ProjectPage from 'pages/ProjectPage';
import ProjectDetailPage from 'pages/ProjectDetailPage'; 
import { Toaster } from 'components/comman/Toaster';
import { UserContext } from 'components/UserContext';
import EditProject from 'pages/editProject';
import CreateSubmittal from 'pages/createSubmittal';
import GenerateSubmittal from 'pages/generateSubmittal';
import ManufacturerWebsite from 'pages/manufacturerWebsite';
import Finalsubmittal from 'pages/finalSubmittal';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import CreateSubmittalLanding from '../pages/createSubmittalLanding';
import MainSubmittal from 'pages/submittalMain';
import TermsOfUse from 'pages/TermsOfUse';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import ResetPassword from 'components/resetPassword';
import ChangePassword from 'pages/ChangePassword';
import EditProfile from 'pages/editProfile';
import SelectDocument from 'pages/selectDocument';
import BrochureMain from 'pages/brochure/brochureMain';
import FinalBrochure from 'pages/brochure/finalBrochure';
import CreateProjectTools from 'pages/CreateProjectTools';
import HomePage2 from 'pages/HomePage2';

const Routes = () => {
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const [login, setLogin] = useState(false);
    const onMenu = () => {
        if (window.innerWidth <= 991) {
            setMenu(!menu)

        }
    }
    const onLogin = () => {
        setLogin(!login);
        navigate('/products');
    }

    const [values, setValues] = useState("PI from Context")

    return (
        <div className={`wrapper ${menu ? 'showMenu' : ''}`}>
            <UserContext.Provider value={values}>
                <Header onMenu={onMenu} showMenu={menu} />
                <main id='main'>
                    <BrowserRoute>
                        <Route exact path={'/'} element={<HomePage2 />} />
                        {/* <PrivateRoute path="/" render={<HomePage />} /> */}
                        <Route path={'/products'} element={<ProductPage />} />
                        <Route path={'/product-detail/:id'} element={<ProductDetailPage />} />
                        <Route path={'/login-signup'} element={<LoginSignupPage login={onLogin} />} />
                        <Route path={'/reset-password/:id'} element={<ResetPassword />} />
                        <Route path={'/edit-profile'} element={<EditProfile />} />
                        <Route path={'/change-password'} element={<ChangePassword />} />
                        <Route path={'/projects'} element={<ProjectPage />} />
                        <Route path={'/termsofuse'} element={<TermsOfUse />} />
                        <Route path={'/project-detail/:id'} element={<ProjectDetailPage />} />
                        <Route path={'/edit-project/:id'} element={<EditProject />} />
                        <Route path={'/createSubmittal/:id'} element={<MainSubmittal />} />
                        <Route path={'/create-project-tools/:id'} element={<CreateProjectTools />} />
                        <Route path={'/selectDocument/:id'} element={<SelectDocument />} />
                        <Route path={'/createBrochure/:id'} element={<BrochureMain/>} />
                        {/* <Route path={'/create-submittal'} element={<CreateSubmittal />} /> */}
                        {/* <Route path={'/generate-submittal'} element={<GenerateSubmittal />} /> */}
                        <Route path={'/final-submittal'} element={<Finalsubmittal />} />
                        <Route path={'/final-brochure'} element={<FinalBrochure />} />

                        <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
                        <Route path={'/view-project/:id'} element={<PrivacyPolicy />} />
                        {/* <Route path={'/manufacturer-websites'} element={<ManufacturerWebsite />} /> */}
                    </BrowserRoute>
                </main>
                <Footer />
            </UserContext.Provider>
        </div>
    )
}
export default Routes;