import React, { useState } from "react";
import CreateBrochureForm from "./createBrochure";
import CreateBrochureLanding from "./createBrochureLanding";
import GenerateBrochure from "./generateBrochure";
import HeadBrochure from "./headBrochure";
import SelectDocumentBrochure from "./selectDocumentBrochure";
import SetBrochureProduct from "./setProductBrochure";
import CreateSubmittalLanding from "pages/createSubmittalLanding";
import CreateSubmittal from "pages/createSubmittal";

const BrochureMain = () => {
    const [step, setStep] = useState(0)

    const steps = () => {
        setStep(1)
    }
    const stepss = () => {
        setStep(2)
    }

    const stepsss = () => {
        setStep(0)
    }

    const handleClick2 = () => {
        setStep(0)
    }
    const backStep = () => {
        setStep(step - 1)
    }

    


    return (
        <section>
            <div style={{ paddingTop: '30px' }} className="container">
                <HeadBrochure backStep={() => backStep()} step={step} onFirstClick={() => setStep(2)}
                    onSecondClick={() => setStep(3)} onThirdClick={() => setStep(4)} />
                {/* {step === 0 && <CreateBrochureLanding changeStep={steps}  />} */}

                {step === 0 && <CreateSubmittalLanding changeStep={steps} />}


                {step === 1 && <SetBrochureProduct onClick={() => setStep(0)} />}

                {step === 2 && <SelectDocumentBrochure onClick2={() => setStep(0)} />}
                {step === 3 && <CreateSubmittal />}
                {step === 4 && <GenerateBrochure onClick={() => setStep(3)} onClick2={() => setStep(0)} />}
            </div>
        </section>
    )
}

export default BrochureMain