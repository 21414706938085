import React from "react";
import Logo from '../constants/imagesC/logoMain.png'
import '../stylesheets/finalSubmittal.scss'

const Finalsubmittal = () => {
    return (
        <section className="finalSubmittal">
           <div className="container">
               <div className="mainContent">
                   <div className="row">
                       <div className="mainContent__logo">
                           <img src={Logo} className="image-fluid"/>
                       </div>
                       
                       <div className="mainContent__textarea">
                           <h1>Submittal Successful</h1>
                           <div>
                               <p>Thank You For Submittal. Please check your email adress</p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
    )
}

export default Finalsubmittal