import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import 'stylesheets/footer.scss';
import { Navigate, useNavigate } from 'react-router-dom';
import { Images } from 'constants/Images';
import ContactSaleModal from './modal/ContactSaleModal';
import TermsOfUse from 'pages/TermsOfUse';
import { useAuthContext } from 'context/auth/AuthContext';
import { toast } from 'react-toastify';

const links = [
    {
        id: 1,
        name: 'Product Catalogue',
        path: () => { },
        to: '/products'
    },
    {
        id: 2,
        name: 'Project Builder',
        path: () => { },
        to: '/projects'
    },
    // {
    //     id: 3,
    //     name: 'Manufacturer Websites',
    //     path: () => { },
    //     to: '/manufacturer-websites'
    // },
    {
        id: 3,
        name: 'plan/space® User Guide',
        path: () => { },
    },
];
const menus = [
    // {
    //     id: 1,
    //     name: 'Contact Sales Rep',
    //     path: () => {},
    // },
    {
        id: 2,
        name: 'Projects',
        path: () => { },
        to: '/projects'
    },
    {
        id: 3,
        name: 'Privacy Policy',
        path: () => { },
        name: 'Terms of Use',
        path: () => { },
        to: '/termsofuse'
    },
];
const Footer = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const { login } = useAuthContext();

    const handleclick = (item) => {
        if ((localStorage.getItem('mytoken') === 'null' || localStorage.getItem('mytoken') === null) && item.id !== 3) {
            toast.error("You must login first")
            navigate('/login-signup')
        }
        else {
            navigate(item.to)
        }
    }
    const handleclick2 = (item) => {
        if ((localStorage.getItem('mytoken') === 'null' || localStorage.getItem('mytoken') === null) && item.id === 2) {
            toast.error("You must login first")
            navigate('/login-signup')
        }
        else {
            navigate(item.to)
        }
    }

    const onModalClick = (props) => {
        props?.onMenu()
        setShowModal(!showModal)
    }
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='footer__box'>
                        <img src={Images.image.logo} alt="" className='footer__box__logo' onClick={() => navigate('/')} />
                        <p>The leading manufacturers at the tip of your fingers. Create and collaborate on your
                            upcoming project by utilizing the most in demand products on the market.
                            Plan/Space gives you the tools to create an all in one plumbing fixture package curated to your vision.</p>
                        <ul className='footer__box__social'>
                            <li><FaFacebookF /></li>    
                            <li><FaInstagram /></li>
                            <li><FaLinkedinIn /></li>
                            <li><FaTwitter /></li>
                        </ul>
                    </div>
                    <div className='footer__box'>
                        <ul className='footer__box__menu'>
                            {links.map(item => (
                                <li key={item.id} onClick={() => handleclick2(item)}>{item.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='footer__box'>
                        <ul className='footer__box__menu'>
                            <li onClick={() => onModalClick()}>Contact Sales Rep</li>
                            {menus.map(item => (
                                <li key={item.id} onClick={() => handleclick(item)}>{item.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <p className='copyright'>Copyright 2022 ©. All Rights Reserved</p>
            </div>
            <ContactSaleModal click={() => setShowModal(!showModal)} show={showModal} />
        </footer>
    )
}
export default Footer;