import React, { useEffect, useState } from "react";
import classes from "./ProjectAccordion.module.scss";
import {
  FaCaretDown,
  FaCaretUp,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
  FaCheck,
} from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { ClipLoader } from "react-spinners";
import { IoMdAddCircle } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GiGClef } from "react-icons/gi";
import { X } from "react-feather";

// ------------------ Product Card Component-------------------

const ProductCard = ({
  isFirst,
  isLast,
  product,
  productIndex,
  index,
  handleProductDelete,
  DocumentList,
  setDocumentList,
  setDocList,
  handleUpdatePrice,
  handleUpdateNotes,
  LocationId,
  moveUp,
  moveDown,
  openAddProductModal
}) => {
  const [productDetailsState, setProductDetailsState] = useState({});
  const [productBrochure, setproductBrochure] = useState([
    ...product.productBrochureFiles,
  ]);
  const [showImage, setShowImage] = useState(true);

  const handleShowDetails = (locationIndex, productIndex) => {
    const key = `${locationIndex}-${productIndex}`;
    setProductDetailsState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const isFileChecked = (fileId, productBrochureFiles) => {
    return productBrochureFiles.includes(fileId);
  };

  const handleDelete = (productId) => {
    handleProductDelete(productId);
  };
  // console.log('price', product.price);
  const [changePrice, setChangePrice] = useState();
  const [price, setPrice] = useState(product.price || "0");

  const [changeNotes, setChangeNotes] = useState();
  const [note, setNote] = useState(product.note || "");

  const params = useParams();
  const projectId = params.id;

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const onUpdatePrice = (price, productId, locationIndex) => {
    setPrice(price);
    setChangePrice(false);
    sessionStorage.setItem(projectId.toString(), locationIndex.toString());
    handleUpdatePrice(price, productId);

    // Scroll to the updated notes section
    const notesSection = document.getElementById(`notes-${productId}`);
    if (notesSection) {
      notesSection.scrollIntoView({ behavior: "smooth", block: "center" });
      notesSection.focus();
    }
  };
  // console.log(product.price, "dsds");

  useEffect(() => {
    setPrice(product.price || "0");
  }, [product.price]);

  const handleNoteChange = (event) => {
    const inputValue = event.target.value.replace(/^\s+/, "");
    setNote(inputValue);
  };

  const onUpdateNote = (note, productId, locationIndex) => {
    setNote(note);
    setChangeNotes(false);
    sessionStorage.setItem(projectId.toString(), locationIndex.toString());
    handleUpdateNotes(note, productId);
    // Scroll to the updated notes section
    const notesSection = document.getElementById(`notes-${productId}`);
    if (notesSection) {
      notesSection.scrollIntoView({ behavior: "smooth", block: "center" });
      notesSection.focus();
    }
  };

  useEffect(() => {
    setNote(product.note || "");
  }, [product.note]);

  console.log("product details", product);

  const handleUpdateProduct = (productName, productId, LocationId, colorId, locationIndex) => {
    console.log('cdcd', projectId.toString(), locationIndex.toString())
    sessionStorage.setItem(projectId.toString(), locationIndex.toString());
    openAddProductModal(projectId, productName, productId, LocationId, colorId);

    // Scroll to the updated notes section
    const removeSection = document.getElementById(`replace-${productId}`);
    if (removeSection) {
      removeSection.scrollIntoView({ behavior: "smooth", block: "center" });
      removeSection.focus();
    }
  }
  // console.log('data', product, productIndex, isFirst, isLast, LocationId, product.productInProjectId);
  return (
    <Draggable
      key={product?.productInProjectId}
      draggableId={product?.productInProjectId}
      index={productIndex}
    >
      {(provided, snapshot) => (
        <div
          key={productIndex}
          className={classes.wrapper_acc_item_inner}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!productDetailsState[`${index}-${productIndex}`] ? (
            <div className={classes.wrapper_acc_header_inner}>
              <span className={classes.wrapper_acc_header_inner_no}>
                {productIndex + 1}
              </span>
              <span className={classes.wrapper_acc_header_inner_menuBar}>
                <IoMenu />
              </span>
              <img
                // src={product?.detail?.images[0]?.fileName}
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${product?.color?.image}`}
                // src="/images/product-demo-img.png"
                alt={`${product?.detail?.name}`}
                className={classes.wrapper_acc_header_inner_img}
              />
              <span className={classes.wrapper_acc_header_inner_name}>
                {product?.detail?.name}
              </span>
              <div
                className={classes.wrapper_acc_header_inner_price}
                style={{ flexDirection: "column", gap: "0px" }}
              >
                <label>Model Number :</label>
                <span style={{ fontSize: "14px" }}>
                  {product.detail.images[0].modelName}
                </span>
              </div>
              <div className={classes.wrapper_acc_header_inner_price}>
                <label>Price</label>
                <div className={classes.wrapper_acc_header_inner_price_cover}>
                  <input
                    type="number"
                    readOnly={!changePrice}
                    value={price}
                    onChange={handlePriceChange}
                    style={{ borderColor: changePrice && "green" }}
                  />
                  <button
                    onClick={() =>
                      changePrice
                        ? onUpdatePrice(
                            price,
                            product.productInProjectId,
                            index
                          )
                        : setChangePrice(true)
                    }
                    style={{ color: changePrice && "green" }}
                  >
                    {changePrice ? <FaCheck /> : <MdModeEditOutline />}
                  </button>
                </div>
                <div className={classes.wrapper_acc_header_inner_price_cover}>
                  <textarea
                    readOnly={!changeNotes}
                    value={note}
                    onChange={handleNoteChange}
                    style={{ borderColor: changeNotes && "green" }}
                    placeholder="Notes"
                  />
                  <button
                    onClick={() =>
                      changeNotes
                        ? onUpdateNote(note, product.productInProjectId, index)
                        : setChangeNotes(true)
                    }
                    style={{ color: changeNotes && "green" }}
                  >
                    {changeNotes ? <FaCheck /> : <MdModeEditOutline />}
                  </button>
                </div>
              </div>
              <div className={classes.wrapper_acc_header_inner_cta}>
                {!isFirst && (
                  <div
                    style={{ width: '24px', height: '22px', display: 'flex', textAlign: 'center', justifyContent: 'center', cursor: 'pointer' }}
                    className={classes.hideInMobile}
                    onClick={() =>
                      moveUp(
                        LocationId,
                        productIndex,
                        product.productInProjectId
                      )
                    }
                  >
                    <FaLongArrowAltUp
                      // className={classes.hideInMobile}
                      // onClick={() =>
                      //   moveUp(
                      //     LocationId,
                      //     productIndex,
                      //     product.productInProjectId
                      //   )
                      // }
                    />
                  </div>
                )}
                {!isLast && (
                  <div
                    // style={{ width: '22px', height: '24px', }}
                    className={classes.hideInMobile}
                    style={{ width: '24px', height: '22px', display: 'flex', textAlign: 'center', justifyContent: 'center', cursor: 'pointer' }}
                    onClick={() =>
                      moveDown(
                        LocationId,
                        productIndex,
                        product.productInProjectId
                      )
                    }
                  >
                    <FaLongArrowAltDown
                      // className={classes.hideInMobile}
                      // onClick={() =>
                      //   moveDown(
                      //     LocationId,
                      //     productIndex,
                      //     product.productInProjectId
                      //   )
                      // }
                      // style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}
                    />
                  </div>
                )}

                <div
                  onClick={() => handleUpdateProduct(product.detail.name, product.detail._id, LocationId, product.colorId, index)}
                  style={{ width: '20px', height: '20px' }}
                >
                  <MdModeEditOutline style={{ width: '17px', height: '17px' }} />
                </div>

                {/* delete functionality */}
                <span
                  className={classes.wrapper_acc_header_inner_cta_delete}
                  onClick={() => handleDelete(product.productInProjectId)}
                >
                  <IoIosCloseCircle />
                </span>

                {!productDetailsState[`${index}-${productIndex}`] && (
                  <span
                    className={classes.wrapper_acc_header_btn}
                    onClick={() => handleShowDetails(index, productIndex)}
                  >
                    <FaCaretDown />
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.wrapper_acc_body_inner}>
              <div className={classes.wrapper_acc_body_inner_head}>
                <span className={classes.wrapper_acc_header_inner_no}>
                  {productIndex + 1}
                </span>
                <span className={classes.wrapper_acc_header_inner_menuBar}>
                  <IoMenu />
                </span>
                <div className={classes.wrapper_acc_header_inner_cta}>
                  <span
                    className={classes.wrapper_acc_header_btn}
                    onClick={() => handleShowDetails(index, productIndex)}
                  >
                    <FaCaretUp />
                  </span>
                </div>
              </div>
              <div className={classes.wrapper_acc_body_inner_content}>
                <div className={classes.wrapper_acc_body_inner_content_img}>
                  <img
                    // src={product?.detail?.images[0]?.fileName}
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}${product?.color?.image}`}
                    // src="/images/product-demo-img.png"
                    alt={`${product?.detail?.name}`}
                  />
                </div>
                <div className={classes.wrapper_acc_body_inner_content_text}>
                  <div
                    className={classes.wrapper_acc_body_inner_content_text_box}
                  >
                    <p className={classes.wrapper_acc_header_inner_name}>
                      {product?.detail?.name}
                    </p>
                    <p className={classes.showInMobile}>
                      <b>Price:</b>: {product?.price}
                    </p>
                    <p>
                      <b>Manufacturer</b>:{" "}
                      {product?.detail?.manufacturer[0]?.title || "N/A"}
                    </p>
                    <p>
                      <b>Model</b>:{" "}
                      {product?.detail?.images[0]?.modelName.length
                        ? product.detail.images[0]?.modelName
                        : "N/A"}
                    </p>
                    <p
                      className={
                        classes.wrapper_acc_body_inner_content_text_box_txt
                      }
                    >
                      {product?.detail?.description}
                    </p>
                  </div>
                  <div
                    className={classes.wrapper_acc_body_inner_content_text_box}
                  >
                    <div
                      className={
                        classes.wrapper_acc_body_inner_content_text_box_label
                      }
                    >
                      <p>
                        <label>Documents</label>
                      </p>
                    </div>
                    {product.files.length !== 0 ? (
                      <ul>
                        {product?.files?.map((file, index) => (
                          <li key={file._id}>
                            <label>
                              <input
                                type="checkbox"
                                onChange={() => {
                                  const element = DocumentList.find(
                                    (t) =>
                                      t.productInProjectId ===
                                      product.productInProjectId
                                  );
                                  if (element) {
                                    if (element.files.includes(file._id)) {
                                      element.files.splice(
                                        element.files.indexOf(file._id),
                                        1
                                      );
                                    } else {
                                      element.files.push(file._id);
                                    }

                                    setDocumentList([
                                      ...DocumentList.filter(
                                        (fil) =>
                                          fil.productInProjectId !=
                                          element.productInProjectId
                                      ),
                                      element,
                                    ]);
                                    setDocList(
                                      [
                                        ...DocumentList.filter(
                                          (fil) =>
                                            fil.productInProjectId !=
                                            element.productInProjectId
                                        ),
                                        element,
                                      ],
                                      showImage
                                    );
                                  }
                                }}
                                checked={DocumentList.find(
                                  (t) =>
                                    t.productInProjectId ===
                                    product.productInProjectId
                                )?.files.includes(file._id)}
                              />
                              <span>{file?.docName}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

//--------------------------- Accordion Card Component ------------------------------
const AccordionCard = ({
  products,
  locationTag,
  handleProductDelete,
  DocumentList,
  setDocumentList,
  setDocList,
  handleUpdateLocation,
  handleUpdatePrice,
  handleUpdateNotes,
  moveUp,
  moveDown,
  onDragEnd,
  openAddProductModal
}) => {
  // const [openLocationIndex, setOpenLocationIndex] = useState(0);
  const params = useParams();
  const projectId = params.id;
  const [openLocationIndex, setOpenLocationIndex] = useState(() => {
    const storedIndex = sessionStorage.getItem(projectId.toString());
    return storedIndex ? parseInt(storedIndex) : 0;
  });
  const [userSelectedLocation, setUserSelectLocation] = useState([]);
  const [customLocation, setCustomLocation] = useState();
  const handleLocationChange = (index, id, name) => (event) => {
    // console.log('imp', index, id, name, event.target.value, event)
    const selectedLocationName = event.target.value;
    handleUpdateLocation(selectedLocationName, id);
    setUserSelectLocation((prev) => ({
      ...prev,
      [index]: selectedLocationName,
    }));
  };

  const handleIconsChange = (index) => {
    setOpenLocationIndex(openLocationIndex === index ? null : index);
  };

  console.log("chech", openLocationIndex);

  const handleSelectLocationChange = (index, locId, locName) => (event) => {
    handleUpdateLocation(event.value, locId);
  };

  const handleInputChange = (newValue) => {
    console.log("check new", newValue);
    setCustomLocation(newValue);
  };

  const updateNewAddedLocation = (loc) => {
    handleUpdateLocation(customLocation, loc);
  };
  console.log("loc tag", locationTag);

  return (
    <Droppable droppableId="mainDroppable">
      {(provided, snapshot) => (
        <div
          className={classes.wrapper_acc}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {products?.map((locationData, index) => (
            <Draggable
              key={locationData.location_id[0]}
              draggableId={locationData.location_id[0]}
              index={index}
              >
                {(provided, snapshot) => (
                  <div
                    className={classes.wrapper_acc_item}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className={classes.wrapper_acc_header}>
                      <span
                        className={classes.wrapper_acc_header_btn}
                        onClick={() => handleIconsChange(index)}
                      >
                        {openLocationIndex === index ? (
                          <FaCaretUp />
                        ) : (
                          <FaCaretDown />
                        )}
                      </span>
                      {/* <select
                className={classes.wrapper_acc_header_category}
                value={
                  userSelectedLocation[index]
                    ? userSelectedLocation[index]
                    : locationData?.location?.name
                }
                onChange={handleLocationChange(index, locationData?.location?._id, locationData.location.name)}
              >
                {Array.isArray(locationTag) &&
                  locationTag.slice(1).map((location) => (
                    <option key={location._id} value={location.name}>
                      {location.name}
                    </option>
                  ))}
              </select> */}

                      <Select
                        className={classes.wrapper_acc_header_category}
                        name="selectLocation"
                        options={locationTag.slice(1).map((location) => ({
                          value: location.name,
                          label: location.name,
                        }))}
                        placeholder={
                          userSelectedLocation[index]
                            ? userSelectedLocation[index]
                            : locationData?.location?.name
                        }
                        onChange={handleSelectLocationChange(
                          index,
                          locationData?.location?._id,
                          locationData.location.name
                        )}
                        onInputChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            console.log("firer");
                            e.preventDefault();
                            updateNewAddedLocation(locationData?.location?._id);
                          }
                        }}
                      />

                      <p className={classes.wrapper_acc_header_ttlPro}>
                        <b>Number of Products:</b>{" "}
                        {locationData?.product?.length}
                      </p>
                    </div>

                  {openLocationIndex === index && ( 
                    <DragDropContext
                      onDragEnd={(e) => onDragEnd(e, locationData?.location?._id)}
                    >
                      <Droppable
                        droppableId={locationData?.location?._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={classes.wrapper_acc_body}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {locationData.product.map(
                              (product, productIndex) => (
                                <ProductCard
                                  isLast={
                                    locationData.product.length - 1 ===
                                    productIndex
                                  }
                                  isFirst={productIndex === 0}
                                  product={product}
                                  productIndex={productIndex}
                                  index={index}
                                  handleProductDelete={handleProductDelete}
                                  DocumentList={DocumentList}
                                  setDocumentList={setDocumentList}
                                  setDocList={setDocList}
                                  handleUpdatePrice={handleUpdatePrice}
                                  LocationId={locationData.location._id}
                                  moveUp={moveUp}
                                  moveDown={moveDown}
                                  handleUpdateNotes={handleUpdateNotes}
                                  openAddProductModal={openAddProductModal}
                                />
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    )}
                  </div>
                )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

//--------------------------project accordion card component------------------------
const ProjectAccordion = ({
  mainTitle,
  allProducts = [],
  locationTag,
  handleProductDelete,
  loading,
  DocumentList,
  setDocumentList,
  setDocList,
  totalProduct,
  handleUpdateLocation,
  handleUpdatePrice,
  handleUpdateNotes,
  moveUp,
  moveDown,
  onDragEnd,
  openAddProductModal,
  handleDragEndOuter
}) => {
  // const [totalProduct, setTotalProduct] = useState(0);
  const [products, setProducts] = useState(null);
  const navigate = useNavigate();

  // const getTotalProducts = () => {
  //   let productLength = 0;
  //   const res = allProducts.map((obj) => {
  //     productLength = productLength + obj.product.length;
  //   })
  //   setTotalProduct(productLength);
  // }

  // getTotalProducts();

  // setTotalProduct(productLength);
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapper_wrap}>
        <h2 className={classes.wrapper_wrap_ttl}>
          {mainTitle}
          <span>({totalProduct})</span>
        </h2>
        <span
          className={classes.wrapper_wrap_actn}
          onClick={() => navigate("/projects")}
        >
          Choose Another Project
        </span>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "100px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ClipLoader />
        </div>
      ) : (
        <>
          {allProducts.length === 0 ? (
            <div style={{ textAlign: "center" }}>No Products are there</div>
          ) : (
            <DragDropContext
                  onDragEnd={(e) => handleDragEndOuter(e)}
            >
              <AccordionCard
                products={allProducts}
                setProducts={setProducts}
                locationTag={locationTag}
                handleProductDelete={handleProductDelete}
                DocumentList={DocumentList}
                setDocumentList={setDocumentList}
                setDocList={setDocList}
                handleUpdateLocation={handleUpdateLocation}
                handleUpdatePrice={handleUpdatePrice}
                handleUpdateNotes={handleUpdateNotes}
                moveUp={moveUp}
                moveDown={moveDown}
                onDragEnd={onDragEnd}
                    openAddProductModal={openAddProductModal}
              />
            </DragDropContext>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectAccordion;
